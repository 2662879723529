import React from "react";

const Focus = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/b945e6bb0c7b46169ea54b4fe1421950"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-b945e6bb-0c7b-4616-9ea5-4b4fe1421950"
        class="block-section background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/279fa641364b4c27af193eab556fb7af"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "3",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-ce21598c-d483-454f-b10b-83f7967d6621"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/219e1ace28ba4adf9f38d5110d3bbec3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-219e1ace-28ba-4adf-9f38-d5110d3bbec3"
                              class="block-header"
                            >
                              Partnership Focus Areas
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/559a357c7a6144d9ae3d2b4abf74d6b7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-separator"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/d42dd74faaef463ba653e9ccbed266f9"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                <strong>
                                  Our team is dedicated to reviewing each
                                  request with care and consideration.
                                </strong>
                              </p>
                              <p>
                                All requests must be submitted through our
                                online request form. For inquiries about our
                                partnerships, please reach out to{" "}
                                <a
                                  rel="noopener"
                                  href="mailto:info@endotherapeutics.us"
                                  target="_blank"
                                >
                                  info@endotherapeutics.us
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/66b04ce6e934445592cff6e548d1f3a7"
                  data-col-span="9"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "9",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-fe3fda2d-d9ab-4292-b14e-6ffc22dcda85"
                    class="block-column p-l-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/1a583891ca8d480e99d9596826de483b"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label text-color-dark-gray">
                              Healthcare Partnerships
                            </p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/872b0d90d63e4b5a9a73fe5daf9664d4"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-e6620e48-ce1f-469c-9d81-78b9b05799b7"
                              class="block-column has-rc has-full-height has-bg-color background-color-white p-l-medium-large p-r-medium-large p-b-medium-large p-t-medium-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/6b4fe9382aff471cb7706ea5502f0db0"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc is-icon">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/0kmgzvda/icon-human-with-heart.svg"
                                          alt="Icon - human with heart"
                                        />
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/05b91c7580c146e1b289f97f127d7124"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-05b91c75-80c1-46e1-b289-f97f127d7124"
                                        class="block-header"
                                      >
                                        Advocacy for Quality Care
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/f6d90c603b574f6cb2a368437a178d81"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We stand alongside advocacy groups
                                          that champion the protection and
                                          improvement of patient care, ensuring
                                          that the voices of those we serve are
                                          heard and valued.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/da52d60b97524cc896c3855529dc6ea2"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-2f3aecc8-593e-44f1-a988-d1c857e40fa1"
                              class="block-column has-rc has-full-height has-bg-color background-color-white p-l-medium-large p-r-medium-large p-b-medium-large p-t-medium-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/85cb7dd3ab9f4cce819b33e23c44f872"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc is-icon">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/wawhvwlh/icon-heart-with-plus.svg"
                                          alt="Heart with plus icon"
                                        />
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/348690367dbd4d9d9ac178840309d184"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-34869036-7dbd-4d9d-9ac1-78840309d184"
                                        class="block-header"
                                      >
                                        Reducing Healthcare Disparities
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/066042264d61496a842d0cd2585284d9"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We are driven to do all we can to
                                          promote health equity by supporting
                                          sustainable solutions through programs
                                          and investments that aim to inspire
                                          hope and promote healthier lives,
                                          especially access to healthy food.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/8708c8ee279e49dd8072a90bb9cba61c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label text-color-dark-gray m-t-medium-large">
                              Community Partnerships
                            </p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/4b78d28ab7724995a56764747197ec94"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-96794c80-c899-4abb-bb42-3301db3acb4a"
                              class="block-column has-rc has-full-height has-bg-color background-color-white p-l-medium-large p-r-medium-large p-b-medium-large p-t-medium-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/e1ed1fdb2bd44a259bc3cda07295aab3"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc is-icon">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/t4bfzi4x/icon-umbrella.svg"
                                          alt="Umbrella Icon"
                                        />
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/ce1a8ee3296a4272a6b16059fe4e7d85"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-ce1a8ee3-296a-4272-a6b1-6059fe4e7d85"
                                        class="block-header"
                                      >
                                        Meeting Basic Necessities
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/6d0180f34da044ac97253e4ad2b23f1b"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We aim to provide support to those in
                                          need by focusing on essential
                                          necessities like food, shelter, and
                                          access to healthcare.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/82773574422c439f8fadffef2d03968c"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-fdd26ed9-d091-407c-9fd3-d41b9958361d"
                              class="block-column has-rc has-full-height has-bg-color background-color-white p-l-medium-large p-r-medium-large p-b-medium-large p-t-medium-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/076e94a19b02472388e5aa07bded4860"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc is-icon">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/raeh0dxp/icon-light-bulb.svg"
                                          alt="Light bulb icon"
                                        />
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/1592c4fd0a424552bef7e9a4fa9a9e78"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-1592c4fd-0a42-4552-bef7-e9a4fa9a9e78"
                                        class="block-header"
                                      >
                                        Igniting Scientific Curiosity
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/50c4ebbe7c464baf9c5f908649affcae"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We strive to nurture an interest in
                                          science education among children and
                                          young adults (grades 1st to 12th).
                                          Preference is given to programs
                                          targeting underprivileged youth, as we
                                          believe in creating equal
                                          opportunities for all to explore and
                                          pursue careers in science.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Focus;
