import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MarkBradLey = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Mark Bradley | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Mark Bradley" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/mark-bradley/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/team.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Mark Bradley</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/vvukurro/mark-bradley.webp"
                  alt="Mark Bradley"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Mark Bradley</h1>

              <p class="team-position">
                Executive Vice President and Chief Financial Officer
              </p>

              <div class="team-bio">
                <p>
                  Mark Bradley has served as Executive Vice President and Chief
                  Financial Officer of Endo International plc since March 2020,
                  and continues to serve in that role for Endo. He previously
                  served as Senior Vice President, Corporate Development &amp;
                  Treasurer since June 2017. Mr. Bradley joined Endo
                  International plc in January 2007 as a Finance Director and
                  has held various positions of increasing responsibility since
                  joining the company.
                </p>
                <p>
                  Prior to joining the company, he spent nearly seven years as a
                  management consultant, most recently with Deloitte Consulting,
                  providing a broad range of strategic and operational advice
                  and services to senior executives across a number of
                  industries. In addition, Mr. Bradley served as a Finance
                  Director for an industrial products company for approximately
                  two years. He spent the first five years of his career in
                  public accounting at Ernst &amp; Young LLP.
                </p>
                <p>
                  Mr. Bradley is a licensed Certified Public Accountant and
                  holds a Bachelor of Science degree in Accounting from Saint
                  Joseph’s University and a Master of Business Administration
                  from The University of Texas at Austin.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default MarkBradLey;
