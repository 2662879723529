import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JamesPapp = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>James Papp | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="James Papp" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/james-papp/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/team.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">James Papp</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/mcthnsap/james-papp.webp"
                  alt="James Papp"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">James Papp</h1>

              <p class="team-position">
                Head of Endo Ireland and
                <br />
                Senior Vice President, Global Supply Chain
              </p>

              <div class="team-bio">
                <p>
                  James Papp has served as Head of Endo Ireland and Senior Vice
                  President, Global Supply Chain for Endo International plc in
                  November 2020, and continues in that role for Endo. In this
                  role Mr. Papp is responsible for the overall strategic
                  management and operations of Endo Ireland and the strategic
                  management and operations of Endo’s global supply chain. Mr.
                  Papp joined Endo International plc in October 2019 as Senior
                  Vice President, Global Supply Operations.
                </p>
                <p>
                  Mr. Papp has over 35 years of leadership experience in
                  pharmaceutical global supply chain management, strategic
                  sourcing, supplier management, contract manufacturing, and
                  continuous improvement in organizations such as Mylan,
                  Novartis, Par Pharmaceutical, and Schering Plough.
                </p>
                <p>
                  Jim earned his Bachelor's degree in Business Administration
                  from the University of Scranton, Scranton PA, and his Master
                  of Business Administration in Pharmaceutical Studies at
                  Fairleigh Dickinson University in Madison, NJ.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default JamesPapp;
