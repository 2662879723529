import React from "react";

const FinancialContent = () => {
  return (
    <div class="wd_content-wrapper-left body-content-container">
      <div class="wd_content">
        <div id="wd_printable_content">
          <h1 class="page_header">Financial Information</h1>

          <div class="wd_standard">
            <div class="wd_category wd_category_0">
              <div class="wd_category_items wd_category_items_0">
                <div class="item">
                  <div class="item_name">Q1 2024</div>
                  <ul>
                    <li>
                      <a href="2024-05-30-Endo-Provides-Select-First-Quarter-2024-Financial-Results-for-Endo-International-plc">
                        Press Release
                      </a>
                    </li>
                    <li>
                      <a href="https://investor.endo.com/image/Endo_Q1+2024+Earnings+Presentation+FINAL.pdf">
                        Presentation
                      </a>
                    </li>
                    <li>
                      <a href="https://investor.endo.com/image/Interim+Financials-2024-05-30.pdf">
                        Interim Financials
                      </a>
                    </li>
                    <li>
                      <a href="https://investor.endo.com/image/Q1+2024+Management+Discussion+and+Analysis.pdf">
                        Management Discussion and Analysis
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wd_featureboxes_bottom wd_featureboxes-vertical">
          <div
            id="wd_featurebox-standard_11"
            class="wd_featurebox_container wd_featurebox-standard"
          >
            <div class="wd_featurebox_title"></div>
            <div class="wd_featurebox">
              <p class="more" style={{ "text-align": "center" }}>
                <a href="ir-archive">Financial Results Archive</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialContent;
