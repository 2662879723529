import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MickMcguiness = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Mick McGuinness | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Mick McGuinness" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/mick-mcguinness/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/team.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Mick McGuinness</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/s34l43j1/mick-mcguinness.webp"
                  alt="Mick McGuinness"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Mick McGuinness</h1>

              <p class="team-position">
                Senior Vice President,
                <br />
                Global Head of Quality and Compliance
              </p>

              <div class="team-bio">
                <p>
                  Michael (Mick) McGuinness has served as Global Head of Quality
                  and Compliance, for Endo International since June 2022, and
                  continues in that role for Endo. In this role, Mr. McGuinness
                  has responsibility for the company’s overall global quality
                  and compliance. Since joining Endo International plc in 2014,
                  he has provided critical leadership and technical insight to
                  the strategy, design, and implementation of quality systems
                  and processes that are critical to Endo’s ability to
                  consistently deliver high-quality medicines.
                </p>
                <p>
                  Mr. McGuinness brings more than 30 years of experience in
                  quality, technical, and manufacturing roles of increasing
                  responsibility on multiple continents. He is a Graduate of the
                  Royal Society of Chemistry, having studied at the University
                  of Hertfordshire.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default MickMcguiness;
