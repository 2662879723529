import React from "react";
import { Helmet } from "react-helmet";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MichaelRandolph = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Michael Randolph | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Michael Randolph" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/michael-randolph/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Michael Randolph</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/k3inmzwi/michael-randolph.webp"
                  alt="Michael Randolph"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Michael Randolph</h1>

              <p class="team-position">
                Senior Vice President,
                <br />
                Global Head of Manufacturing Operations
              </p>

              <div class="team-bio">
                <p>
                  Michael Randolph has served as Global Head of Manufacturing
                  Operations for Endo International plc since June 2022 and
                  continues to serve in that role for Endo. Most recently, he
                  served as Senior Vice President, Commercial Operations and
                  General Manager, and was responsible for U.S. operations. He
                  brings more than 30 years of global pharmaceutical
                  manufacturing operations experience to the role as well as a
                  passion for process improvement and quality.
                </p>
                <p>
                  Prior to joining Endo International plc in 2015, Mr. Randolph
                  had roles of increasing responsibility at Fujifilm, Mylan,
                  Teva, and Baxter Healthcare. Mr. Randolph holds a Master of
                  Science in Civil Engineering from Georgia Institute of
                  Technology and a Bachelor of Science in Civil Engineering from
                  North Carolina State University.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default MichaelRandolph;
