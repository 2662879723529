import React from "react";

const SliderTory = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/0c5aba4677484909ac61327627d3387e"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-0c5aba46-7748-4909-ac61-327627d3387e"
        class="block-section background-color-light-gray p-b-large p-t-medium-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/6641427f97f64a14b733ce098efd4e14"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-4dae0e89-bd4a-423c-a2bf-5aaba637cf04"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="storiesSlider"
                            data-content-element-type-key="6396bf08-a8a8-4006-a495-b776d4827cc4"
                            data-element-udi="umb://element/8f316433198e4767823d42d87ebec397"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <section
                              id="slider-8f316433-198e-4767-823d-42d87ebec397"
                              class="block-stories-slider type-cards splide"
                              aria-label="Slider-8f316433-198e-4767-823d-42d87ebec397"
                            >
                              <div class="splide__track">
                                <ul class="splide__list">
                                  <li
                                    id="slide-item-2ce9baa7-1bcc-43ff-8fcf-b79b8a043891"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/v2yebctj/dc-commercial-3.png"
                                            alt=""
                                            width="1198"
                                            height="672"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/"
                                          >
                                            Dupuytren&#x2019;s Campaign
                                            Emboldens Patients with a Handful of
                                            &#x201C;Reminders&#x201D;
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-d117fd85-5651-426c-a155-cecabe1ede2a"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/qf1c15tr/istock-1047519510.jpg"
                                            alt=""
                                            width="3864"
                                            height="2576"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/it-s-men-s-health-month-here-s-your-checkup-checklist/"
                                          >
                                            It&#x2019;s Men&#x2019;s Health
                                            Month: Here&#x2019;s Your Checkup
                                            Checklist
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/it-s-men-s-health-month-here-s-your-checkup-checklist/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-e518c4a9-2854-4f67-a38d-d4a27de26c61"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/ppzd4cpl/inspired-thumbnail-1402x1050.webp"
                                            alt="The Endo logo on the side of an office wall."
                                            width="1402"
                                            height="1050"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/ready-for-tomorrow-inspired-for-life/"
                                          >
                                            Ready for Tomorrow, Inspired for
                                            Life
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/ready-for-tomorrow-inspired-for-life/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-66a91c99-8ef8-4af0-a315-57ee3494ffad"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/amehjla1/thumbnail-how-ready-to-use-medications-1402x1050.webp"
                                            alt="A drip line in the foreground and a bed bound hospital patient in the background"
                                            width="0"
                                            height="0"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/how-ready-to-use-medications-may-help-reduce-hospital-complexity/"
                                          >
                                            How Ready-to-Use Medications May
                                            Help Reduce Hospital Complexity
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/how-ready-to-use-medications-may-help-reduce-hospital-complexity/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-068c5151-770a-44d3-a9ff-d60016bc4345"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/gvmet532/endo_lov_corpsitethumbnailbanner_1402x1050px_p1.webp"
                                            alt="Living Our Values Awards Logo"
                                            width="1402"
                                            height="1050"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/2024-living-our-values-award-winners/"
                                          >
                                            2024 Living Our Values Award Winners
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/2024-living-our-values-award-winners/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-549f95a6-1e7a-4adf-9202-600e744df052"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/jsgblqi0/thumbnail-peyronie-1402x1050.webp"
                                            alt="A patient speaks with a doctor."
                                            width="0"
                                            height="0"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/why-peyronie-s-disease-goes-undiagnosed-and-untreated/"
                                          >
                                            Why Peyronie&#x2019;s Disease Goes
                                            Undiagnosed and Untreated
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/why-peyronie-s-disease-goes-undiagnosed-and-untreated/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-0399b042-8770-404f-a7db-86a3ef5530c5"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/1jqlclwa/thumbnail-supplier-diversity-1402x1050.webp"
                                            alt="Three people in a warehouse walk while in discussion"
                                            width="0"
                                            height="0"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/supplier-diversity-powering-success-and-opportunities/"
                                          >
                                            Supplier Diversity: Powering Success
                                            and Opportunities
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/supplier-diversity-powering-success-and-opportunities/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    id="slide-item-2ce9baa7-1bcc-43ff-8fcf-b79b8a043891"
                                    class="splide__slide"
                                  >
                                    <div class="story-row">
                                      <div class="story-image">
                                        <figure id="story-hero">
                                          <img
                                            loading="lazy"
                                            src="https://www.endo.com/media/v2yebctj/dc-commercial-3.png"
                                            alt=""
                                            width="1198"
                                            height="672"
                                          />
                                          <figcaption></figcaption>
                                        </figure>
                                      </div>
                                      <div class="story-copy">
                                        <h2 class="story-name">
                                          <a
                                            style={{
                                              fontSize: "23px",
                                              lineHeight: "0.5",
                                            }}
                                            href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/"
                                          >
                                            Dupuytren&#x2019;s Campaign
                                            Emboldens Patients with a Handful of
                                            &#x201C;Reminders&#x201D;
                                          </a>
                                        </h2>

                                        <div class="block-btn type-text">
                                          <a
                                            class="common-btn"
                                            href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/"
                                          >
                                            Read the Story
                                            <div class="arrow-wrap">
                                              <svg
                                                width="40"
                                                height="12"
                                                viewBox="0 0 40 12"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                  fill="#E87722"
                                                ></path>
                                                <path
                                                  d="M33 11L38 6L33 1"
                                                  stroke="#E87722"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                ></path>
                                              </svg>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SliderTory;
