import React from "react";

const RelatedStories = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="relatedStoriesBlock"
      data-content-element-type-key="a4e5c049-03f3-4695-bedc-eee45e94b2fb"
      data-element-udi="umb://element/cba9999b48a24d25bb087a908eb2b6e8"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <div class="block-related-stories count-3">
        <img
          class="bg-image"
          loading="lazy"
          src="https://www.endo.com/images/bg-related-stories.webp"
          alt="Decorative background"
        />
        <div class="global-container">
          <h2 class="section-title">Related Stories</h2>

          <div class="story-row-wrap">
            <div class="story-tile">
              <a href="/newsroom/stories/providers-weigh-in-on-the-importance-of-men-s-health/">
                <figure class="story-thumb">
                  <img
                    loading="lazy"
                    src="https://www.endo.com/media/jeuga3bi/screenshot-2024-06-13-103529.png"
                    alt=""
                    width="1647"
                    height="868"
                  />
                  <figcaption></figcaption>
                </figure>

                <div class="story-copy">
                  <h3 class="story-name">
                    Providers Weigh In on the Importance of Men&#x2019;s Health
                  </h3>
                  <div class="story-excerpt">
                    We asked healthcare professionals why it&#x2019;s important
                    to focus on men&#x2019;s health. Listen to what the experts
                    had to say about the reasons they&#x2019;re passionate about
                    their work.
                  </div>
                  <div class="block-btn type-text">
                    <span href="/newsroom/stories/providers-weigh-in-on-the-importance-of-men-s-health/">
                      <span>Watch the Video</span>

                      <div class="arrow-wrap">
                        <svg
                          width="40"
                          height="12"
                          viewBox="0 0 40 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                            fill="#E87722"
                          ></path>
                          <path
                            d="M33 11L38 6L33 1"
                            stroke="#E87722"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                      </div>
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <div class="story-tile">
              <a href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/">
                <figure class="story-thumb">
                  <img
                    loading="lazy"
                    src="https://www.endo.com/media/v2yebctj/dc-commercial-3.png"
                    alt=""
                    width="1198"
                    height="672"
                  />
                  <figcaption></figcaption>
                </figure>

                <div class="story-copy">
                  <h3 class="story-name">
                    Dupuytren&#x2019;s Campaign Emboldens Patients with a
                    Handful of &#x201C;Reminders&#x201D;
                  </h3>
                  <div class="story-excerpt">
                    &#x201C;You got this.&#x201D; That&#x2019;s how
                    Endo&#x2019;s newest commercial about Dupuytren&#x2019;s
                    contracture begins. The &#x201C;Reminders&#x201D; campaign
                    aims to embolden patients living with the progressive hand
                    condition and to encourage them to become advocates in their
                    treatment journey.
                  </div>
                  <div class="block-btn type-text">
                    <span href="/newsroom/stories/dupuytren-s-campaign-emboldens-patients-with-a-handful-of-reminders/">
                      <span>Read the Full Story</span>

                      <div class="arrow-wrap">
                        <svg
                          width="40"
                          height="12"
                          viewBox="0 0 40 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                            fill="#E87722"
                          ></path>
                          <path
                            d="M33 11L38 6L33 1"
                            stroke="#E87722"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                      </div>
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <div class="story-tile">
              <a href="/newsroom/stories/reminders-commercial-for-dupuytren-s-contracture/">
                <figure class="story-thumb">
                  <img
                    loading="lazy"
                    src="https://www.endo.com/media/vdchwnkd/dc-commercial-2.png"
                    alt=""
                    width="1198"
                    height="672"
                  />
                  <figcaption></figcaption>
                </figure>

                <div class="story-copy">
                  <h3 class="story-name">
                    &#x201C;Reminders&#x201D; Commercial for Dupuytren&#x2019;s
                    Contracture
                  </h3>
                  <div class="story-excerpt">
                    The &#x201C;Reminders&#x201D; campaign emboldens patients to
                    advocate for their preferred treatment plan for
                    Dupuytren&#x2019;s contracture, a progressive hand condition
                    that affects an estimated 17 million Americans.*
                  </div>
                  <div class="block-btn type-text">
                    <span href="/newsroom/stories/reminders-commercial-for-dupuytren-s-contracture/">
                      <span>Watch the Video</span>

                      <div class="arrow-wrap">
                        <svg
                          width="40"
                          height="12"
                          viewBox="0 0 40 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                            fill="#E87722"
                          ></path>
                          <path
                            d="M33 11L38 6L33 1"
                            stroke="#E87722"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                      </div>
                    </span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedStories;
