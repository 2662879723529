import React from "react";

const TransformationGrowth = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/bbcc2cc021464ace96748cbce25325bd"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-bbcc2cc0-2146-4ace-9674-8cbce25325bd"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/ae7638e929ce40249dd3dda9133c7124"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-8b9b716e-f8ba-49d6-9494-d3e284add65c"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/be198062c87e43e896cf85c9baefc4f0"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label">Transformation and Growth</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/f5db2ebde494402fac584625a6dfcfca"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-f5db2ebd-e494-402f-ac58-4625a6dfcfca"
                              class="block-header"
                            >
                              Moving Forward With Purpose
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/5d1456380f584062ba3eede283b3fc5c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                In our constant pursuit to address the unmet
                                needs of patients, Endo has a long history of
                                developing and evolving its business to provide
                                diverse products and capabilities.
                              </p>
                              <p>
                                Like many pharmaceutical companies, we have a
                                history of mergers and acquisitions that have
                                helped us become the company we are today.
                                Through a merger in 2000, we became a publicly
                                traded company. Another business move brought us
                                multiple products and a specialization in
                                urology and endocrinology, areas where we still
                                focus and invest today. And it was an
                                acquisition that gave us one of our premier
                                products, XIAFLEX®.
                              </p>
                              <p>
                                We rapidly expanded our global footprint and our
                                expertise with a series of transactions in 2014
                                and 2015. First, we entered the Canadian market
                                when we acquired Paladin Labs (now Paladin
                                Pharma), a leading Canada-based specialty
                                pharmaceutical company, and we united under one
                                parent company based in Dublin, Ireland, where
                                we established our global headquarters. A year
                                later, we acquired specialty generics and
                                sterile injectables company Par Pharmaceutical
                                Holdings Inc., and those two business segments
                                remain critical to our company.
                              </p>
                              <p>
                                Endo initiated a financial restructuring process
                                in 2022, with a goal of emerging as a strong
                                growth company. This significant milestone
                                allowed us to further advance our strategic
                                priorities and strengthen Endo for the future.
                              </p>
                              <p>
                                Through these changes, we have embraced our
                                momentum and transformation and built a
                                sustainable business, while remaining
                                unwaveringly committed to our mission,
                                customers, patients, team members, and
                                communities.
                              </p>
                              <p>
                                <strong>
                                  Today, we’re better positioned than ever to
                                  grow our business and to help everyone we
                                  serve live their best life.
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/93a995554af14570b84c254da02a7f18"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-898be434-604e-4a7c-b069-9ee0adf00d1d"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/badc4c183c3c43769ff141280e37694a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <figure class="block-image has-rc m-b-medium-large">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/iqcdqz1d/endo-india-ireland.webp"
                                alt="Endo India welcomes leaders from the U.S. and Ireland"
                                width="0"
                                height="0"
                              />
                              <figcaption>
                                Endo India welcomes leaders from the U.S. and
                                Ireland
                              </figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/e7341c63a089494fb90c4581ada8b39d"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/ukqaklgu/endo-bike-team.webp"
                                alt="Paladin team members in Canada strive to be a force for good"
                                width="0"
                                height="0"
                              />
                              <figcaption>
                                Paladin team members in Canada strive to be a
                                force for good
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TransformationGrowth;
