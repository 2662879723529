import React from "react";

const BlogMore = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/745e1169df1e471dafe9d9f3e40d1150"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-745e1169-df1e-471d-afe9-d9f3e40d1150"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": 1 }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/6d89c50386fc45259a622e27ff541eba"
                  data-col-span="7"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "7",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-223cf976-e881-45bb-adc9-8a39aa67ce81"
                    class="block-column p-r-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/a7659a7e69214f3985a83288d1d2b324"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/ah2oddbu/patient-community-care.webp"
                                alt="A mother and adult daughter smile and embrace."
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/580fa98f008b4e018fe9bb6d13af2930"
                  data-col-span="5"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "5",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-2a4a99b5-d04f-414e-b1a8-0b4ef4e70d19"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/37dca07ed9354cc4956fe09e9dd40928"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label">Our Company</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/29b2daeb636240f1afe3ed5631b7b235"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-29b2daeb-6362-40f1-afe3-ed5631b7b235"
                              class="block-header font-size-large"
                            >
                              Life-Enhancing Therapies
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/5172d03537784d44b81eef04a8e37be7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Our mission drives us: through focused insights
                                and execution, we develop and deliver
                                life-enhancing therapies. Our team members
                                collaborate to bring forward pharmaceutical
                                solutions that make a real difference for
                                customers and patients.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/200ad872ff194a16b7d31254be20d24c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-btn type-hollow">
                              <a
                                class="common-btn"
                                href="/company/our-purpose/"
                              >
                                About Endo
                              </a>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/41dd862c10e74e8abacb90c74bb47259"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-separator type-full-width m-b-medium-large m-t-medium-large"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/23bafee169d443c2b63b974fe965c90f"
                            data-col-span="5"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "5",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-3f7acc9f-5083-45d6-9067-b923d43bd843"
                              class="block-column p-r-small"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": 1,
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/6d223b4207674e668eda701aa2ebc126"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/jzoknre1/expanding-together.webp"
                                          alt="A group of people sign a banner"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/cf0db1856ccd4240ad4291b327b9927f"
                            data-col-span="7"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "7",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-3700daeb-20f4-4e01-9a4f-36b426f0d2dc"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": 1,
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/a8f0e70e48d6445db11921c0fe4cc148"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-a8f0e70e-48d6-445d-b119-21c0fe4cc148"
                                        class="block-header font-size-medium-large"
                                      >
                                        Expanding Together
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/7121ce1fad74413cbfa9999790a0a6c4"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          Endo’s century-long manufacturing
                                          legacy encompasses life-saving
                                          products and contributions to science
                                          and technology. The new
                                          90,000-square-foot facility in
                                          Rochester, Michigan builds on this
                                          history and supports future
                                          innovations in medicine.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/c87f31033cca4ef5ba08eb86adec2670"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text">
                                        <a
                                          class="common-btn"
                                          href="/newsroom/stories/empowering-progress-endos-rochester-expansion-facility/"
                                        >
                                          Read the Story
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogMore;
