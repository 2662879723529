import React from "react";

const Explore = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/8c1c20dbe1894a6e85fc80a89dbf7407"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-8c1c20db-e189-4a6e-85fc-80a89dbf7407"
        class="block-section align-h-center"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/cb92bd35c34a474db81cd924b427f9cb"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-3ff89587-7c1d-4811-92b9-52807571a2a3"
                    class="block-column align-h-left"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/aeb51a303e214cdeac7b9045e371fc03"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-aeb51a30-3e21-4cde-ac7b-9045e371fc03"
                              class="block-header talign-center m-b-medium-large"
                            >
                              Explore Our Sustainable Efforts
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9cf2982b5be0401f8f3e357ef1714894"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-87bab290-795e-4215-ab2c-569636c9b4f5"
                              class="block-column p-r-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/afb3581abc084e41907c901a4ed8861e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/a5vp4bsj/water-management.webp"
                                          alt="A wall garden"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/7635fb398830457bafa12dc27e112611"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-7635fb39-8830-457b-afa1-2dc27e112611"
                                        class="block-header"
                                      >
                                        Water Management
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/2cf1a03537e94d94baf5a4e09814b960"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          According to the United Nations, 2.3
                                          billion people live in water-stressed
                                          countries; therefore, we know water is
                                          a resource that must be carefully
                                          managed. Water is important in both
                                          the development and manufacturing
                                          processes we practice at our sites,
                                          and Endo continues to improve our
                                          stewardship of water resources. Using
                                          technologies such as membrane
                                          filtration/reverse osmosis at our
                                          plants in India, we able to reuse
                                          effluents and sanitary waste for plant
                                          service and gardening needs.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/1bfc6eac2eae4ad8b72ed45e664b5548"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-f6244d91-2e20-4266-a680-888e90461277"
                              class="block-column p-l-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/4e5a84d69dfe43dea41f33fb693a00a7"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/gdukwzx5/efficient-facilities-management.webp"
                                          alt="Facilities technician walks while holding a laptop."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/16ae4c4f7f7149ffa3885581b2361a47"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-16ae4c4f-7f71-49ff-a388-5581b2361a47"
                                        class="block-header"
                                      >
                                        Efficient Facilities Management
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/910b5243c71a4feabd3ab4909bf6b871"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          Since 2020, Endo has worked to plan,
                                          direct, and control the supply and
                                          consumption of energy across our
                                          global manufacturing sites. Each of
                                          our facilities has a customized
                                          strategy based on local market
                                          regulations and each site’s unique
                                          energy requirements. The goal is to
                                          achieve effective use of energy
                                          products while minimizing costs and
                                          pollution with conscious, judicious,
                                          and efficient use of resources. One of
                                          our key strategies: moving away from
                                          diesel to cleaner and more efficient
                                          natural gas and renewable energy
                                          alternatives.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/55a234cd21d2419bb557214acca6797e"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-191efcf7-68a7-4789-9e5c-a6b67bf7c161"
                              class="block-column p-r-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/555e3f2ef8da41c78412c86f65accebd"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/oumnovd4/material-reduction-recycling.webp"
                                          alt="An aeriel view of a person sorting recyclables."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/72df6f821a794c6d9f7ae1ccb02d5733"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-72df6f82-1a79-4c6d-9f7a-e1ccb02d5733"
                                        class="block-header"
                                      >
                                        Material Reduction &amp; Recycling
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/dbc26d4ed9c845fc9e22c31f4188273f"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          Recycling is an important way
                                          companies can protect the environment.
                                          We have active recycling programs
                                          underway across each of our
                                          manufacturing facilities, offices, and
                                          labs. Many states within the U.S., as
                                          well as India and Ireland, have
                                          separate and distinct recycling
                                          requirements, with which we comply.
                                          Our internal global recycling program
                                          encourages team members to identify
                                          ways to improve recycling efforts at
                                          their sites.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/b21c4202b33a4034a2b801b13ba770b7"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-c64666cb-98b2-45cb-a43c-d1e7cf3e8a5b"
                              class="block-column p-l-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/5cac44f97741425bab0fa787cb7d4e26"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/1sdkta0f/waste-reduction.webp"
                                          alt="A closeup of a stack of flattened cardboard boxes."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/4e5a1a1ee9ae4097a7f3dc7050915979"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-4e5a1a1e-e9ae-4097-a7f3-dc7050915979"
                                        class="block-header"
                                      >
                                        Waste Reduction
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/413b394768e34f1687af654a1f0ed3ae"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          In producing millions of products and
                                          their packaging, our sites naturally
                                          generate waste in the form of rejected
                                          components, printed materials, or
                                          water-spent solvents. The goal is to
                                          limit waste and to repurpose it in
                                          sustainable ways. Our team remains
                                          diligent in working to reduce the
                                          amount of waste incinerated at our
                                          plants.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Explore;
