import React from "react";

const CollaborativeHealthEfforts = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/682f02ccb3cb4a9786e62292cbe9f4c6"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-682f02cc-b3cb-4a97-86e6-2292cbe9f4c6"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": 1 }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/54bd88c81319487ca981c2bf3b67d37e"
                  data-col-span="7"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 7,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-fcfb431d-0426-4236-bf52-76437bcd085d"
                    class="block-column p-r-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/6d01a83c574e46e3ba9dfb487a449ffd"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/yxjpu1do/patient-community-driven-care.webp"
                                alt="A doctor sits on a bench and talks with her patient."
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/e9a594e5eb7b4b57b20d123f8b8f5ae9"
                  data-col-span="5"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 5,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-96081a65-f810-4580-a42b-b6cbcb5447e4"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/cb3efbec7a3046bf810a90691fd17503"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <p class="block-label">
                              Collaborative Health Efforts
                            </p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/9ecf23be3ff0448a93b247766d5fc4a5"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-9ecf23be-3ff0-448a-93b2-47766d5fc4a5"
                              class="block-header font-size-large"
                            >
                              Patient and Community-Driven Care
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/b6618a8c4d1e417ca080fd4186cef376"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                At Endo, our dedication extends beyond
                                developing quality products. With a rich
                                history, we empower individuals and communities
                                alike, fostering better health outcomes and a
                                brighter future for all.
                              </p>
                              <p>
                                We are driven to improve lives—from patients
                                seeking effective treatments, to healthcare
                                providers striving to deliver exceptional
                                <br />
                                care, to investors relying on our forward
                                progress and aspirational goals for the future.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/04785f08a81843d48f1a3a3eab88bbe8"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width m-b-medium-large m-t-medium-large"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/2d416cf161634258b6109c20e96f36f9"
                            data-col-span="5"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 5,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-dbd70e30-3643-471e-8943-8045bd96cbc2"
                              class="block-column p-r-small"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": 1,
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/78b1a917404b4d7690c669d1c6f5e721"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <figure class="block-image has-rc">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/32wh5rp0/patients-in-india.webp"
                                          alt="A crowd gathers in India to listen to a speaker"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/8523d17095fa444b8f2ef11e1c8f4005"
                            data-col-span="7"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 7,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-60e355d6-5a5e-4aec-8593-26ea53f83fd1"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": 1,
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/d8b51a9f7a134a75a6bf8a3e05ff5795"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h3
                                        id="header-d8b51a9f-7a13-4a75-a6bf-8a3e05ff5795"
                                        class="block-header font-size-medium-large"
                                      >
                                        Reaching Patients in Need in India
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/1018f34a8d3b4e94b9d9527cb1845819"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          In remote areas, access to healthcare
                                          is often limited. Our Mobile Health
                                          Unit bridges this gap, providing
                                          essential services to thousands in
                                          Chennai and Indore, India.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/4cd398eb0597414faee88a85df2405af"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-btn type-text">
                                        <a
                                          class="common-btn"
                                          href="/newsroom/stories/reaching-patients-in-need-in-india/"
                                        >
                                          Read the Story
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CollaborativeHealthEfforts;
