import React from "react";

const BoardContent = () => {
  return (
    <div class="wd_content-wrapper-left body-content-container">
      <div class="wd_content">
        <div id="wd_printable_content">
          <h1 class="page_header">Board Committees</h1>

          <div class="wd_standard">
            <div class="wd_category wd_category_14">
              <div class="wd_category_info">
                <div class="category_intro">
                  <div class="category_summary"></div>
                </div>
              </div>
              <div class="wd_category_items wd_category_items_14">
                <div class="item">
                  <div class="item_name"></div>
                  <table class="wd_row_tbl responsive">
                    <caption class="wd_sr-only">Board of Directors</caption>
                    <thead>
                      <tr>
                        <th scope="col">
                          <span style={{ "font-weight": "normal" }}>
                            &nbsp;
                          </span>
                        </th>
                        <th scope="col">
                          <span style={{ "font-weight": "normal" }}>
                            Audit &amp; Finance
                          </span>
                        </th>
                        <th scope="col">
                          <span style={{ "font-weight": "normal" }}>
                            Compensation &amp; Human Capital
                          </span>
                        </th>
                        <th scope="col">
                          <span style={{ "font-weight": "normal" }}>
                            Compliance
                          </span>
                        </th>
                        <th scope="col">
                          <span style={{ "font-weight": "normal" }}>
                            Nominating, Governance &amp; Corporate
                            Responsibility
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Paul Herendeen, Chairperson
                        </td>
                        <td class="tdno2" data-th="Audit &amp; Finance">
                          Member
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          &nbsp;
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          Member
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Paul Efron
                        </td>
                        <td class="tdno2" data-th="Audit &amp; Finance">
                          &nbsp;
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          Member
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          &nbsp;
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          Chair
                        </td>
                      </tr>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Scott Hirsch
                        </td>
                        <td class="tdno2" data-th="Audit &amp; Finance">
                          &nbsp;
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          Chair
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          Member
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Sophia Langlois
                        </td>
                        <td class="tdno2" data-th="Audit &amp; Finance">
                          Chair
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          Member
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          &nbsp;
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Andrew Pasternak
                        </td>
                        <td class="tdno2" data-th="Audit &amp; Finance">
                          Member
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          &nbsp;
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          &nbsp;
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          Member
                        </td>
                      </tr>
                      <tr>
                        <td class="tdno0" data-th="Name">
                          Marc Yoskowitz
                          <span class="marker finexpert">&nbsp;</span>
                        </td>
                        <td class="tdno2" data-th="Audit Committee">
                          &nbsp;
                        </td>
                        <td
                          class="tdno3"
                          data-th="Compensation &amp; Human Capital"
                        >
                          &nbsp;
                        </td>
                        <td class="tdno4" data-th="Compliance">
                          Chair
                        </td>
                        <td
                          class="tdno5"
                          data-th="Nominating, Governance &amp; Corporate Responsibility"
                        >
                          Member
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardContent;
