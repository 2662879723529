import React from "react";

const Report = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/ff86afe5cead4f9ea8a9aa059febae39"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-ff86afe5-cead-4f9e-a8a9-aa059febae39"
        class="block-section align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/4340d51914e04f7a9b1e2ac41096da82"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-af88bf9c-6106-4a4c-8cda-669a9f6d12a4"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/8ed434fc6de94a1f889d23ff06f79ded"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-b1e5a0f9-76c8-46a0-bb03-25b5d9269bb6"
                              class="block-column p-r-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/55ee95096a3d461ca56d50a165776ba6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/0kpnvsvg/corporate-responsibility-report.webp"
                                          alt="Endo Corporate Responsibility report cover."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/bae1400b268e4dd69337cdd2120f174a"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-b727de4c-2eb7-4967-bf25-03f578c751c3"
                              class="block-column p-l-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/2999b7c2050a4eefa498b68ddc1ab390"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label text-color-dark-gray">
                                        GOVERNANCE AND GROWTH
                                      </p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/4979efc7f1e84a698c11b8d11d4972a8"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-4979efc7-f1e8-4a69-8c11-b8d11d4972a8"
                                        class="block-header"
                                      >
                                        Read Our Corporate Responsibility Report
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/dd886a59166c426894b3982231777711"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          A commitment to sustainability and
                                          social responsibility is at the core
                                          of our business strategy. Our
                                          Corporate Responsibility Report
                                          details Endo's comprehensive
                                          Environmental, Social, and Governance
                                          strategy and highlights our progress
                                          and initiatives across four key
                                          pillars: our business practices, our
                                          customers, our team, and our world.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/74f7544842a64618a28ea5a166421a30"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/k44hgnih/endo_2022_cr_report_05-01-23.pdf"
                                          target="_blank"
                                        >
                                          Read Report
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Report;
