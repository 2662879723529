import React from "react";

const Main = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="heroBlock"
      data-content-element-type-key="e8d63b4b-5bbe-4afd-8533-01727a730aeb"
      data-element-udi="umb://element/9549ac9be751414097888b147fce1d13"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section class="block-section hero-block">
        <picture class="hero-image">
          <source
            media="(max-width: 781px)"
            srcset="https://www.endo.com/media/l42cyvtf/hero-mobile-manufacturing.webp"
            width="0"
            height="0"
            alt=""
          />

          <img
            class="bg-image"
            src="https://www.endo.com/media/1vectoz5/hero-manufacturing.webp"
            alt="A technician squeezes the contents of a syringe into vials."
            width="0"
            height="0"
          />
        </picture>

        <div class="section-block-inner">
          <div class="global-container">
            <div
              class="umb-block-grid__area-container"
              style={{ "--umb-block-grid--area-grid-columns": "12" }}
            >
              <div
                class="umb-block-grid__area"
                data-area-col-span="12"
                data-area-row-span="1"
                data-area-alias="Inner"
                style={{
                  "--umb-block-grid--grid-columns": 12,
                  "--umb-block-grid--area-column-span": 12,
                  "--umb-block-grid--area-row-span": 1,
                }}
              >
                <div class="umb-block-grid__layout-container">
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="columnBlock"
                    data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                    data-element-udi="umb://element/783dc5c0ccd04aae96537ee462e51f81"
                    data-col-span="4"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": 4,
                      "--umb-block-grid--item-row-span": 1,
                    }}
                  >
                    <div
                      id="column-48aac807-0ff9-402e-bb7d-c25d9e9b7f68"
                      class="block-column"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": "1" }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": 12,
                            "--umb-block-grid--area-column-span": 12,
                            "--umb-block-grid--area-row-span": 1,
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="headerBlock"
                              data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                              data-element-udi="umb://element/9e062ae86ffb46be97dc9224a5e07a44"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": 12,
                                "--umb-block-grid--item-row-span": 1,
                              }}
                            >
                              <h1
                                id="header-9e062ae8-6ffb-46be-97dc-9224a5e07a44"
                                class="block-header"
                                style={{ fontSize: "60px", color: "#3f4443" }}
                              >
                                Manufacturing
                              </h1>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="richTextBlock"
                              data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                              data-element-udi="umb://element/9f37ced9d2cc4c0fa9347ce6ca16cb97"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": 12,
                                "--umb-block-grid--item-row-span": 1,
                              }}
                            >
                              <div class="block-rich-text font-size-medium-large font-weight-medium">
                                <p style={{ color: "#3f4443" }}>
                                  Committed to excellence and meeting the
                                  highest standards
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
