import React from "react";

const ManuFacturingLegacy = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/3f4998d6f0b244f58251ed01e1840415"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-3f4998d6-f0b2-44f5-8251-ed01e1840415"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/9713b89c29444e5592031cc7b00e174e"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-9ac5cf77-1029-4e45-abc4-b04a397b03d7"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/ff152285f1234d5bb64bb189c21ec1f1"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-a39d2576-9d4a-4371-a422-887a565b2930"
                              class="block-column p-r-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/a80285b2290249f1a75473cdcb8b682c"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc m-b-medium-large">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/3cpdpkr5/endo-farm.webp"
                                          alt="Parke-Davis Farm. From the Archives of the Rochester Hills Museum at Van Hoosen Farm"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption>
                                          Parke-Davis Farm. From the Archives of
                                          the Rochester Hills Museum at Van
                                          Hoosen Farm
                                        </figcaption>
                                      </figure>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/17a511515f6e49c7bb5590ddb3f86fe5"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image has-rc">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/wnqhdgpb/endo-leaders-sign.webp"
                                          alt="Endo leaders sign a beam that would become part of the Rochester facility expansion, completed in 2023"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption>
                                          Endo leaders sign a beam that would
                                          become part of the Rochester facility
                                          expansion, completed in 2023
                                        </figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/c554ef56fc234f90914344f008501053"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-c985409c-9f75-4823-9f24-114ce93c5dd9"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/bca0c9e22396466bb6f33b70b681ae50"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label">
                                        Manufacturing Legacy
                                      </p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/76f589a1af74437381b94955da3226cc"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-76f589a1-af74-4373-81b9-4955da3226cc"
                                        class="block-header"
                                      >
                                        Proud Spirit, Bold Future
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/4e15a9147c954a4d9b263316fb702909"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          Endo’s manufacturing legacy dates back
                                          more than a century and includes the
                                          production of life-saving products for
                                          tetanus, gangrene, diphtheria,
                                          influenza, rabies, smallpox, polio,
                                          and vasodilatory shock, to name a few.
                                        </p>
                                        <p>
                                          Our Rochester, Michigan facility
                                          originated as a 340-acre tract of land
                                          purchased by Parke-Davis, once
                                          America’s largest drugmaker and now a
                                          subsidiary of Pfizer, in 1907. “The
                                          farm,” as it was called, housed crops
                                          and animals, including horses, cows,
                                          and monkeys, and it was one of just
                                          two facilities in the country that was
                                          able to support the clinical work
                                          pioneered by Dr. Jonas Salk and other
                                          researchers to develop an antitoxin
                                          vaccine for polio.
                                        </p>
                                        <p>
                                          Just as the plant stepped up to
                                          produce and distribute the life-saving
                                          polio vaccine in the 1950s, our team
                                          mirrored that historic moment in 2020,
                                          when we focused all our energy on
                                          meeting the demand for two of our
                                          products, which were used as part of
                                          the treatment for critical COVID-19
                                          patients. Our supply of those
                                          medicines increased by five to 10
                                          times in the early days of the
                                          pandemic, and meeting that rapid
                                          patient demand became the top priority
                                          for both the Rochester team and the
                                          global Endo organization. We were also
                                          selected to provide fill-finish
                                          manufacturing services for one of the
                                          vaccine candidates underway at that
                                          time.
                                        </p>
                                        <p>
                                          The U.S. government took notice of
                                          Endo’s willingness to partner and the
                                          deep expertise and innovative spirit
                                          of our Rochester team. In 2021, we
                                          signed a fill-finish manufacturing
                                          agreement with the Department of
                                          Defense to support production of
                                          critical medicines for future pandemic
                                          preparedness. That meant we would
                                          boost the footprint and capabilities
                                          of our already impressive Rochester
                                          facility to be ready to serve in an
                                          emergency, if needed.
                                        </p>
                                        <p>
                                          Our Rochester site is joined by seven
                                          other facilities in the United States
                                          and India that make up our global
                                          manufacturing and supply network. With
                                          over one million square feet of modern
                                          facilities, we produce and package
                                          liquids, semi-solids, solids, and
                                          injectable products using innovative
                                          high-velocity equipment. Our sites
                                          also house quality control and
                                          research and development labs, as well
                                          as state-of-the-art distribution
                                          facilities.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManuFacturingLegacy;
