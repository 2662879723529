import React from "react";

const Reporting = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/58d448f04e524b64898051f22a71da9a"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-58d448f0-4e52-4b64-8980-51f22a71da9a"
        class="block-section"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="/media/fe2cujbn/bg-living-our-values-award.webp"
            alt=""
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/f0c1ba183c094ca88f6bf4e0089af31a"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-8a04def6-bbc8-4048-bf8d-a1d1dfadac2b"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/335afb93bffa491599cfc40d26c7176e"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-335afb93-bffa-4915-99cf-c40d26c7176e"
                              class="block-header font-size-large"
                            >
                              Reporting a Concern&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/44472dff7d2c46deb0244826b4d7e5b1"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-b3487ea8-7c27-4990-8566-878b3b4cfc10"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/53276a6fb76e4983920229dcb7a9f5dd"
                                      data-col-span="6"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "6",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div
                                        id="column-dd72f937-5fe5-4995-9466-f9811fa2845e"
                                        class="block-column p-r-x-large"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            " --umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns":
                                                "12",
                                              "--umb-block-grid--area-column-span":
                                                "12",
                                              "--umb-block-grid--area-row-span":
                                                "1",
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="richTextBlock"
                                                data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                data-element-udi="umb://element/e77f580dec364f4086809998d6bd0bfb"
                                                data-col-span="12"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span":
                                                    "12",
                                                  "--umb-block-grid--item-row-span":
                                                    "1",
                                                }}
                                              >
                                                <div class="block-rich-text">
                                                  <p>
                                                    Endo is committed to
                                                    conducting business in an
                                                    ethical and compliant
                                                    manner. If standards are not
                                                    met or an issue is
                                                    identified, we have
                                                    established procedures for
                                                    reporting and handling
                                                    concerns.
                                                  </p>
                                                  <p>
                                                    Our Office of Integrity
                                                    serves as a centralized,
                                                    confidential, and objective
                                                    resource for all team
                                                    members and external
                                                    stakeholders. The office
                                                    receives reports and
                                                    conducts compliance
                                                    investigations.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/1a791d17d65b40eab81f16682ef5ef30"
                                      data-col-span="6"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "6",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div
                                        id="column-5662d597-797b-497f-8727-521421cf1065"
                                        class="block-column p-r-x-large"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            " --umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns":
                                                "12",
                                              "--umb-block-grid--area-column-span":
                                                "12",
                                              "--umb-block-grid--area-row-span":
                                                "1",
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="richTextBlock"
                                                data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                data-element-udi="umb://element/7da5bec005e14a68ba1fcc97974a7ea9"
                                                data-col-span="12"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span":
                                                    "12",
                                                  "--umb-block-grid--item-row-span":
                                                    "1",
                                                }}
                                              >
                                                <div class="block-rich-text">
                                                  <p>
                                                    Endo’s Ethics Hotline is
                                                    available 24/7 by phone or
                                                    online, and reports may even
                                                    be anonymous if permitted by
                                                    local laws. Toll-free
                                                    international numbers are
                                                    available for every country
                                                    with Endo team members.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reporting;
