import React from "react";

const Professional = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/f4ec1545104942a68fab3b1491dc795a"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-f4ec1545-1049-42a6-8fab-3b1491dc795a"
        class="block-section"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/je5l3eqo/background.webp"
            alt="decorative background gradient"
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                {/* here */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/80150c1703484da8ad76676509b0e465"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-6904186a-85b8-44f2-b161-16968adfa735"
                    class="block-column"
                  >
                    <div
                      id="column-6904186a-85b8-44f2-b161-16968adfa735"
                      class="block-column"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": "1" }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": "12",
                            "--umb-block-grid--area-column-span": "12",
                            "--umb-block-grid--area-row-span": "1",
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="labelBlock"
                              data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                              data-element-udi="umb://element/2b141f409d3246e6a3fff3575c7c79d7"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <p class="block-label talign-center">
                                Pathways to Professional Growth
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="headerBlock"
                  data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                  data-element-udi="umb://element/2cedbbbd6e67468bb12285ec0c8302c0"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <h2
                    id="header-2cedbbbd-6e67-468b-b122-85ec0c8302c0"
                    class="block-header font-size-large font-weight-semi-bold talign-center m-b-medium-large"
                  >
                    Join a team where progress and potential converge
                  </h2>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="statBlock"
                  data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                  data-element-udi="umb://element/f0482eeecf6942e083494c7008d8da6f"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "3",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    class="block-stat has-ds has-rc"
                    data-stat="3,000"
                    data-auto="auto-false"
                    data-interval="interval-"
                  >
                    <div class="stat">
                      <span class="stat-count">3,000</span>
                    </div>

                    <div class="stat-label">
                      <p>
                        Dedicated
                        <br />
                        Team Members
                      </p>
                    </div>

                    <span class="sep"></span>
                  </div>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="statBlock"
                  data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                  data-element-udi="umb://element/8c766bf70f834bc3b9a28b693ff6c5ba"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "3",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    class="block-stat has-ds has-rc"
                    data-stat="16"
                    data-auto="auto-false"
                    data-interval="interval-"
                  >
                    <div class="stat">
                      <span class="stat-count">16</span>
                      <span class="stat-suffix">%</span>
                    </div>

                    <div class="stat-label">
                      <p>
                        Of Workforce
                        <br />
                        Promoted in 2023
                      </p>
                    </div>

                    <span class="sep"></span>
                  </div>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="statBlock"
                  data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                  data-element-udi="umb://element/dc7706d75d934e58aca1073e9cf14d2b"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "3",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    class="block-stat has-ds has-rc"
                    data-stat="7,000"
                    data-auto="auto-false"
                    data-interval="interval-"
                  >
                    <div class="stat">
                      <span class="stat-prefix">$</span>
                      <span class="stat-count">7,000</span>
                    </div>

                    <div class="stat-label">
                      <p>
                        Toward Continued <br />
                        Education*
                      </p>
                    </div>

                    <span class="sep"></span>
                  </div>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="statBlock"
                  data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                  data-element-udi="umb://element/0deabe4c63ac4b8eb890fa9b92b7f518"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "3",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    class="block-stat has-ds has-rc"
                    data-stat="1,000"
                    data-auto="auto-false"
                    data-interval="interval-"
                  >
                    <div class="stat">
                      <span class="stat-prefix">$</span>
                      <span class="stat-count">1,000</span>
                    </div>

                    <div class="stat-label">
                      <p>
                        Annual <br />
                        Matching Donations*
                      </p>
                    </div>

                    <span class="sep"></span>
                  </div>
                </div>
                {/* start */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="richTextBlock"
                  data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                  data-element-udi="umb://element/ff0b91449487497a941ad3f9d774abf6"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div class="block-rich-text font-size-xx-small">
                    <p>
                      <span className="talign-right">*Per team member</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Professional;
