import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BoardOfDirectors = () => {
  const location = useLocation();

  const blaiseColeman = useRef();
  const paulEfron = useRef();
  const paulHerendeen = useRef();
  const scottHirsch = useRef();
  const sophiaLanglois = useRef();
  const andyPasternak = useRef();
  const marcYoskowitz = useRef();

  useEffect(() => {
    if (location.hash === "#blaise-coleman") {
      blaiseColeman.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#paul-efron") {
      paulEfron.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#paul-herendeen") {
      paulHerendeen.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#scott-hirsch") {
      scottHirsch.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#sophia-langlois") {
      sophiaLanglois.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#sophia-langlois") {
      sophiaLanglois.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#andy-pasternak") {
      andyPasternak.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#marc-yoskowitz") {
      marcYoskowitz.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Board of Directors | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Board of Directors" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/board-of-directors/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="site.css" />
      </Helmet>
      <Header />
      <main id="main">
        <div
          class="umb-block-grid"
          data-grid-columns="12;"
          style={{ "--umb-block-grid--grid-columns": "12" }}
        >
          <div class="umb-block-grid__layout-container">
            <div
              class="umb-block-grid__layout-item"
              data-content-element-type-alias="sectionBlock"
              data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
              data-element-udi="umb://element/ad374d730da74adf9d1e6cf3cb77a852"
              data-col-span="12"
              data-row-span="1"
              style={{
                "--umb-block-grid--item-column-span": "12",
                "--umb-block-grid--item-row-span": "1",
              }}
            >
              <section
                id="section-ad374d73-0da7-4adf-9d1e-6cf3cb77a852"
                class="block-section p-t-xx-large ignore-mobile-padding"
              >
                <div class="global-container">
                  <div
                    class="umb-block-grid__area-container"
                    style={{ "--umb-block-grid--area-grid-columns": "1" }}
                  >
                    <div
                      class="umb-block-grid__area"
                      data-area-col-span="12"
                      data-area-row-span="1"
                      data-area-alias="inner"
                      style={{
                        "--umb-block-grid--grid-columns": "12",
                        "--umb-block-grid--area-column-span": "12",
                        "--umb-block-grid--area-row-span": "1",
                      }}
                    >
                      <div class="umb-block-grid__layout-container">
                        <div
                          class="umb-block-grid__layout-item"
                          data-content-element-type-alias="columnBlock"
                          data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                          data-element-udi="umb://element/e6e5922f1f774fa7903a3169676f2296"
                          data-col-span="12"
                          data-row-span="1"
                          style={{
                            "--umb-block-grid--item-column-span": "12",
                            "--umb-block-grid--item-row-span": "1",
                          }}
                        >
                          <div
                            id="column-c7a52a1b-c59a-4816-98aa-76c412312960"
                            class="block-column"
                          >
                            <div
                              class="umb-block-grid__area-container"
                              style={{
                                "--umb-block-grid--area-grid-columns": "1",
                              }}
                            >
                              <div
                                class="umb-block-grid__area"
                                data-area-col-span="12"
                                data-area-row-span="1"
                                data-area-alias="inner"
                                style={{
                                  "--umb-block-grid--grid-columns": "12",
                                  "--umb-block-grid--area-column-span": "12",
                                  "--umb-block-grid--area-row-span": "1",
                                }}
                              >
                                <div class="umb-block-grid__layout-container">
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/cb8e5c628387460f9384c27fd1edfab8"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h1
                                      id="header-cb8e5c62-8387-460f-9384-c27fd1edfab8"
                                      class="block-header font-size-x-large m-b-x-large"
                                    >
                                      Board of Directors
                                    </h1>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/ca45e52ad12b45d39fc518b0273a8304"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="blaise-coleman"
                                      ref={blaiseColeman}
                                      class="block-header font-size-medium-large"
                                    >
                                      Blaise Coleman
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/8971b5a3a76d482280d5d2838048ec56"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Blaise Coleman served as President and
                                        Chief Executive Officer of Endo
                                        International plc and a member of its
                                        board of directors from March 2020 and
                                        continues to serve in such roles for
                                        Endo, Inc. Mr. Coleman previously served
                                        as Executive Vice President and Chief
                                        Financial Officer of Endo International
                                        plc from December 2016 to March 2020.
                                        Mr. Coleman joined Endo in January 2015
                                        as Vice President of Corporate Finance
                                        and was promoted to Senior Vice
                                        President, Global Finance Operations in
                                        November 2015. Prior to joining Endo,
                                        Mr. Coleman held a number of finance
                                        leadership roles with AstraZeneca,
                                        including as the Chief Financial Officer
                                        of the AstraZeneca/Bristol Myers Squibb
                                        U.S. Diabetes Alliance. He joined
                                        AstraZeneca in 2007 from Centocor, a
                                        wholly owned subsidiary of Johnson &amp;
                                        Johnson, where he held positions in both
                                        the Licenses &amp; Acquisitions and
                                        Commercial Finance organizations. Mr.
                                        Coleman’s move to Centocor in early 2003
                                        followed seven years’ experience with
                                        the global public accounting firm,
                                        PricewaterhouseCoopers LLP. Mr. Coleman
                                        is a Certified Public Accountant and
                                        holds a Bachelor of Science degree in
                                        accounting from Widener University and a
                                        Master of Business Administration degree
                                        from the Fuqua School of Business at
                                        Duke University.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/8058e764f3b04a4590ad3836428e70a0"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/eb5cc80dc87e4e6abb61251a7227753c"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="paul-efron"
                                      ref={paulEfron}
                                      class="block-header font-size-medium-large"
                                    >
                                      Paul Efron
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/e29a96687c6c47dfa8de889eb37c5e4c"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Paul Efron is the Executive Chairman of
                                        Oodles Energy, Inc., which installs and
                                        operates fast DC chargers in apartments
                                        and hotels. He has served in that role
                                        since he co-founded the company in
                                        December 2020. From 1984 to 2022, Mr.
                                        Efron worked in a variety of capacities
                                        at Goldman Sachs &amp; Company. He was
                                        elected General Partner of the firm in
                                        1998. He ran a variety of businesses for
                                        the firm, including Debt Capital Markets
                                        in London, New Product Development for
                                        the Investment Banking Division, and
                                        Leveraged Finance. Mr. Efron served for
                                        20 years on the Firmwide Capital
                                        Committee, which reviewed the firm’s
                                        underwriting and fixed income capital
                                        commitments. Mr. Efron has served on the
                                        Board of Directors of seven private
                                        companies and was the Chairman of the
                                        Board of Trustees of his Alma Mater,
                                        Pomona College.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/5413c08e00544768b45ccb85c5c1aeec"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/a935fe308c474c14a425ccbdaf594e73"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="paul-herendeen"
                                      ref={paulHerendeen}
                                      class="block-header font-size-medium-large"
                                    >
                                      Paul Herendeen, Board Chairperson
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/22115c4fa3e44aaa9c2bc08d7bbbaa8b"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Paul Herendeen has served as a member of
                                        the Elanco Pharmaceuticals Board of
                                        Directors since December 2020. Mr.
                                        Herendeen served as Advisor to the
                                        Chairman and Chief Executive Officer at
                                        Bausch Health Companies Inc. Prior to
                                        that, he was EVP and CFO of Bausch
                                        Health. Before joining Bausch Health, he
                                        served as EVP and CFO of Zoetis Inc.
                                        Prior to that time, Mr. Herendeen served
                                        as chief financial officer at Warner
                                        Chilcott, a specialty pharmaceuticals
                                        company. He rejoined Warner Chilcott
                                        after four years as EVP and CFO of
                                        MedPointe. Mr. Herendeen previously
                                        worked as a Principal Investor at
                                        Dominion Income Management and
                                        Cornerstone Partners and spent the early
                                        part of his career in banking and public
                                        accounting, holding various positions
                                        with the investment banking group of
                                        Oppenheimer &amp; Company, the capital
                                        markets group of Continental Bank
                                        Corporation and as a senior auditor with
                                        Arthur Andersen &amp; Company. Mr.
                                        Herendeen holds a bachelor’s degree from
                                        Boston College and earned an M.B.A. from
                                        the University of Virginia’s Darden
                                        School of Business.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/7a20ffd652cf4ede84f8e2757c5b9f90"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/d6417beeafd543c1b14826bc83abbf0e"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="scott-hirsch"
                                      ref={scottHirsch}
                                      class="block-header font-size-medium-large"
                                    >
                                      Scott Hirsch
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/6651197590c543cda42dd1dcc9188517"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Scott Hirsch has over 20 years of
                                        experience in healthcare operations,
                                        investment management, and financial
                                        services. He has served as an executive
                                        operator and Board member for privately
                                        held companies within Blackstone, Bain
                                        Capital, and Lauder Partner portfolios.
                                        Mr. Hirsch was formerly the CEO of Solta
                                        Medical, where he led the business
                                        growth, investment cycle, and global
                                        infrastructure development for a
                                        healthcare company operating in over 50
                                        countries. Prior to Solta, Mr. Hirsch
                                        was the President of the Ortho
                                        Dermatologics and OraPharma business
                                        segments and the Chief Business Officer
                                        of Bausch Health/Bausch &amp; Lomb. In
                                        those roles, he had responsibility for
                                        operational performance, capital
                                        allocation, strategic planning, M&amp;A,
                                        and investor communications. He
                                        additionally held the role of President
                                        of the Bausch Foundation and Patient
                                        Access with oversight of government
                                        affairs, product donation, and
                                        charitable giving. Prior to Bausch, Mr.
                                        Hirsch was a Portfolio Manager at
                                        Citadel’s Surveyor Capital fund
                                        overseeing investment and risk
                                        management decisions for a healthcare
                                        portfolio. Mr. Hirsch started on Wall
                                        Street in the investment banking group
                                        of Credit Suisse, where he was
                                        recognized by Institutional Investor
                                        magazine as a top Equity Research
                                        Analyst covering Specialty
                                        Pharmaceuticals, Biotechnology, and
                                        Global Generics companies. Mr. Hirsch
                                        began his career as a venture capital
                                        operator in product development, sales,
                                        and marketing roles at J.P. Morgan
                                        Partners and Morgan Stanley Ventures
                                        portfolio companies including Medsite, a
                                        healthcare technology company that was
                                        acquired by WebMD. Mr. Hirsch holds an
                                        M.B.A. in Healthcare Management and
                                        Finance from The Wharton School and
                                        B.F.A. with honors from The Rhode Island
                                        School of Design.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/05a002f355234cb8b9e0fa76e10127c3"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/f5239a6956df4becbc2630c391f4f5ce"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="sophia-langlois"
                                      ref={sophiaLanglois}
                                      class="block-header font-size-medium-large"
                                    >
                                      Sophia Langlois
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/c6f8cebf3cf14f3791b7c67d03f552f0"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Sophia Langlois is presently a board
                                        member, compensation committee member
                                        and Chair of the audit committees for
                                        both Alaris Equity Partners and Loop
                                        Energy Inc. She is also a board member,
                                        compensation committee member and audit
                                        committee member of Pason Systems Inc.
                                        Ms. Langlois was formerly a board member
                                        at Essential Energy Services where she
                                        chaired the audit committee. She has
                                        been involved with numerous
                                        not-for-profit organizations and is
                                        presently on the board of Telus Spark
                                        Science Centre. As a public company
                                        audit partner with KPMG LLP in Calgary
                                        (2006 to 2020), she served domestic,
                                        cross-border and international companies
                                        across numerous industry sectors. She
                                        also led the Corporate Services group
                                        for KPMG Calgary and was the KPMG
                                        National Audit Partner in charge of
                                        People Strategy for three years. She
                                        received her Bachelor of Commerce degree
                                        from the University of Calgary, holds a
                                        Chartered Professional Accountant
                                        designation and is a member of the Human
                                        Resources Institute of Alberta. Ms.
                                        Langlois has been granted an ICD.D
                                        designation by the Institute of
                                        Corporate Directors.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/d282c4ac2b574f6b858840f724e1ee18"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/f6b114a8edc141f2a1be8fa4649b353d"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="andy-pasternak"
                                      ref={andyPasternak}
                                      class="block-header font-size-medium-large"
                                    >
                                      Andy Pasternak
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/b4d2254198f946b2aa5085c93a208cf8"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Andy Pasternak is a biopharmaceutical
                                        executive and expert with over 25 years
                                        of experience, and currently serves as
                                        an Advisory Partner at Bain &amp;
                                        Company, a leading global consulting
                                        firm. Most recently, Mr. Pasternak
                                        served as Executive Vice President,
                                        Chief Strategy Officer at Horizon
                                        Therapeutics, a biotechnology company
                                        focused on serious, rare autoimmune
                                        diseases; in this role, he was
                                        responsible for corporate strategy,
                                        M&amp;A / business development,
                                        commercial development, and portfolio
                                        management, and he played a central role
                                        in the $28 billion acquisition of
                                        Horizon by Amgen, Inc. Prior to joining
                                        Horizon in 2019, Mr. Pasternak was a
                                        senior partner at Bain &amp; Company,
                                        where he served as Head of the
                                        Healthcare Practice in the Americas.
                                        Earlier in his career, he was an analyst
                                        in the Investment Banking division of
                                        Chemical Securities, Inc. (now part of
                                        J.P. Morgan). Mr. Pasternak is also an
                                        Adjunct Lecturer in the Healthcare
                                        Program at the Kellogg School of
                                        Management. He serves on the Board of
                                        Directors of Make-A-Wish, Illinois
                                        Chapter. Mr. Pasternak received his B.A.
                                        in economics from Northwestern
                                        University and an M.B.A. from the
                                        University of Chicago.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="separatorBlock"
                                    data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                    data-element-udi="umb://element/91c9e1063fd542b387ae0cebf65579f4"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-separator type-full-width m-b-large m-t-medium-large"></div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/87363651f7ff4937ae901c57e2d0da9c"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h2
                                      id="marc-yoskowitz"
                                      ref={marcYoskowitz}
                                      class="block-header font-size-medium-large"
                                    >
                                      Marc Yoskowitz
                                    </h2>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/f7acd6cc7a0a42bd9e1ef95fee88742b"
                                    data-col-span="8"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span": "8",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        Marc Yoskowitz serves as Chief Executive
                                        Officer of Evozyne, Inc. a venture
                                        capital based biotech designing novel
                                        proteins leveraging generative AI. He
                                        has served as a member of the Board of
                                        Directors at Mereo BioPharma since 2022
                                        where he is a member of the R&amp;D
                                        Committee. Previously he served as EVP
                                        and Chief Strategy Officer, Life
                                        Sciences at Tempus, Inc. Prior to
                                        Tempus, Mr. Yoskowitz was Chief Business
                                        Officer, Pfizer Essential Health,
                                        leading a range of corporate initiatives
                                        within the Pfizer portfolio. Prior to
                                        Pfizer, he served as SVP, Strategy and
                                        Corporate Development at Hospira and was
                                        a member of the Executive Committee.
                                        Earlier in his career, Mr. Yoskowitz led
                                        business development at a specialty
                                        pharmaceutical company, spent eight
                                        years at McKinsey &amp; Company where he
                                        was an Associate Principal, and began
                                        his career as an M&amp;A lawyer at
                                        Davis, Polk &amp; Wardwell in New York.
                                        Mr. Yoskowitz received a bachelor’s
                                        degree magna cum laude from Washington
                                        University in St. Louis and holds a J.D.
                                        from Columbia University School of Law.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default BoardOfDirectors;
