import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LaurePark = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Laure Park | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Laure Park" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/laure-park/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/team.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Laure Park</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/mgupw4yy/laure-park.webp"
                  alt="Laure Park"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Laure Park</h1>

              <p class="team-position">
                Senior Vice President, Investor Relations and Corporate Affairs
              </p>

              <div class="team-bio">
                <p>
                  Laure Park has served as Senior Vice President, Investor
                  Relations and Corporate Affairs for Endo International plc
                  since August 2018 and continues in that role for Endo. In this
                  role, Ms. Park is responsible for leading Endo’s Investor
                  Relations and Corporate Affairs teams, including corporate
                  responsibility &amp; sustainability, internal communications
                  and engagement, as well as corporate communications, branding,
                  and public relations.
                </p>
                <p>
                  Prior to joining Endo International plc, Ms. Park spent 23
                  years at Quest Diagnostics where she held key leadership roles
                  in different functional areas including finance, investor
                  relations, and communications. Most recently, she served as
                  Quest Diagnostics’ Vice President, Customer Experience, where
                  she was responsible for building a customer-focused culture
                  and representing the customer in the design and delivery of
                  the customer experience. Prior to that, Ms. Park was Vice
                  President of Communications &amp; Investor Relations,
                  responsible for leading investor relations and integrating it
                  with the internal and external communications functions. She
                  also served as the Vice President &amp; General Manager of
                  their Prescription Drug Monitoring business.
                </p>
                <p>
                  Ms. Park began her career in public accounting with Deloitte.
                  She holds Bachelor's degrees in Accounting and Communications
                  from the University of North Dakota.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default LaurePark;
