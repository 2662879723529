import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JamesTursi = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>James Tursi | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="James Tursi" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/james-tursi/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">James Tursi</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/4rbpch1g/james-tursi.webp"
                  alt="James Tursi"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">James Tursi</h1>

              <p class="team-position">
                Executive Vice President, Global Research AND Development
              </p>

              <div class="team-bio">
                <p>
                  James Tursi, M.D., has served as appointed Executive Vice
                  President, Global Research &amp; Development for Endo
                  International plc since January 2022, and continues in that
                  role for Endo. In this role, Dr. Tursi is responsible for
                  leading global research &amp; development, medical affairs,
                  and regulatory operations.
                </p>
                <p>
                  Prior to joining Endo International plc, he held senior
                  leadership roles at Ferring Pharmaceuticals U.S., Antares
                  Pharmaceuticals, and Aralez Pharmaceuticals. Prior to Aralez,
                  Dr. Tursi was Chief Medical Officer and Vice President of
                  Clinical R&amp;D at Auxilium Pharmaceuticals until its
                  acquisition by Endo International plc in 2015.
                </p>
                <p>
                  Dr. Tursi practiced medicine and surgery for over 10 years and
                  created a medical education company, I Will Pass®, which
                  assisted physicians in the process of board certification. He
                  performed his residency in Gynecology and Obstetrics at the
                  Johns Hopkins Hospital, holds a Bachelor of Science degree in
                  Chemistry and Biology from Ursinus College, and a Doctor of
                  Medicine degree from the Medical College of Pennsylvania. Dr.
                  Tursi is a member of the Ideal Image Board of Directors.
                  Previously, Dr. Tursi served as a member of the Agile
                  Therapeutics, Inc. Board of Directors from October 2014 to
                  October 2022.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default JamesTursi;
