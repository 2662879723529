import React from "react";

const Values = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/0c6136e923264140be03cf98f308734c"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="endo-values"
        class="block-section align-h-left p-b-x-large p-t-0"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/e993576b5193409b98edb700bc0641ff"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="endo-values-col-video"
                    class="block-column has-rc align-h-center has-bg-color background-color-light-gray p-b-xx-large p-t-xx-large"
                  >
                    <video className="bg-video" autoPlay muted loop playsInline>
                      <source src="/bg-values.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>

                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/d7798d222e2d420590355566f9100bf9"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="endo-values-col-content"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/38249be9ac0f415da73c70bd07b0d602"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label talign-center">
                                        Values
                                      </p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/353682d4a7d94d99acaa7f7c11b09e1a"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text font-size-large font-weight-medium">
                                        <p style={{ "text-align": "center" }}>
                                          Our values and behaviors serve as
                                          guiding principles that shape our
                                          daily actions and equip us to fulfill
                                          our purpose.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/b26b70e6efe44c0ea45b5c9a81b70f1f"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text font-size-medium">
                                        <p style={{ "text-align": "center" }}>
                                          Our passionate team members embrace
                                          our values, creating a Culture of
                                          Excellence that drives our success in
                                          advancing healthcare.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="valuesBlock"
                            data-content-element-type-key="93b610eb-7d9b-42a7-a80d-c0c5043ebe82"
                            data-element-udi="umb://element/7f96ac4efe084ca0b154dbcd35c11dfa"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-values global-container">
                              <ul class="value-nav">
                                <li>
                                  Value <span class="sep">|</span>
                                  <span class="value-nav-title">
                                    Integrity &amp; Quality
                                  </span>
                                </li>
                                <li>
                                  Value <span class="sep">|</span>
                                  <span class="value-nav-title">
                                    Collaboration
                                  </span>
                                </li>
                                <li>
                                  Value <span class="sep">|</span>
                                  <span class="value-nav-title">Drive</span>
                                </li>
                                <li>
                                  Value <span class="sep">|</span>
                                  <span class="value-nav-title">
                                    Innovation
                                  </span>
                                </li>
                                <li>
                                  Value <span class="sep">|</span>
                                  <span class="value-nav-title">Empathy</span>
                                </li>
                              </ul>
                              <div class="value-values">
                                <div
                                  data-key="dc21c4cd-dafe-490a-a2de-5f2cada2b523"
                                  id="value-integrity-and-quality"
                                  class="value-row"
                                >
                                  <h2 class="value-header">
                                    Integrity &amp; Quality
                                  </h2>
                                  <p class="value-subheader">Own it</p>
                                  <div class="value-copy">
                                    <ul>
                                      <li>
                                        Mean what you say. Say what you mean.
                                      </li>
                                      <li>
                                        Make yourself proud in a job done right.
                                      </li>
                                      <li
                                        style={{ color: "rgb(232, 119, 34)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(232, 119, 34)" }}
                                        >
                                          Act like an owner. Do it right even
                                          when no one is watching.
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  data-key="9525b234-6840-4086-adcc-d6b1c91155d0"
                                  id="value-collaboration"
                                  class="value-row"
                                >
                                  <h2 class="value-header">Collaboration</h2>
                                  <p class="value-subheader">
                                    Unite with purpose&#xA0;
                                  </p>
                                  <div class="value-copy">
                                    <ul>
                                      <li>Work as a team to achieve more.</li>
                                      <li>
                                        Come together with one goal and no
                                        hidden agendas.
                                      </li>
                                      <li
                                        style={{ color: "rgb(232, 119, 34)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(232, 119, 34)" }}
                                        >
                                          Work hard and support each other
                                          harder.
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  data-key="d22847af-a99b-461f-b724-458fe44ff010"
                                  id="value-drive"
                                  class="value-row"
                                >
                                  <h2 class="value-header">Drive</h2>
                                  <p class="value-subheader">
                                    Be action-biased
                                  </p>
                                  <div class="value-copy">
                                    <ul>
                                      <li>
                                        Wake up determined. Go to bed satisfied.
                                      </li>
                                      <li>
                                        Be on a mission to deliver on our
                                        mission.
                                      </li>
                                      <li
                                        style={{ color: "rgb(232, 119, 34)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(232, 119, 34)" }}
                                        >
                                          Be relentless in your pursuit of
                                          excellence.
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  data-key="e9d2d18e-ad76-4532-b6d0-32486274cee5"
                                  id="value-innovation"
                                  class="value-row"
                                >
                                  <h2 class="value-header">Innovation</h2>
                                  <p class="value-subheader">
                                    (re)Invent a better way
                                  </p>
                                  <div class="value-copy">
                                    <ul>
                                      <li>Ask “why,” then “why not?”</li>
                                      <li>
                                        Ride the wave of curiosity—it leads to
                                        discovery.
                                      </li>
                                      <li
                                        style={{ color: "rgb(232, 119, 34)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(232, 119, 34)" }}
                                        >
                                          Walk out of the past to step into the
                                          future.
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  data-key="3d35d250-2a27-43e7-9883-ae24c4123142"
                                  id="value-empathy"
                                  class="value-row"
                                >
                                  <h2 class="value-header">Empathy</h2>
                                  <p class="value-subheader">
                                    Listen with your ears and your heart&#xA0;
                                  </p>
                                  <div class="value-copy">
                                    <ul>
                                      <li>
                                        Learn why it matters to the customers we
                                        serve.
                                      </li>
                                      <li>Understand others' perspectives.</li>
                                      <li
                                        style={{ color: "rgb(232, 119, 34)" }}
                                      >
                                        Listen without judgement and challenge
                                        your own assumptions.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Values;
