import React from "react";

const ExperienceStrategy = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/76c1f81462a747718f42da58bdff27b7"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-76c1f814-62a7-4771-8f42-da58bdff27b7"
        class="block-section p-b-x-large p-t-xx-large ignore-mobile-padding"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/a0fe6c8ffc2743f891ceb3e04ef6bdad"
                  data-col-span="7"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "7",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-09226ac7-c1ea-4e29-bd9b-a1348b6ba6a6"
                    class="block-column p-b-medium-large p-t-small"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/1b28d4cf96d84fe9a99683f2428c012c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label">Experience and Strategy</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/2af239199f6c4cb6be0bda28ccdff276"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h1
                              id="header-2af23919-9f6c-4cb6-be0b-da28ccdff276"
                              class="block-header font-size-x-large"
                            >
                              Meet Our Leadership Team
                            </h1>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/dfd06770becb4fbab79661b98ba6b28f"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-small">
                              <p>
                                Our company leaders bring together a wealth of
                                experience in the pharmaceutical and life
                                science industries. Through their strategic
                                vision, they guide Endo’s continued
                                transformation and drive the development and
                                delivery of innovative solutions to help address
                                unmet medical needs and improve patient
                                outcomes. 
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/19e6851f39164b30b7b25384a65c2b60"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-7b91953e-afb4-4025-9498-38c0c4e1f2c4"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="teamMemberBlock"
                            data-content-element-type-key="b416c7a1-696a-46e7-96aa-84711c1ae60f"
                            data-element-udi="umb://element/3b009a3f74fc4a10b5b0328f49ec273d"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-team-member vs-block-grid">
                              <div
                                data-key="1c03280d-45ba-483e-b144-4f52f5c99e9c"
                                id="team-member-1c03280d-45ba-483e-b144-4f52f5c99e9c"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/tu1pz3qw/blaise-coleman.webp"
                                    alt="Blaise Coleman"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Blaise Coleman</h2>
                                <p class="team-position">
                                  President and Chief Executive Officer
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/blaise-coleman/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="72df7e06-b1c3-4bba-80c3-8564564ca801"
                                id="team-member-72df7e06-b1c3-4bba-80c3-8564564ca801"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/4dvfoen3/patrick-barry.webp"
                                    alt="Patrick Barry"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Patrick Barry</h2>
                                <p class="team-position">
                                  Executive Vice President and
                                  <br />
                                  President, Global Commercial Operations
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/patrick-barry/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="2722012a-16b5-41d1-8f27-8e19b571f12a"
                                id="team-member-2722012a-16b5-41d1-8f27-8e19b571f12a"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/g0jhsq25/tracy-basso.webp"
                                    alt="Tracy Basso"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Tracy Basso</h2>
                                <p class="team-position">
                                  Chief Human Resources Officer
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/tracy-basso/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="75a14619-2baf-4812-952c-e078afeb3f93"
                                id="team-member-75a14619-2baf-4812-952c-e078afeb3f93"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/vvukurro/mark-bradley.webp"
                                    alt="Mark Bradley"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Mark Bradley</h2>
                                <p class="team-position">
                                  Executive Vice President and Chief Financial
                                  Officer
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/mark-bradley/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="dbf3818d-783c-42ba-b15a-6bb82ab44c99"
                                id="team-member-dbf3818d-783c-42ba-b15a-6bb82ab44c99"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/vu2h4p4x/matthew-maletta.webp"
                                    alt="Matthew J. Maletta"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Matthew J. Maletta</h2>
                                <p class="team-position">
                                  Executive Vice President, Chief Legal Officer
                                  and Secretary
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/matthew-j-maletta/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="af7a725f-09ac-4d00-acb6-d74f5d40ad82"
                                id="team-member-af7a725f-09ac-4d00-acb6-d74f5d40ad82"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/s34l43j1/mick-mcguinness.webp"
                                    alt="Mick McGuinness"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Mick McGuinness</h2>
                                <p class="team-position">
                                  Senior Vice President,
                                  <br />
                                  Global Head of Quality and Compliance
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/mick-mcguinness/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="fc957b1c-87ad-45a2-8dce-decf6b37217c"
                                id="team-member-fc957b1c-87ad-45a2-8dce-decf6b37217c"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/mcthnsap/james-papp.webp"
                                    alt="James Papp"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">James Papp</h2>
                                <p class="team-position">
                                  Head of Endo Ireland and
                                  <br />
                                  Senior Vice President, Global Supply Chain
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/james-papp/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="3db9bd24-c4eb-4c45-84e6-c3142f2417f8"
                                id="team-member-3db9bd24-c4eb-4c45-84e6-c3142f2417f8"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/mgupw4yy/laure-park.webp"
                                    alt="Laure Park"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Laure Park</h2>
                                <p class="team-position">
                                  Senior Vice President, Investor Relations and
                                  Corporate Affairs
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/laure-park/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="9cd622d7-6de7-49ce-be38-bf218bbc5f7a"
                                id="team-member-9cd622d7-6de7-49ce-be38-bf218bbc5f7a"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/k3inmzwi/michael-randolph.webp"
                                    alt="Michael Randolph"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Michael Randolph</h2>
                                <p class="team-position">
                                  Senior Vice President,
                                  <br />
                                  Global Head of Manufacturing Operations
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/michael-randolph/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="f5d598f0-1a6e-4d1f-9d2c-f035d49eb104"
                                id="team-member-f5d598f0-1a6e-4d1f-9d2c-f035d49eb104"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/dqwb1ahf/cheryl-stouch.webp"
                                    alt="Cheryl Stouch"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Cheryl Stouch</h2>
                                <p class="team-position">
                                  Senior Vice President, Information Technology
                                  and Chief Information Officer
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/cheryl-stouch/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="fb3394dd-8c58-4342-bb7b-10433cf65404"
                                id="team-member-fb3394dd-8c58-4342-bb7b-10433cf65404"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/4rbpch1g/james-tursi.webp"
                                    alt="James Tursi"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">James Tursi</h2>
                                <p class="team-position">
                                  Executive Vice President, Global Research AND
                                  Development
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/james-tursi/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                data-key="08417bb8-076c-44d3-9a96-745109b876de"
                                id="team-member-08417bb8-076c-44d3-9a96-745109b876de"
                                class="grid-tile"
                              >
                                <figure class="team-headshot">
                                  <img
                                    loading="lazy"
                                    src="https://www.endo.com/media/thvijgu1/susan-williamson.webp"
                                    alt="Susan Williamson"
                                    width="0"
                                    height="0"
                                  />
                                  <figcaption></figcaption>
                                </figure>

                                <h2 class="team-name">Susan Williamson</h2>
                                <p class="team-position">
                                  Senior Vice President and Chief Compliance
                                  Officer
                                </p>
                                <span class="sep"></span>

                                <div class="block-btn type-text">
                                  <a
                                    class="common-btn"
                                    href="/company/leadership/susan-williamson/"
                                  >
                                    Read More
                                    <div class="arrow-wrap">
                                      <svg
                                        width="40"
                                        height="12"
                                        viewBox="0 0 40 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                          fill="#E87722"
                                        ></path>
                                        <path
                                          d="M33 11L38 6L33 1"
                                          stroke="#E87722"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        ></path>
                                      </svg>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExperienceStrategy;
