import React, { useEffect, useState } from "react";

const CodeOfConduct = () => {
  // State to keep track of the expanded accordion panel
  const [expandedId, setExpandedId] = useState("");

  const handleAccordionClick = (id) => {
    setExpandedId(expandedId === id ? "" : id);
  };

  // Set the height of the panel based on the state change
  useEffect(() => {
    const panels = document.querySelectorAll(".accordion-panel");
    panels.forEach((panel) => {
      const inner = panel.querySelector(".accordion-panel-inner");
      if (panel.parentElement.dataset.key === expandedId) {
        panel.style.height = `${inner.offsetHeight}px`;
      } else {
        panel.style.height = "0px";
      }
    });
  }, [expandedId]);

  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/509fdf33526e4db7b865593496ee4841"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-509fdf33-526e-4db7-b865-593496ee4841"
        class="block-section p-b-x-large p-t-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/9d3353286a434a7cbfae41e313e83120"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-0baa76b2-5ede-4e9b-82b7-d1e194307d79"
                    class="block-column p-r-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/ab40fe940821450c9543719af7e1c1e8"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-ab40fe94-0821-450c-9543-719af7e1c1e8"
                              class="block-header font-size-large"
                            >
                              Code of Conduct
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/17fcf33ab5894478bb0b2a3a8862db14"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Anchored by our Code of Conduct, we work at
                                every level of the company to educate our team
                                members about our policies and standards and the
                                methods of raising issues or concerns.
                              </p>
                              <p>
                                Our Code of Conduct applies to all team members
                                globally and defines how we interact with
                                patients, healthcare providers, payors,
                                suppliers, government officials, the healthcare
                                community, shareholders, and our colleagues. It
                                is designed to enable team members to apply the
                                principles of respect, trust, and integrity to
                                their day-to-day activities. All team members,
                                full-time and select part-time contractors, and
                                vendors are required to complete annual training
                                on the tenets of the Code of Conduct and certify
                                that they will adhere to it.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/0f10b0f7b08d47c18d04f0eae1b340aa"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-btn type-text">
                              <a
                                class="common-btn"
                                href="/company/policies-and-statements/#code-of-conduct"
                              >
                                View Our Code of Conduct
                                <div class="arrow-wrap">
                                  <svg
                                    width="40"
                                    height="12"
                                    viewBox="0 0 40 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                      fill="#E87722"
                                    />
                                    <path
                                      d="M33 11L38 6L33 1"
                                      stroke="#E87722"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* code of conduct */}
                <div className="d" style={{ width: "70vh" }}>
                  {" "}
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="accordionBlock"
                    data-content-element-type-key="0c32fb25-ca42-4ca6-985a-e8a7ee7031b4"
                    data-element-udi="umb://element/e118b757e58c4864b20eb781549f5f11"
                    data-col-span="12"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": "12",
                      "--umb-block-grid--item-row-span": "1",
                    }}
                  >
                    <div
                      className="block-accordion"
                      data-expand="False"
                      data-remove-indent="False"
                      aria-roledescription="accordion"
                    >
                      {accordionData.map((data) => (
                        <div
                          key={data.id}
                          data-key={data.id}
                          className={`accordion-row ${
                            data.id === expandedId ? "active" : ""
                          }`}
                        >
                          <h2
                            aria-expanded={
                              data.id === expandedId ? "" : "false"
                            }
                            tabIndex="0"
                            className="accordion-header"
                            onClick={() => handleAccordionClick(data.id)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAccordionClick(data.id);
                              }
                            }}
                          >
                            {data.title}
                          </h2>
                          <div className="accordion-panel">
                            <div className="accordion-panel-inner">
                              <div
                                className="umb-block-grid"
                                data-grid-columns="12"
                                style={{
                                  "--umb-block-grid--grid-columns": "12",
                                }}
                              >
                                <div className="umb-block-grid__layout-container">
                                  {data.contents.map((content, index) => (
                                    <div
                                      key={index}
                                      className="umb-block-grid__layout-item"
                                      data-content-element-type-alias={
                                        content.typeAlias
                                      }
                                      data-content-element-type-key={
                                        content.typeKey
                                      }
                                      data-element-udi={content.elementUdi}
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div className="block-btn type-text">
                                        {content.p ? (
                                          <p
                                            className={`${
                                              content.list && "disc-text"
                                            } ${
                                              content.bold && "bold-text"
                                            } accordian-text-updated`}
                                          >
                                            {content.strong && (
                                              <>
                                                <strong>
                                                  {content.strong}
                                                </strong>
                                                <br />
                                                <br />
                                              </>
                                            )}
                                            {content.p}
                                          </p>
                                        ) : (
                                          <a
                                            className={`${
                                              content.sublink && "sublink"
                                            } common-btn`}
                                            href={content.href}
                                            target={`${
                                              !content.sublink && !content.self
                                                ? "_blank"
                                                : "_self"
                                            }`}
                                            rel="noopener noreferrer"
                                          >
                                            {content.text}
                                            {!content.sublink && (
                                              <div className="arrow-wrap">
                                                <svg
                                                  width="40"
                                                  height="12"
                                                  viewBox="0 0 40 12"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                    fill="#E87722"
                                                  />
                                                  <path
                                                    d="M33 11L38 6L33 1"
                                                    stroke="#E87722"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                  />
                                                </svg>
                                              </div>
                                            )}
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* put the code */}
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/339e2a01faeb454a95f1fbe8c8f06f24"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-d3b0746a-edbd-4ecd-b803-3096404e44ed"
                    class="block-column p-t-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/821e69a7631e4ee1a2f6dfdde266e2aa"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "4",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-ba552e7e-11af-46e0-b10a-d1a06b1e9468"
                              class="block-column has-ds has-rc p-l-large p-r-large p-b-medium-large p-t-medium-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/bd50639abdff421ea4b3bb2734a34770"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-bd50639a-bdff-421e-a4b3-bb2734a34770"
                                        class="block-header font-size-medium-large font-weight-medium talign-center text-color-dark-orange"
                                      >
                                        Know the Rules
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/8321e746db18446bb44f7c346fdbfddb"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text talign-center">
                                        <p>
                                          By educating ourselves about relevant
                                          policies and procedures
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/3330ea9e699d4f2bb46f69cd4bb56ecc"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator type-default align-h-center"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/46b92a65a12549e3b0ed748b816d0df3"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "4",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-4f48c47a-1046-42e3-b7d7-3b225a9775e2"
                              class="block-column has-ds has-rc p-l-medium-large p-r-medium-large p-b-medium-large p-t-medium-large ignore-mobile-padding"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/e6f2dc997beb4268afb724c54299daff"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-e6f2dc99-7beb-4268-afb7-24c54299daff"
                                        class="block-header font-size-medium-large font-weight-medium talign-center text-color-dark-orange"
                                      >
                                        Act with Integrity &amp; Respect
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/9f7f8283f9f34534b9ffa9a61816ef14"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text talign-center">
                                        <p>
                                          By showing respect for your
                                          colleagues, our customers, and all of
                                          Endo’s stakeholders
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/e7a7acfd9df0474886aa25a50d953bfe"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator type-default align-h-center"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/97a24627a2c747b0840f15cd0582fc3c"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "4",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-0b535985-0633-4739-87b8-3e4d0f739240"
                              class="block-column has-ds has-rc p-l-large p-r-large p-b-medium-large p-t-medium-large ignore-mobile-padding"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/145e3b48828c4035a0d6d936c57daee6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-145e3b48-828c-4035-a0d6-d936c57daee6"
                                        class="block-header font-size-medium-large font-weight-medium talign-center text-color-dark-orange"
                                      >
                                        Speak Up
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/e1fcba31eb284d69b175634c4d981362"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text talign-center">
                                        <p>
                                          By reporting behavior inconsistent
                                          with the Code of Conduct
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/41fe7e6eeebb41ed9c9245ea1fbc59df"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator type-default align-h-center"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const accordionData = [
  {
    id: "working",
    title: "Working with Healthcare Professionals ",
    contents: [
      {
        p: "We educate healthcare professionals about our products, collaborate with them on research, and rely on their expertise as advisers. These interactions are governed by laws, regulations, and standards and always reflect our commitment to integrity.",
      },
    ],
  },
  {
    id: "responsible",
    title: "Responsible Marketing & Promotion ",
    contents: [
      {
        p: "Our marketing and promotion highlight the approved uses of our products and the needs of patients. The truthful, on-label, fair-balanced, and ethical communications are designed to help healthcare professionals make informed and independent decisions about how they can use our products for their patients.",
      },
    ],
  },
  {
    id: "government",
    title: "Government Interactions",
    contents: [
      {
        p: "Governments are integral to the pharmaceutical industry, from research and development and regulatory submissions to manufacturing and import/export activities. Through these interactions, we follow all anti-corruption and anti-bribery laws and act with the utmost integrity and transparency.",
      },
    ],
  },
  {
    id: "equal",
    title: "Equal Employment and Fair Treatment",
    contents: [
      {
        p: "Endo provides equal employment opportunity and fair treatment to all applicants and employees on the basis of merit. We do not tolerate discrimination or harassment. Team members embrace our values and treat each other with respect, dignity, and empathy.",
      },
    ],
  },
];

export default CodeOfConduct;
