import React from "react";
import AlsoConduct from "./AlsoConduct";

const PatientSafety = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/9d77acdfb1b04038b446e7d9a01553e5"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-9d77acdf-b1b0-4038-b446-e7d9a01553e5"
        class="block-section p-b-x-large p-t-x-large"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/je5l3eqo/background.webp"
            alt="decorative background gradient"
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/425c572bcead408a994c9130bbbd16b6"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-06900bf3-07cc-4757-a88a-abab9e28a221"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/cb922e78d28344c8a0c4f815ea5319d6"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <p class="block-label">Patient Safety</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/56e58608f79643ebb8f0c89aec8fdf18"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-56e58608-f796-43eb-b8f0-c89aec8fdf18"
                              class="block-header font-size-large"
                            >
                              Safety is our top priority as we develop new
                              products.
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/72d9827b5e144ad4886e437d653819b1"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-75096b20-7418-494b-b507-f7dab750a537"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/76d30b4ded8e418f894dd62f31208cc6"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                <span>
                                  Our Pharmacovigilance team works to identify,
                                  understand, and communicate all safety
                                  information about Endo’s products to both
                                  prescribers and patients.
                                </span>
                              </p>
                              <p>
                                <span>
                                  This process starts with our clinical trials,
                                  where we identify adverse events and risks so
                                  that they can be shared with regulators to
                                  assess the overall risk-benefit profile and
                                  determine the product’s viability. Once
                                  approved, these risks must be clearly
                                  communicated.
                                </span>
                              </p>
                              <p>
                                <span>
                                  After our products get to market, we have a
                                  responsibility to detect and report adverse
                                  events and quality complaints associated with
                                  our products, including but not limited to:
                                  unfavorable side effects, dosing errors,
                                  misuse, malfunctions, and concerns about
                                  performance or efficacy of a product. Our
                                  established monitoring program, which includes
                                  a call center, email, and medical information
                                  portal, tracks this information in a timely
                                  and efficient manner, enabling us to report
                                  any significant side effects or safety issues
                                  that may occur to the FDA and other regulatory
                                  agencies.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <AlsoConduct />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PatientSafety;
