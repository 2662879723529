import React from "react";

const OurResponsibility = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/e804026da977438b99a371a49e6414b8"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-e804026d-a977-438b-99a3-71a49e6414b8"
        class="block-section black-60-percent text-color-white p-b-xxx-large p-t-xxx-large"
      >
        <video className="bg-video" autoPlay muted loop playsInline>
          <source src="/our-responsibility-web.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div class="overlay"></div>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": 1 }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/c8d75cd6fcc147a9902f3be075046cef"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 3,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-c50b1b52-b431-4862-9492-72c10b8867c9"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/430580b06b504afc8fc41136c7b72864"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <p class="block-label">Our Responsibility</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/50441e7067ce4750857fa7be81db9471"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-50441e70-67ce-4750-857f-a7be81db9471"
                              class="block-header font-size-large"
                            >
                              Committed to the Adoption of More Sustainable
                              Practices
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/a5101da7cae948c8a7287f22fdea26c7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We strive to help everyone we serve live their
                                best life, and that vision calls on us to be a
                                force for good in everything we do.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/c675ad5dbc2e4a2aa5662a6eb74b504e"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/908dafb62841429195e6f660795c10cf"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 3,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-dd82df3b-499d-4715-b494-25d7875f9226"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/326255e93e854b87bf196194d656a6fb"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 3,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image is-icon">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/vzoilkxm/integrity-icon.svg"
                                alt="Shield with checkmark icon"
                              />
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/6f0475da47784cb19903e513c13dcfd3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-6f0475da-4778-4cb1-9903-e513c13dcfd3"
                              class="block-header font-size-medium"
                            >
                              Integrity
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/c674ea08e95140ebb25aeffc85d63480"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We aim to do the right thing, always. That means
                                operating with unwavering ethical standards,
                                taking accountability, and embracing a quality
                                mindset.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/c9369b037403476282ab99a4ac96be53"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-btn type-text">
                              <a
                                class="common-btn"
                                href="/responsibility/ethics-and-compliance/"
                              >
                                Our Business Practices
                                <div class="arrow-wrap">
                                  <svg
                                    width="40"
                                    height="12"
                                    viewBox="0 0 40 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                      fill="#E87722"
                                    />
                                    <path
                                      d="M33 11L38 6L33 1"
                                      stroke="#E87722"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/fc2c9ed2bf044afea0ddb3f7ad9da17c"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 3,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-5af522ee-e0ac-45db-a943-2b51f0740792"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/e065fe1be2904f4ab0221cf37321e3c8"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 3,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image is-icon">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/fn1pshol/excellence.svg"
                                alt="Ribbon Icon"
                              />
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/9baddd27236c418bb5c747012c54743e"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-9baddd27-236c-418b-b5c7-47012c54743e"
                              class="block-header font-size-medium"
                            >
                              Culture of Excellence
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/4e693852e0244b328cefdbe5bdae29ad"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Our commitment to quality and safety drives our
                                work, from the initial stages of research and
                                development to the delivery of our products to
                                those who need them.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/2326749b47c348898391ab408f3e85fc"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-btn type-text">
                              <a
                                class="common-btn"
                                href="/responsibility/customers-and-patients/"
                              >
                                Our Customers
                                <div class="arrow-wrap">
                                  <svg
                                    width="40"
                                    height="12"
                                    viewBox="0 0 40 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                      fill="#E87722"
                                    />
                                    <path
                                      d="M33 11L38 6L33 1"
                                      stroke="#E87722"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/b12cd5d9cf7e4c499c692a9c9b2b7748"
                  data-col-span="3"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 3,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-d40d1f4e-9453-424f-b693-738cb1ebdbdb"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/297308a0027b4945a9b40443716e2a22"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 3,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image is-icon">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/sojiw5dx/sustainability.svg"
                                alt="Hands holding a plant icon"
                              />
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/3e43177016444ed48efdcd8dd0d7e9d5"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-3e431770-1644-4ed4-8efd-cd8dd0d7e9d5"
                              class="block-header font-size-medium"
                            >
                              Sustainability
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/16b9ed8404a34e17b8f12e1e2282c553"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We operate our business in a responsible manner
                                that seeks to minimize environmental impact.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/ad20e7131c414407bc49c8f69847d150"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-btn type-text">
                              <a
                                class="common-btn"
                                href="/responsibility/environment/"
                              >
                                Our World
                                <div class="arrow-wrap">
                                  <svg
                                    width="40"
                                    height="12"
                                    viewBox="0 0 40 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                      fill="#E87722"
                                    />
                                    <path
                                      d="M33 11L38 6L33 1"
                                      stroke="#E87722"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurResponsibility;
