import React from "react";

const Main = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="heroBlock"
      data-content-element-type-key="e8d63b4b-5bbe-4afd-8533-01727a730aeb"
      data-element-udi="umb://element/12d90b898a0e4832b112f6a6e45c6644"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section class="block-section hero-block">
        <picture class="hero-image">
          <source
            media="(max-width: 781px)"
            srcset="https://www.endo.com/media/icghzvta/hero-mobile-progress-reports.webp"
            width="0"
            height="0"
            alt=""
          />

          <img
            class="bg-image"
            src="https://www.endo.com/media/k0hlzcpn/hero-progress-and-reports.webp"
            alt="Farm with solar panels and energy generating windmills."
            width="0"
            height="0"
          />
        </picture>

        <div class="section-block-inner">
          <div class="global-container">
            <div
              class="umb-block-grid__area-container"
              style={{ "--umb-block-grid--area-grid-columns": "12" }}
            >
              <div
                class="umb-block-grid__area"
                data-area-col-span="12"
                data-area-row-span="1"
                data-area-alias="Inner"
                style={{
                  "--umb-block-grid--grid-columns": "12",
                  "--umb-block-grid--area-column-span": "12",
                  "--umb-block-grid--area-row-span": "1",
                }}
              >
                <div class="umb-block-grid__layout-container">
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="columnBlock"
                    data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                    data-element-udi="umb://element/501c6f73e76a4b76bf7eb7c32b44ac0b"
                    data-col-span="12"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": "12",
                      "--umb-block-grid--item-row-span": "1",
                    }}
                  >
                    <div
                      id="column-1d34753e-478f-418e-b993-00822dbcb79d"
                      class="block-column p-b-x-large"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": "1" }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": "12",
                            "--umb-block-grid--area-column-span": "12",
                            "--umb-block-grid--area-row-span": "1",
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="headerBlock"
                              data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                              data-element-udi="umb://element/2826a4cc68444cb787a2963fe0429073"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <h1
                                id="header-2826a4cc-6844-4cb7-87a2-963fe0429073"
                                class="block-header"
                                style={{ color: "#4d5250" }}
                              >
                                Progress & <br /> Reports
                              </h1>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="richTextBlock"
                              data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                              data-element-udi="umb://element/2309c2ba4494488b8ca1b4c2c55f893d"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-rich-text font-size-medium-large text-color-white">
                                <p style={{ color: "#4d5250" }}>
                                  Building a sustainable future
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
