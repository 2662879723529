import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import CompanyLeadership from "./pages/CompanyLeadership";
import BlaiseColeman from "./pages/profile/BlaiseColeman";
import PatrickBarry from "./pages/profile/PatrickBarry";
import TracyBasso from "./pages/profile/TracyBasso";
import MarkBradLey from "./pages/profile/MarkBradLey";
import MathewJMaletta from "./pages/profile/MathewJMaletta";
import MickMcguiness from "./pages/profile/MickMcguiness";
import JamesPapp from "./pages/profile/JamesPapp";
import LaurePark from "./pages/profile/LaurePark";
import MichaelRandolph from "./pages/profile/MichaelRandolph";
import CherylStouch from "./pages/profile/CherylStouch";
import JamesTursi from "./pages/profile/JamesTursi";
import SusanWilliamson from "./pages/profile/SusanWilliamson";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import CompanyHistory from "./pages/CompanyHistory";
import PoliciesAndStatement from "./pages/PoliciesAndStatement";
import CompanyContact from "./pages/CompanyContact";
import FocusArea from "./pages/FocusArea";
// import Products from "./pages/Products";
import Manufacturing from "./pages/Manufacturing";
import Rochester from "./pages/Rochester";
import Horsham from "./pages/Horsham";
import Suppliers from "./pages/Suppliers";
import RDApproach from "./pages/RDApproach";
import Pipeline from "./pages/Pipeline";
import ClinicalResearch from "./pages/ClinicalResearch";
import ResearchGrants from "./pages/ResearchGrants";
import Media from "./pages/Media";
import BlogPage from "./pages/BlogPage";
import EthicsAndCompliance from "./pages/EthicsAndCompliance";
import Team from "./pages/Team";
import Customers from "./pages/Customers";
import Environment from "./pages/Environment";
import Partnership from "./pages/Partnership";
import Progress from "./pages/Progress";
import Investor from "./pages/Investor";
import BoardCommitties from "./pages/BoardCommitties";
import IrArchive from "./pages/IrArchive";
import FinancialInformation from "./pages/FinancialInformation";
import Careers from "./pages/Career";
import "./endo.css";
import Story from "./pages/Story";
import Purpose from "./pages/Purpose";
import EmployeeBenefits from "./pages/EmployeeBenefits";
import Notfound from "./pages/Notfound";
import OpenPositions from "./pages/OpenPositions";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/company/leadership/" element={<CompanyLeadership />} />
          <Route
            path="/company/leadership/blaise-coleman/"
            element={<BlaiseColeman />}
          />
          <Route
            path="/company/leadership/patrick-barry/"
            element={<PatrickBarry />}
          />
          <Route
            path="/company/leadership/tracy-basso/"
            element={<TracyBasso />}
          />
          <Route
            path="/company/leadership/mark-bradley/"
            element={<MarkBradLey />}
          />
          <Route
            path="/company/leadership/matthew-j-maletta/"
            element={<MathewJMaletta />}
          />
          <Route
            path="/company/leadership/mick-mcguinness/"
            element={<MickMcguiness />}
          />
          <Route
            path="/company/leadership/james-papp/"
            element={<JamesPapp />}
          />
          <Route
            path="/company/leadership/laure-park/"
            element={<LaurePark />}
          />
          <Route
            path="/company/leadership/michael-randolph/"
            element={<MichaelRandolph />}
          />
          <Route
            path="/company/leadership/cheryl-stouch/"
            element={<CherylStouch />}
          />
          <Route
            path="/company/leadership/james-tursi/"
            element={<JamesTursi />}
          />
          <Route
            path="/company/leadership/susan-williamson/"
            element={<SusanWilliamson />}
          />
          <Route
            path="/company/leadership/board-of-directors/"
            element={<BoardOfDirectors />}
          />
          <Route path="/company/history/" element={<CompanyHistory />} />
          <Route
            path="/company/policies-and-statements/"
            element={<PoliciesAndStatement />}
          />
          <Route
            path="/company/contact-locations/"
            element={<CompanyContact />}
          />
          <Route
            path="/products-and-capabilities/focus-areas/"
            element={<FocusArea />}
          />
          {/* <Route
            path="/products-and-capabilities/products/"
            element={<Products />}
          /> */}
          <Route
            path="/products-and-capabilities/manufacturing/"
            element={<Manufacturing />}
          />
          <Route
            path="/products-and-capabilities/manufacturing/rochester-michigan/"
            element={<Rochester />}
          />
          <Route
            path="/products-and-capabilities/manufacturing/horsham-pennsylvania/"
            element={<Horsham />}
          />
          <Route
            path="/products-and-capabilities/suppliers/"
            element={<Suppliers />}
          />
          <Route
            path="/research-and-development/rd-approach/"
            element={<RDApproach />}
          />
          <Route
            path="/research-and-development/pipeline/"
            element={<Pipeline />}
          />
          <Route
            path="/research-and-development/clinical-research/"
            element={<ClinicalResearch />}
          />
          <Route
            path="/research-and-development/research-grants/"
            element={<ResearchGrants />}
          />
          <Route path="/newsroom/media/" element={<Media />} />
          <Route path="/investor/:blog/" element={<BlogPage />} />
          <Route
            path="/responsibility/ethics-and-compliance/"
            element={<EthicsAndCompliance />}
          />
          <Route path="/responsibility/team-and-culture/" element={<Team />} />
          <Route
            path="/responsibility/customers-and-patients/"
            element={<Customers />}
          />
          <Route
            path="/responsibility/environment/"
            element={<Environment />}
          />
          <Route
            path="/responsibility/partnerships/"
            element={<Partnership />}
          />
          <Route
            path="/responsibility/progress-and-reports/"
            element={<Progress />}
          />
          <Route path="/investor/" element={<Investor />} />
          <Route
            path="/investor/board-committees"
            element={<BoardCommitties />}
          />
          <Route path="/investor/ir-archive" element={<IrArchive />} />
          <Route
            path="/investor/financial-information"
            element={<FinancialInformation />}
          />
          <Route path="/careers" element={<Careers />} />
          <Route path="/newsroom/stories/:title" element={<Story />} />
          <Route path="/company/our-purpose/" element={<Purpose />} />
          <Route
            path="/careers/employee-benefits/"
            element={<EmployeeBenefits />}
          />
          <Route path="/careers/open-positions/" element={<OpenPositions />} />
          <Route path="*" element={<Notfound />} />
          <Route path="/notfound" element={<Notfound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
