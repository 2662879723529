import React from "react";

const ProductProcessing = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/b1d237269e8f4b5682124f4bdf9abf68"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-b1d23726-9e8f-4b56-8212-4f4bdf9abf68"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/16e049f5567745f48b497a5550446f75"
                  data-col-span="4"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 4,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-7efd867a-a909-428c-bb63-a6332825a46f"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/e8e1f8d602534e009f5e23b28c9bb158"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-e8e1f8d6-0253-4e00-9f5e-23b28c9bb158"
                              class="block-header font-size-large"
                            >
                              Product and Processing Capabilities
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/a66f142badd849ce8743019101bfd75f"
                  data-col-span="8"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 8,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-62207c3c-3393-49a7-8488-553245e62178"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/59a05fac32384a1a9d86b8d2b9a08ae6"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-59a05fac-3238-4a1a-9d86-b8d2b9a08ae6"
                              class="block-header font-size-medium"
                            >
                              Biopharmaceutical Bulk Drug Substance Production
                              Facility
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/78bab8565b5e4eae9381ba5a08d1f01f"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <ul>
                                <li>Biosafety Level 2</li>
                                <li>
                                  Protein and enzyme production
                                  <ul>
                                    <li>
                                      Secreted peptides/Low molecular weight
                                      proteins
                                    </li>
                                    <li>Non-engineered proteins</li>
                                    <li>Recombinant proteins</li>
                                    <li>Glycosylated proteins</li>
                                    <li>Enzymes</li>
                                    <li>Vaccines</li>
                                    <li>Cell banking</li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/729e8d18477744f6ac2424bbf5cf9521"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/f37ce992be70430786eff53cb5ec56a3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-f37ce992-be70-4307-86ef-f53cb5ec56a3"
                              class="block-header font-size-medium"
                            >
                              Fermenters/Bioreactors up to 500 L (Aerobic and
                              Anaerobic)
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/b2651785ed7249bbb4fa8cce0a842fac"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <ul>
                                <li>Bacterial fermentations</li>
                                <li>Fungal fermentations</li>
                                <li>Insect cell cultures</li>
                              </ul>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/034d35dd519d44f898452644f2ff32f6"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/980902b958904e458f299125dbc452e3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-980902b9-5890-4e45-8f29-9125dbc452e3"
                              class="block-header font-size-medium"
                            >
                              Purification
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/98a0f885626a4524a66885a2cd8de255"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <ul>
                                <li>
                                  Cytiva ÄKTAprocess and ÄKTA Pilot
                                  chromatography systems
                                </li>
                                <li>
                                  Cytiva AxiChrom and BPG chromatography columns
                                </li>
                                <li>
                                  Millipore tangential flow filtration systems
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/f855fe8834a140e0ada34c37dfa99152"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/f7b119e80d994885a88e65dd23a45429"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-f7b119e8-0d99-4885-a88e-65dd23a45429"
                              class="block-header font-size-medium"
                            >
                              Holding and mixing vessels up to 1,000 L
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/0cfaa262ed154601a302ec702f4b38b3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/559778368f01477595d5961c5531bd88"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-55977836-8f01-4775-95d5-961c5531bd88"
                              class="block-header font-size-medium"
                            >
                              Cold Storage
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="separatorBlock"
                  data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                  data-element-udi="umb://element/53411b6bff2d45dcbed98794eb55815e"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div class="block-separator type-full-width m-b-large m-t-large"></div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/ad6cc2c068b343eeb2dc990131efd2f8"
                  data-col-span="4"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 4,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-2d5e651c-1a19-4385-b78b-07dd0f4f990e"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/96076ab7ec284cba87e65de5a4669f19"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-96076ab7-ec28-4cba-87e6-5de5a4669f19"
                              class="block-header font-size-large"
                            >
                              Quality Control and Analytical Testing
                              Capabilities
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/364e5220639a4c2bbd932f7347acdaeb"
                  data-col-span="8"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 8,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-9bf1e917-bd26-418d-bde5-d6ce128d772c"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/30d2449ef3cc4fedb39ca966435a6522"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-30d2449e-f3cc-4fed-b39c-a966435a6522"
                              class="block-header font-size-medium"
                            >
                              Release and stability testing: drug substance,
                              drug product, sterile diluent
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/cb83d20038a9423e91b0832c1929f672"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/4663baef50fa4f71a315cf86aafb1a85"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-4663baef-50fa-4f71-a315-cf86aafb1a85"
                              class="block-header font-size-medium"
                            >
                              Biologic and chemical assays: drug product, API,
                              raw materials
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/698df79356d647aebf1368a0e9838ccc"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <ul>
                                <li>
                                  High-performance liquid chromatography (HPLC),
                                  including size exclusion (SEC)
                                </li>
                                <li>Reverse phase purity/impurity methods</li>
                                <li>Peptide mapping</li>
                                <li>UV/VIS spectroscopy</li>
                                <li>Karl Fischer moisture content</li>
                                <li>SDS-PAGE and densitometry</li>
                                <li>Imaged capillary electrophoresis (iCE)</li>
                                <li>Capillary gel electrophoresis (CGE)</li>
                                <li>
                                  Microplate- and cuvette-based enzyme activity
                                  assays
                                </li>
                                <li>ELISA</li>
                                <li>NIR and FTIR</li>
                                <li>
                                  Inductively coupled plasma mass spectrometry
                                  (ICP-MS)
                                </li>
                                <li>
                                  Wide range of wet chemistry analysis including
                                  pH, conductivity, titrations, osmometry
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/8d0cef57afb0411fa75e7bd479379ed7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/72a16ce7526f4139ac712217187af2ee"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-72a16ce7-526f-4139-ac71-2217187af2ee"
                              class="block-header font-size-medium"
                            >
                              Microbiology
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/25d0218b8219410cb66676f5749d7c3a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <ul>
                                <li>
                                  Total organic carbon (TOC) analysis using
                                  Sievers M9, endotoxin
                                </li>
                                <li>
                                  Kinetic chromogenic (water and in-process)
                                </li>
                                <li>
                                  Bioburden via membrane filtration (water and
                                  in-process)
                                </li>
                                <li>Conductivity</li>
                                <li>Microbial identification</li>
                                <li>Cell line characterization</li>
                                <li>
                                  Microbial testing of upstream processes
                                  (culture purity, hemolytic testing)
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductProcessing;
