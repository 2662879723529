import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TracyBasso = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Tracy Basso | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Tracy Basso" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/tracy-basso/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Tracy Basso</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/g0jhsq25/tracy-basso.webp"
                  alt="Tracy Basso"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Tracy Basso</h1>

              <p class="team-position">Chief Human Resources Officer</p>

              <div class="team-bio">
                <p>
                  Tracy Basso has served as Chief Human Resources Officer of
                  Endo International plc since July 2019, and she continues to
                  serve in that role for Endo. In this role, she leads all
                  aspects of human resources, enabling the development and
                  growth of Endo. Most recently, Ms. Basso, who joined the
                  company in November 2004, was Senior Vice President, Human
                  Resources for Endo International plc, leading human resources
                  support for the company’s sterile injectable and generic
                  businesses as well as operations, quality, and enterprise-wide
                  talent acquisition.
                </p>
                <p>
                  Prior to joining the company, Ms. Basso, who has over 20 years
                  of experience in human resources, worked for GlaxoSmithKline
                  and started her career at Best Foods Baking Company. She holds
                  a Master’s in Human Resources from Fordham University and a
                  Bachelor of Science in Business Management from Iona College.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default TracyBasso;
