import React, { useEffect, useState } from "react";

const Benefits = () => {
  // State to keep track of the expanded accordion panel
  const [expandedId, setExpandedId] = useState("");

  const handleAccordionClick = (id) => {
    setExpandedId(expandedId === id ? "" : id);
  };

  // Set the height of the panel based on the state change
  useEffect(() => {
    const panels = document.querySelectorAll(".accordion-panel");
    panels.forEach((panel) => {
      const inner = panel.querySelector(".accordion-panel-inner");
      if (panel.parentElement.dataset.key === expandedId) {
        panel.style.height = `${inner.offsetHeight}px`;
      } else {
        panel.style.height = "0px";
      }
    });
  }, [expandedId]);

  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/ffc82ed32d4e4ac9a08c1706f9f86c0b"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-ffc82ed3-2d4e-4ac9-a08c-1706f9f86c0b"
        class="block-section white-60-percent mobile-overlay-only background-color-light-gray"
      >
        <div class="overlay"></div>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/f74b2371a94c4b0d945145e9b26b398e"
                  data-col-span="5"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "5",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-762d53c8-07d9-41b3-baa0-371f5af30f73"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/7b5ca268ec9a481da2ab1c359c9c834a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-7b5ca268-ec9a-481d-a2ab-1c359c9c834a"
                              class="block-header font-size-x-large"
                            >
                              Benefits That Support Team Member Wellness
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/934a4ef20f7d4da082a2a4036419f45c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-medium">
                              <p>
                                We are committed to supporting the total
                                well-being of our team members. Our benefits and
                                resources help our team members take steps
                                toward greater physical, personal, and financial
                                health. Check out a topline summary of our
                                offerings.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d" style={{ width: "70vh" }}>
                  {" "}
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="accordionBlock"
                    data-content-element-type-key="0c32fb25-ca42-4ca6-985a-e8a7ee7031b4"
                    data-element-udi="umb://element/e118b757e58c4864b20eb781549f5f11"
                    data-col-span="12"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": "12",
                      "--umb-block-grid--item-row-span": "1",
                    }}
                  >
                    <div
                      className="block-accordion"
                      data-expand="False"
                      data-remove-indent="False"
                      aria-roledescription="accordion"
                    >
                      {accordionData.map((data) => (
                        <div
                          key={data.id}
                          data-key={data.id}
                          className={`accordion-row ${
                            data.id === expandedId ? "active" : ""
                          }`}
                        >
                          <h2
                            aria-expanded={
                              data.id === expandedId ? "" : "false"
                            }
                            tabIndex="0"
                            className="accordion-header"
                            onClick={() => handleAccordionClick(data.id)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                handleAccordionClick(data.id);
                              }
                            }}
                          >
                            {data.title}
                          </h2>
                          <div className="accordion-panel">
                            <div className="accordion-panel-inner">
                              <div
                                className="umb-block-grid"
                                data-grid-columns="12"
                                style={{
                                  "--umb-block-grid--grid-columns": "12",
                                }}
                              >
                                <div className="umb-block-grid__layout-container">
                                  {data.contents.map((content, index) => (
                                    <div
                                      key={index}
                                      className="umb-block-grid__layout-item"
                                      data-content-element-type-alias={
                                        content.typeAlias
                                      }
                                      data-content-element-type-key={
                                        content.typeKey
                                      }
                                      data-element-udi={content.elementUdi}
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div className="block-btn type-text">
                                        {content.p ? (
                                          <p
                                            className={`${
                                              content.list && "disc-text"
                                            } ${
                                              content.bold && "bold-text"
                                            } accordian-text-updated`}
                                          >
                                            {content.strong && (
                                              <>
                                                <strong>
                                                  {content.strong}
                                                </strong>
                                                <br />
                                                <br />
                                              </>
                                            )}
                                            {content.p}
                                          </p>
                                        ) : (
                                          <a
                                            className={`${
                                              content.sublink && "sublink"
                                            } common-btn`}
                                            href={content.href}
                                            target={`${
                                              !content.sublink && !content.self
                                                ? "_blank"
                                                : "_self"
                                            }`}
                                            rel="noopener noreferrer"
                                          >
                                            {content.text}
                                            {!content.sublink && (
                                              <div className="arrow-wrap">
                                                <svg
                                                  width="40"
                                                  height="12"
                                                  viewBox="0 0 40 12"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                    fill="#E87722"
                                                  />
                                                  <path
                                                    d="M33 11L38 6L33 1"
                                                    stroke="#E87722"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                  />
                                                </svg>
                                              </div>
                                            )}
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const accordionData = [
  {
    id: "physical",
    title: "Physical",
    contents: [
      {
        p: "Medical, dental, and vision insurance options providing flexible plans for individuals and families, along with access to virtual medicine",
        list: true,
      },
      {
        p: "Disease management programs to support team members with chronic medical conditions",
        list: true,
      },
      {
        p: "Health advocate program to assist team members navigating healthcare and insurance issues",
        list: true,
      },
    ],
  },
  {
    id: "personal",
    title: "Personal",
    contents: [
      {
        p: "Employee assistance program that connects team members with counselors and offers up to five free counseling sessions, as well as referral services to support childcare, elder care, and educational needs",
        list: true,
      },
      {
        p: "Paid time off ",
        list: true,
      },
    ],
  },
  {
    id: "financial",
    title: "Financial",
    contents: [
      {
        p: "Pay-for-performance compensation philosophy, which supports our business strategy and advancement of stakeholder interests, while delivering compensation that is commensurate with performance",
        list: true,
      },
      {
        p: "401(k) plan that matches up to 4% of eligible earnings",
        list: true,
      },
      {
        p: "Savings and spending accounts that help team members save, tax-free, for healthcare and dependent care",
        list: true,
      },
      {
        p: "Education assistance that provides up to $7,000/year toward continuing education at accredited schools, if approved",
        list: true,
      },
    ],
  },

  // Add more accordion data here
];

export default Benefits;
