import React from "react";

const SupplierPhilosophy = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/be8006bd894c468db5b50de55a697c70"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-be8006bd-894c-468d-b5b5-0de55a697c70"
        class="block-section align-v-top align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/8431831302b44f0185e5de79f762a800"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-69785c4e-89f6-4cc3-9a54-18337daca6d7"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/1377389d5eaa4042bef5dcf62324606c"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-4807ea9d-026c-4b85-a096-aa94cb37992d"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/92662427aebd443ea940fe14f37817fb"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-92662427-aebd-443e-a940-fe14f37817fb"
                                        class="block-header font-size-x-large"
                                      >
                                        Supplier Philosophy&#x202F;&#xA0;
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/256e87dfc6524ebd8ff6ddd809ccdccd"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We are working every day to develop
                                          and deliver life-enhancing products to
                                          the patients and people who need them
                                          most. We realize we cannot do this
                                          alone.
                                        </p>
                                        <p>
                                          A complex network of suppliers from
                                          all around the globe bolsters our
                                          efforts, whether it be through
                                          professional services, raw materials,
                                          transportation, or technology
                                          enablement, to name just a few goods
                                          and services Endo procures.
                                        </p>
                                        <p>
                                          <strong>
                                            One of our key business priorities
                                            is to be a force for good.
                                          </strong>
                                        </p>
                                        <p>
                                          When it comes to our supply chain,
                                          this means engaging with suppliers who
                                          share in our commitment to operating
                                          with integrity and delivering top
                                          quality products and services.
                                        </p>
                                        <p>
                                          We also accelerate new ways of working
                                          that can improve our productivity and
                                          help us deliver innovative solutions
                                          for our customers. To execute this
                                          effectively, it is important that we
                                          have a diverse community of suppliers
                                          with whom we can collaborate to help
                                          us achieve our business objectives.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/edd5a1de4d884708b316a08c0ad640f6"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-02c13a66-ef22-4bc0-85c6-1ab0415d2786"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/caf4d8dc79914858825e19cde0c6851e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <figure class="block-image has-rc">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/pyxgaykk/supplier-philosophy.webp"
                                          alt="A worker cross checks a box label with the information on a tablet."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SupplierPhilosophy;
