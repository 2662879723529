import React from "react";

const AlsoConduct = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="columnBlock"
      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
      data-element-udi="umb://element/b835c9f48862458ab5e47bc8ed3e6562"
      data-col-span="6"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 6,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <div
        id="column-c5ad5cf3-b7a3-40b3-9910-2c2cc12aa6c7"
        class="block-column"
      >
        <div
          class="umb-block-grid__area-container"
          style={{ "--umb-block-grid--area-grid-columns": "1" }}
        >
          <div
            class="umb-block-grid__area"
            data-area-col-span="12"
            data-area-row-span="1"
            data-area-alias="inner"
            style={{
              "--umb-block-grid--grid-columns": 12,
              "--umb-block-grid--area-column-span": 12,
              "--umb-block-grid--area-row-span": 1,
            }}
          >
            <div class="umb-block-grid__layout-container">
              <div
                class="umb-block-grid__layout-item"
                data-content-element-type-alias="richTextBlock"
                data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                data-element-udi="umb://element/e81a65c845654e3f88095dc1135ac4e0"
                data-col-span="12"
                data-row-span="1"
                style={{
                  "--umb-block-grid--item-column-span": 12,
                  "--umb-block-grid--item-row-span": 1,
                }}
              >
                <div class="block-rich-text">
                  <p>
                    We also conduct postmarketing clinical trials and health
                    economic studies to gather additional data on certain
                    products after they have reached the market. We share this
                    study information with regulatory authorities, researchers,
                    and prescribers to improve the understanding of how our
                    products are being used in real-world settings and to help
                    healthcare professionals use our products safely and
                    effectively.
                  </p>
                  <p>
                    <strong>
                      If you need to report an adverse event, please call:
                    </strong>
                  </p>
                  <p>
                    <strong>Branded products:</strong>
                    +1-800-462-3636
                  </p>
                  <p>
                    <strong>Generic or Sterile products:</strong>
                    +1-800-828-9393
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlsoConduct;
