import React from "react";

const Ivr = () => {
  return (
    <div class="wd_content-wrapper-left body-content-container">
      <div class="wd_content">
        <div id="wd_printable_content">
          <h1 class="page_header">Investor Resources Archive</h1>
          <p>
            The following information pertains to Endo, Inc.&rsquo;s predecessor
            company, Endo International plc.
          </p>

          <div class="wd_documents">
            <div class="wd_item_search_form_container">
              <form
                name="item_basic_search_151"
                method="get"
                action="https://investor.endo.com/ir-archive"
                onsubmit="wd_item_search_form_submit(this, true);"
                class="wd_search_form wd_search_form_basic"
              >
                <div class="wd_search_basic_fields">
                  <label
                    for="item_basic_search_151_keywords"
                    class="wd_sr-only"
                  >
                    Keywords
                  </label>
                  <input
                    type="text"
                    name="keywords"
                    value=""
                    size="32"
                    placeholder="Search by Keyword"
                    wd_original_value=""
                    id="item_basic_search_151_keywords"
                  />
                  <button
                    type="submit"
                    class="wd_search_button"
                    title="Search by Keyword"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>

            <ul class="wd_layout-simple wd_documents_item_list">
              <li class="wd_documents_item">
                <a
                  href="/download/TEST+File+-+PDF.pdf"
                  target=""
                  class="wd_icon wd_icon-pdf"
                  title="Download TEST File - PDF.pdf  (370 KB)"
                >
                  <span
                    style={{ "font-size": "2em" }}
                    class="fa fa-file-pdf-o"
                  ></span>
                </a>
                <div class="wd_title">
                  <a
                    href="/download/TEST+File+-+PDF.pdf"
                    target=""
                    title="Download TEST File - PDF.pdf  (370 KB)"
                  >
                    2023 Q4 Presentation
                  </a>
                </div>
              </li>
              <li class="wd_documents_item">
                <a
                  href="https://endocorporate-development.azurewebsites.net/responsibility/progress-and-reports/"
                  target=""
                  class="wd_icon wd_icon-other"
                  title="Download progress-and-reports "
                >
                  <span
                    style={{ "font-size": "2em" }}
                    class="fa fa-file-o"
                  ></span>
                </a>
                <div class="wd_title">
                  <a
                    href="https://endocorporate-development.azurewebsites.net/responsibility/progress-and-reports/"
                    target=""
                    title="Download progress-and-reports "
                  >
                    Corporate Responsibility Report
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="wd_featureboxes_bottom wd_featureboxes-vertical">
          <div
            id="wd_featurebox-standard_4"
            class="wd_featurebox_container wd_featurebox-standard"
          >
            <div class="wd_featurebox">
              <p class="more">
                <a
                  href="https://www.sec.gov/edgar/browse/?CIK=1593034&amp;owner=exclude"
                  target="_blank"
                >
                  View Historical Predecessor SEC Filings
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ivr;
