import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import "../../css/team.css";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlaiseColeman = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Blaise Coleman | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Blaise Coleman" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/blaise-coleman/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-detailed">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Blaise Coleman</li>
          </ul>

          <div id="team-content">
            <h1>Our Leadership</h1>
            <div id="team-image">
              <figure>
                <img
                  src="https://www.endo.com/media/tu1pz3qw/blaise-coleman.webp"
                  alt="Blaise Coleman"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <p class="team-leadin">A message from our CEO</p>

              <blockquote>
                &quot;We have and will continue to challenge ourselves to
                operate in even more sustainable ways, knowing that by doing so,
                we will be one step closer to fulfilling our vision of helping
                everyone live their best life.&#x201D;
              </blockquote>

              <cite class="team-name">
                Blaise Coleman
                <span class="team-position">
                  President and Chief Executive Officer
                </span>
              </cite>

              <div class="block-separator type-default align-h-left"></div>
            </div>
            <div id="sub-content">
              <div class="col col-1">
                <h2>Overview</h2>
                <div class="team-overview">
                  <p
                    style={{
                      msoMarginTopAlt: "auto",
                      msoMarginBottomAlt: "auto",
                      lineHeight: "normal",
                      background: "white",
                    }}
                    class="MsoNormal"
                  >
                    Blaise Coleman has served as President and Chief Executive
                    Officer of Endo International plc and a member of its Board
                    of Directors since March 2020, and he continues to serve in
                    those roles for Endo. He previously served as Executive Vice
                    President and Chief Financial Officer of Endo International
                    plc from December 2016 to March 2020. He joined Endo
                    International in January 2015 as Vice President of Corporate
                    Financial Planning &amp; Analysis, and was then promoted to
                    Senior Vice President, Global Finance Operations in November
                    2015.
                  </p>
                </div>
              </div>
              <div class="col col-2">
                <h2>History</h2>
                <div class="team-bio">
                  <p>
                    Prior to joining Endo, Mr. Coleman held a number of finance
                    leadership roles with AstraZeneca, most recently as the
                    Chief Financial Officer of the AstraZeneca/Bristol-Myers
                    Squibb US Diabetes Alliance.
                    <br />
                    <br />
                    Prior to that, he was the Head of Finance for the
                    AstraZeneca Global Medicines Development organization based
                    in Mölndal, Sweden. Mr. Coleman joined AstraZeneca in 2007
                    as Senior Director, Commercial Finance for the US
                    Cardiovascular Business. <br />
                    <br />
                    He joined AstraZeneca from Centocor, a wholly-owned
                    subsidiary of Johnson &amp; Johnson, where he held positions
                    in both the Licenses &amp; Acquisitions and Commercial
                    Finance organizations. Mr. Coleman’s move to Centocor in
                    early 2003 followed seven years’ experience with the global
                    public accounting firm, PricewaterhouseCoopers LLP.
                  </p>
                </div>
              </div>
              <div class="col col-3">
                <h2>Qualifications</h2>
                <div class="team-qualifications">
                  <p>
                    Mr. Coleman is a Certified Public Accountant; he holds a
                    Bachelor of Science degree in accounting from Widener
                    University and an M.B.A. from the Fuqua School of Business
                    at Duke University.
                    <br />
                    <br />
                    Mr. Coleman’s qualifications to serve on the Board of Endo
                    include, among others, his executive leadership experience
                    at pharmaceutical companies, extensive background in
                    finance, business and strategic planning and in-depth
                    knowledge of the Company, its businesses and management.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default BlaiseColeman;
