import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SusanWilliamson = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Susan Williamson | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Susan Williamson" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/susan-williamson/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Susan Williamson</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/thvijgu1/susan-williamson.webp"
                  alt="Susan Williamson"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Susan Williamson</h1>

              <p class="team-position">
                Senior Vice President and Chief Compliance Officer
              </p>

              <div class="team-bio">
                <p>
                  Susan Williamson has served as Senior Vice President and Chief
                  Compliance Officer for Endo International plc since April 2018
                  and continues in that role today for Endo. In this role, she
                  is responsible for the strategic direction and operations of
                  the company’s Global Corporate Compliance program. Ms.
                  Williamson joined Endo International plc in 2012 and has been
                  a valued member of Endo's Corporate Compliance leadership
                  team, previously serving as Endo International plc’s Vice
                  President and U.S. and Canadian Compliance Officer where she
                  was responsible for oversight of the company’s Corporate
                  Compliance program, including management of its Corporate
                  Integrity Agreement (CIA), enterprise-wide corporate
                  compliance training and monitoring, and Aggregate Spend
                  reporting.
                </p>
                <p>
                  Prior to joining Endo International plc, Ms. Williamson worked
                  at Pfizer in Operational Risk Management supporting Pfizer's
                  CIA from 2009 to 2012. Prior to her employment with Pfizer,
                  Ms. Williamson spent over five years at Wyeth Pharmaceuticals
                  and held key roles in building, developing, and implementing a
                  Global Corporate Compliance program.
                </p>
                <p>
                  Ms. Williamson began her career in public accounting at Ernst
                  &amp; Young in the Assurance and Advisory Practice. Ms.
                  Williamson is a Certified Public Accountant and holds a
                  Bachelor of Science degree in Accounting from Saint Joseph's
                  University as well as a Master of Business Administration from
                  the Erivan K. Haub School of Business at St. Joseph's
                  University.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default SusanWilliamson;
