import React from "react";

const Header = () => {
  return (
    <header id="masthead" class="theme-dark">
      <div class="global-container">
        <p id="masthead-logo">
          <a href="/">
            <img
              src="https://www.endo.com/images/Logo.svg"
              alt="Endo Logo"
              width="116"
              height="34"
            />
          </a>
        </p>

        <button
          tabindex="0"
          id="mobile-toggle"
          aria-label="Toggle mobile navigation"
        >
          <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="hamburger">
              <rect
                id="Rectangle 128"
                y="0.238525"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
              <rect
                id="Rectangle 129"
                y="7.28284"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
              <rect
                id="Rectangle 130"
                y="14.3268"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
            </g>
          </svg>
        </button>

        <nav id="masthead-navigation-primary" aria-label="Primary Navigation">
          <button
            aria-label="Close mobile navigation"
            id="mobile-navigation-close"
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#656969"
              />
            </svg>
          </button>
          <div id="primay-nav-container">
            <ul id="primary-nav">
              <li tabindex="0" class="parent-not-active" aria-expanded="false">
                <span class="nolink">Company</span>
                <ul class="dropdown">
                  <li>
                    <a href="/company/our-purpose/">Purpose</a>
                  </li>
                  <li>
                    <a href="/company/leadership/">Leadership</a>
                  </li>
                  <li>
                    <a href="/company/history/">History</a>
                  </li>
                  <li>
                    <a href="/company/policies-and-statements/">
                      Policies &amp; Statements
                    </a>
                  </li>
                  <li>
                    <a href="/company/contact-locations/">
                      Contact &amp; Locations
                    </a>
                  </li>
                </ul>
              </li>
              <li tabindex="0" class="parent-not-active" aria-expanded="false">
                <span class="nolink">Products &amp; Capabilities</span>
                <ul class="dropdown">
                  <li>
                    <a href="/products-and-capabilities/focus-areas/">
                      Focus Areas
                    </a>
                  </li>
                  <li>
                    <a href="/products-and-capabilities/products/">Products</a>
                  </li>
                  <li>
                    <a href="/products-and-capabilities/manufacturing/">
                      Manufacturing
                    </a>
                  </li>
                  <li>
                    <a href="/products-and-capabilities/suppliers/">
                      Suppliers
                    </a>
                  </li>
                </ul>
              </li>
              <li tabindex="0" class="parent-not-active" aria-expanded="false">
                <span class="nolink">Research &amp; Development</span>
                <ul class="dropdown">
                  <li>
                    <a href="/research-and-development/rd-approach/">
                      R&amp;D Approach
                    </a>
                  </li>
                  <li>
                    <a href="/research-and-development/pipeline/">Pipeline</a>
                  </li>
                  <li>
                    <a href="/research-and-development/clinical-research/">
                      Clinical Research
                    </a>
                  </li>
                  <li>
                    <a href="/research-and-development/research-grants/">
                      Grants
                    </a>
                  </li>
                </ul>
              </li>
              <li tabindex="0" class="parent-not-active" aria-expanded="false">
                <span class="nolink">Newsroom</span>
                <ul class="dropdown">
                  <li>
                    <a href="/newsroom/media/">Media</a>
                  </li>
                  <li>
                    <a href="/newsroom/stories/">Stories</a>
                  </li>
                </ul>
              </li>
              <li tabindex="0" class="parent-not-active" aria-expanded="false">
                <span class="nolink">Responsibility</span>
                <ul class="dropdown">
                  <li>
                    <a href="/responsibility/ethics-and-compliance/">
                      Ethics &amp; Compliance
                    </a>
                  </li>
                  <li>
                    <a href="/responsibility/team-and-culture/">
                      Team &amp; Culture
                    </a>
                  </li>
                  <li>
                    <a href="/responsibility/customers-and-patients/">
                      Customers &amp; Patients
                    </a>
                  </li>
                  <li>
                    <a href="/responsibility/environment/">Environment</a>
                  </li>
                  <li>
                    <a href="/responsibility/partnerships/">Partnerships</a>
                  </li>
                  <li>
                    <a href="/responsibility/progress-and-reports/">
                      Progress &amp; Reports
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <span class="sep"></span>
            <ul id="utility-nav">
              <li>
                <a href="https://investor.endo.com/" target="_blank">
                  Investors
                </a>
              </li>
              <li>
                <a href="/careers/" target="">
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
