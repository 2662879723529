import React from "react";

const SmallBuisness = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/ff1777e450404ed6b7b1814dd3d19a6b"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-ff1777e4-5040-4ed6-b7b1-814dd3d19a6b"
        class="block-section align-h-center p-b-x-large p-t-x-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/81b0707a267b41398e9fd84f0395bb80"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-3a7590a0-dd10-412f-8d1d-b59947b5a777"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/f91594010bba48e99b4c737c320f40c8"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-f375e39d-2d87-4a9c-ae0c-774f8e66800e"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/6a46ad41174e48829d2092eb833461db"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-6a46ad41-174e-4882-9d20-92eb833461db"
                                        class="block-header font-size-large"
                                      >
                                        Supplier Diversity
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/eb8473ef102d4162ac474cf40dbfa42d"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          In line with our broader Diversity,
                                          Equity, and Inclusion (DEI) strategy,{" "}
                                          <br />
                                          our supplier diversity program
                                          promotes the use of businesses that
                                          are owned by minorities, women,
                                          veterans, LGBTQ members, people with
                                          disabilities, and other
                                          underrepresented groups.
                                        </p>
                                        <p>
                                          By partnering with a diverse group of
                                          suppliers, we believe we will make
                                          better-informed decisions that will
                                          fuel our future success and expand
                                          opportunities for marginalized and
                                          historically underrepresented business
                                          owners.
                                        </p>
                                        <p>
                                          In 2022, Endo invested in a full-time
                                          internal leader to manage our supplier
                                          diversity efforts and added a supplier
                                          diversity metric to the corporate
                                          scorecard. Since then, the company has
                                          increased the number of veteran-owned
                                          businesses and Lesbian Gay Bisexual
                                          Transgender Business Enterprises it
                                          engages by more than 30% and 200%,
                                          respectively.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/e5467ffa19fd449187d64c59d1e55e5e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-btn type-text">
                                        <a
                                          class="common-btn"
                                          href="https://endo.corcentricplatform.com/"
                                          target="_blank"
                                        >
                                          Join Endo&#x27;s Vision for a Diverse
                                          Supply Chain
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/b97fffe00f1142928c1e3849d45a7550"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-4d442241-b580-4fde-b1d8-d94185727dcc"
                              class="block-column p-l-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/f6311bf4a7b34e84a013461caf8a5ac5"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          <strong>
                                            Small Business Subcontracting
                                            Program
                                          </strong>
                                        </p>
                                        <p>
                                          Endo actively works to engage small
                                          businesses, as defined by the U.S.
                                          Small Business Administration’s (SBA)
                                          Size Standards. Endo welcomes all
                                          small and diverse suppliers with a
                                          particular focus on these categories:
                                        </p>
                                        <ul>
                                          <li>Small Business</li>
                                          <li>Veteran-Owned Small Business</li>
                                          <li>
                                            Service-Disabled, Veteran-Owned
                                            Small Business
                                          </li>
                                          <li>Small Disadvantaged Business</li>
                                          <li>
                                            Historically Underutilized Business
                                            (HUB) Zone Small Business
                                          </li>
                                          <li>Women-Owned Small Business</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SmallBuisness;
