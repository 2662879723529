import React from "react";

const ProductsCapabilities = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/8d2488cdd5f849c18b2d7040ccedacb5"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-8d2488cd-d5f8-49c1-8b2d-7040ccedacb5"
        class="block-section background-color-light-gray p-b-x-large p-t-x-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": 1 }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/7571c0398923450db1a79be0d12e368f"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-ba5fc94c-9e8b-49ed-9ab1-49c8d8a02913"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/b397e455298344ba92e4f633b1510fed"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-b397e455-2983-44ba-92e4-f633b1510fed"
                              class="block-header font-size-large m-b-small"
                            >
                              Products &amp; Capabilities
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/eb579dd142ad476796307ab91ab05b68"
                  data-col-span="4"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "4",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-920653bf-8867-4d1b-bc4d-891894c7abee"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="cTABlock"
                            data-content-element-type-key="b2564b0d-7551-4641-a182-41ece6174139"
                            data-element-udi="umb://element/ef9d9afe36d34f409cb6bd49ca7cd230"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-cta has-rc">
                              <figure class="cta-image-wrap">
                                <img
                                  class="cta-image"
                                  loading="lazy"
                                  src="https://www.endo.com/media/tf0f0psq/specialty-medicines.webp"
                                  alt="A patient in the middle of a discussion with their doctor"
                                  width="0"
                                  height="0"
                                />
                                <figcaption></figcaption>

                                <a
                                  class="cta-overlay"
                                  href="/products-and-capabilities/focus-areas/"
                                >
                                  <span>Learn More</span>
                                </a>
                              </figure>

                              <h3 class="cta-title">
                                <a href="/products-and-capabilities/focus-areas/">
                                  Specialty Medicines
                                  <div class="arrow-wrap">
                                    <svg
                                      width="40"
                                      height="12"
                                      viewBox="0 0 40 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                        fill="#E87722"
                                      />
                                      <path
                                        d="M33 11L38 6L33 1"
                                        stroke="#E87722"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </div>
                                </a>
                              </h3>

                              <div class="cta-copy">
                                <p>
                                  We empower patients and build trust with
                                  urologists, orthopedic surgeons, pediatric
                                  endocrinologists, and other specialists. This
                                  relentless focus delivers meaningful therapies
                                  and seamless experiences that help enable
                                  successful outcomes in both U.S. and Canada.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/32f44641ee4641c6b14359ab20f5ae2b"
                  data-col-span="4"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 4,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-4d82cac6-92de-443f-8b3c-d1d2e5d1220b"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="cTABlock"
                            data-content-element-type-key="b2564b0d-7551-4641-a182-41ece6174139"
                            data-element-udi="umb://element/2f30d1b019794d4ca37487a89552c73f"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-cta has-rc">
                              <figure class="cta-image-wrap">
                                <img
                                  class="cta-image"
                                  loading="lazy"
                                  src="https://www.endo.com/media/haldmud5/sterile-injectables.webp"
                                  alt="A drip catheter"
                                  width="0"
                                  height="0"
                                />
                                <figcaption></figcaption>

                                <a
                                  class="cta-overlay"
                                  href="/products-and-capabilities/focus-areas/#sterile-injectables"
                                >
                                  <span>Learn More</span>
                                </a>
                              </figure>

                              <h3 class="cta-title">
                                <a href="/products-and-capabilities/focus-areas/#sterile-injectables">
                                  Sterile Injectables
                                  <div class="arrow-wrap">
                                    <svg
                                      width="40"
                                      height="12"
                                      viewBox="0 0 40 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                        fill="#E87722"
                                      />
                                      <path
                                        d="M33 11L38 6L33 1"
                                        stroke="#E87722"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </div>
                                </a>
                              </h3>

                              <div class="cta-copy">
                                <p>
                                  We partner with hospitals and healthcare
                                  systems to reduce complexity through
                                  life-enhancing solutions, including sterile
                                  injectable and ready-to-use products.
                                  Together, we innovate to add value where it
                                  matters—from manufacturing to treatment—so
                                  healthcare providers can focus on patient
                                  care.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/83fe678fbcc34be7aa1004b503aa5c1e"
                  data-col-span="4"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 4,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-961a9b56-d723-4d66-9039-41b5da129cf7"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="cTABlock"
                            data-content-element-type-key="b2564b0d-7551-4641-a182-41ece6174139"
                            data-element-udi="umb://element/6cff845662974d549f015cb24f3d7940"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-cta has-rc">
                              <figure class="cta-image-wrap">
                                <img
                                  class="cta-image"
                                  loading="lazy"
                                  src="https://www.endo.com/media/rn3ixz2x/products-generics.webp"
                                  alt="A closeup view of a person holding a prescription canister"
                                  width="0"
                                  height="0"
                                />
                                <figcaption></figcaption>

                                <a
                                  class="cta-overlay"
                                  href="/products-and-capabilities/focus-areas/#generics"
                                >
                                  <span>Learn More</span>
                                </a>
                              </figure>

                              <h3 class="cta-title">
                                <a href="/products-and-capabilities/focus-areas/#generics">
                                  Generics
                                  <div class="arrow-wrap">
                                    <svg
                                      width="40"
                                      height="12"
                                      viewBox="0 0 40 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                        fill="#E87722"
                                      />
                                      <path
                                        d="M33 11L38 6L33 1"
                                        stroke="#E87722"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                      />
                                    </svg>
                                  </div>
                                </a>
                              </h3>

                              <div class="cta-copy">
                                <p>
                                  We are dedicated to delivering high-quality
                                  generic products in a variety of forms and
                                  dosages through retail, mail-order, and other
                                  patient-facing pharmacy networks. We achieve
                                  this through excellence in research and
                                  development, manufacturing, and
                                  commercialization.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductsCapabilities;
