import React from "react";

const CultureQuality = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/fe17821209f2469e848e502e9ec6c1ef"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-fe178212-09f2-469e-848e-502e9ec6c1ef"
        class="block-section align-v-top align-h-center p-b-x-large p-t-xx-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/563a34618d1045218739cb096461667b"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-f1fb675e-aab1-4ecb-aa32-b7ab1ec4b0a6"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/5604c9ff0baa4d5985c049f19c91c2a6"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-d7f9bb51-2f25-483f-9b80-aa1712489694"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/aa0a85b926334b9494a85ffeb3cb39be"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <figure class="block-image has-rc">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/bgvnnsal/culture-of-compliance.webp"
                                          alt="A technician holds a small bottle in front of his face."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9ab5e3cdda064629af247e5786ad816a"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-4d977f2f-dd82-40f2-9b73-b8790f239490"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/38a489538e7846b184e4b35e201ca837"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-38a48953-8e78-46b1-84e4-b35e201ca837"
                                        class="block-header font-size-x-large"
                                      >
                                        Culture of Quality and Compliance&#xA0;
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/b7b4e25248854e33b283d80e0f79483b"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We strive for excellence in everything
                                          we do. We know that our products are
                                          more than just the vials, bottles,
                                          bags, pre-filled syringes, or tablets
                                          that make up our vast portfolio and
                                          supply network. They are the medicines
                                          that help individuals in our own
                                          communities and families.
                                          <span> </span>
                                        </p>
                                        <p>
                                          <strong>
                                            That patient-first mindset drives
                                            Endo's unwavering commitment to
                                            quality and compliance.
                                          </strong>
                                          <span> </span>
                                        </p>
                                        <p>
                                          We abide by stringent manufacturing
                                          standards, which helps us achieve
                                          consistent and reliable production and
                                          meet and exceed industry regulations.
                                          At the same time, we embrace an
                                          environment of continuous improvement.
                                          <span> </span>
                                        </p>
                                        <p>
                                          Through Endo's Culture of Excellence
                                          program, our team members identify
                                          process improvements, seek diverse
                                          perspectives to solve problems, and
                                          innovate new ways of working to
                                          optimize the quality and effectiveness
                                          of our manufacturing processes.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CultureQuality;
