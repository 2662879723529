import React from "react";

const Nonsurgical = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/01b9c95d6d584b91be18ebb8dd67e7c2"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-01b9c95d-6d58-4b91-be18-ebb8dd67e7c2"
        class="block-section"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/8fdef679644340b39a6eb26bc9732bea"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-c14db737-f1dc-4182-9058-a9f42678be32"
                    class="block-column p-r-x-large p-b-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/3003f85e079c47b0a9006859a27931e6"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/543mcvmq/non-surgical-treatments.webp"
                                alt="A technician processes a vial"
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/c11c91a07f54498a952184291773e317"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-c11c91a0-7f54-498a-9521-84291773e317"
                              class="block-header font-size-large"
                            >
                              Nonsurgical Treatments&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/72eab5527784471386d30da76eb74cab"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Within our specialty research and development
                                arm, we apply the latest technology to identify
                                opportunities to improve patient outcomes
                                through nonsurgical medicines. For instance, in
                                recent years, our extensive research has
                                confirmed the role of a specific enzyme in the
                                treatment of several connective tissue
                                disorders. Successful regulatory approvals of
                                different presentations of a specific enzyme
                                across multiple therapeutic areas have bolstered
                                our team’s dedication and commitment to address
                                areas of continued unmet patient needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/00032dad34c74316a877daf66180be03"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-f1cc5ce3-1ca5-4ca1-8025-8932629d126f"
                    class="block-column p-l-x-large p-b-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/7188b8e3c3944746bd242e2570d888e7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/pbaptj1a/sterile-injectibles.webp"
                                alt="Hands hold a rack of vials"
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/5fc3eb21273d48039ae4f6267ea80428"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-5fc3eb21-273d-4803-9ae4-f6267ea80428"
                              class="block-header font-size-large"
                            >
                              Sterile Injectables
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/5ab3add979384e4081cf09634d0e0388"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We constantly pursue new product solutions that
                                address hospitals’ operational and clinical
                                challenges. Our sterile product portfolio will
                                grow quickly over the next few years, with
                                dozens of projects in our pipeline. Within the
                                complex sterile injectables category, we are
                                particularly proud to offer ready-to-administer
                                (RTA) and ready-to-use (RTU) medicines, part of
                                our
                                <a
                                  rel="noopener"
                                  href="https://www.trudelivery.com/"
                                  target="_blank"
                                >
                                  TruDelivery®
                                </a>
                                platform, that help streamline hospital
                                operations and support patient care when it
                                matters most.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="separatorBlock"
                  data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                  data-element-udi="umb://element/43427e863dd8463ea0f36128962192f0"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div class="block-separator type-full-width"></div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/816de2cb94704910971d160e834dc6f3"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-b857e2ad-3607-417c-b39f-8200edc91a76"
                    class="block-column p-r-x-large p-t-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/68422a4814bd444fa4ebfe488187c81c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/fs0prgzw/retail-generics.webp"
                                alt="An opened hand with a blue pill in it"
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/abcb40a78c5a4b91810342506ad3f3a1"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-abcb40a7-8c5a-4b91-8103-42506ad3f3a1"
                              class="block-header font-size-large"
                            >
                              Retail Generics&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/34e46ace85ae4e9aada4375d111dcb1a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Endo has a long history of developing and
                                delivering generic pharmaceutical products in a
                                variety of therapeutic categories. Bringing a
                                therapeutically equivalent (also called
                                AB-rated) generic product to market requires
                                specialized product design expertise, consistent
                                manufacturing processes, and demonstrated
                                effectiveness through bioequivalence studies. At
                                all times, we maintain unwavering standards for
                                quality, efficacy, and safety as we bring these
                                lifesaving, life-enhancing, and affordable
                                medicines to patients in need.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/01d24d57d6634ed7a31b94beeb21d789"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-bcfba399-30bb-4c44-b218-af92457ddc35"
                    class="block-column p-l-x-large p-t-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/c94a7a89440e4d059f934c9f1fa9da04"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/pbpmqvat/real-world-data.webp"
                                alt="A closeup of hands typing on a keyboard"
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/8087186ac468440f8a89cdbdfc9eea6d"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-8087186a-c468-440f-8a89-cdbdfc9eea6d"
                              class="block-header font-size-large"
                            >
                              Real-World Data
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/b8adb6e2fc8f46fab1393d203b2f8fbc"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We continue to study our products even after
                                they are on the market to understand how
                                healthcare providers and patients use them in
                                the real world. We assess data from a variety of
                                sources, including medical claims, electronic
                                medical records, and patient surveys. When a
                                rare disease lacks meaningful data, we may
                                create our own disease registries to help cull
                                the information from a variety of providers and
                                experts. These real-world research efforts
                                demonstrate our dedication to continuous
                                learning and optimal patient outcomes.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Nonsurgical;
