import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [expandedNavItems, setExpandedNavItems] = useState({});
  const [isUtilityNavOpen, setIsUtilityNavOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Set active states based on the current path
    setActiveStates(location.pathname);
  }, [location.pathname]);

  const handleToggleClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleCloseClick = () => {
    setIsNavOpen(false);
  };

  const handleSubnavToggle = (index) => {
    setExpandedNavItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleKeyPress = (event, callback) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callback();
    }
  };

  const handleUtilityNavToggle = () => {
    setIsUtilityNavOpen(!isUtilityNavOpen);
  };

  const setActiveStates = (path) => {
    const items = document.querySelectorAll("#primary-nav > li");

    items.forEach((item, index) => {
      const link = item.querySelector("ul.dropdown li a");

      if (link && link.getAttribute("href") === path) {
        setExpandedNavItems((prev) => ({ ...prev, [index]: true }));
      }

      navItems.forEach((navv) => {
        if (location.pathname.split("/")[1] === navv.lb.toLowerCase()) {
          const labelName = document.querySelectorAll(".mf-title");
          labelName.forEach((lb) => {
            if (lb.children[0].textContent === navv.label) {
              lb.classList.add("parent-active");
            } else {
              lb.classList.remove("parent-active");
            }
          });
        }
      });
    });
  };

  const navItems = [
    {
      label: "Company",
      lb: "Company",
      links: [
        { to: "/company/our-purpose/", text: "Purpose" },
        { to: "/company/leadership/", text: "Leadership" },
        { to: "/company/history/", text: "History" },
        {
          to: "/company/policies-and-statements/",
          text: "Policies & Statements",
        },
        { to: "/company/contact-locations/", text: "Contact & Locations" },
      ],
    },
    {
      lb: "Products-and-Capabilities",
      label: "Products & Capabilities",
      links: [
        { to: "/products-and-capabilities/focus-areas/", text: "Focus Areas" },
        { to: "/products-and-capabilities/products/", text: "Products" },
        {
          to: "/products-and-capabilities/manufacturing/",
          text: "Manufacturing",
        },
        { to: "/products-and-capabilities/suppliers/", text: "Suppliers" },
      ],
    },
    {
      lb: "Research-and-Development",
      label: "Research & Development",
      links: [
        { to: "/research-and-development/rd-approach/", text: "R&D Approach" },
        { to: "/research-and-development/pipeline/", text: "Pipeline" },
        {
          to: "/research-and-development/clinical-research/",
          text: "Clinical Research",
        },
        { to: "/research-and-development/research-grants/", text: "Grants" },
      ],
    },
    {
      label: "Newsroom",
      lb: "Newsroom",
      links: [
        { to: "/newsroom/media/", text: "Media" },
        { to: "/newsroom/stories/", text: "Stories" },
      ],
    },
    {
      label: "Responsibility",
      lb: "Responsibility",
      links: [
        {
          to: "/responsibility/ethics-and-compliance/",
          text: "Ethics & Compliance",
        },
        { to: "/responsibility/team-and-culture/", text: "Team & Culture" },
        {
          to: "/responsibility/customers-and-patients/",
          text: "Customers & Patients",
        },
        { to: "/responsibility/environment/", text: "Environment" },
        { to: "/responsibility/partnerships/", text: "Partnerships" },
        {
          to: "/responsibility/progress-and-reports/",
          text: "Progress & Reports",
        },
      ],
    },
  ];

  return (
    <header id="masthead" className="theme-dark">
      <div className="global-container">
        <p id="masthead-logo">
          <Link to="/">
            <img
              src="https://www.endo.com/images/Logo.svg"
              alt="Endo Logo"
              width="116"
              height="34"
            />
          </Link>
        </p>

        <button
          tabIndex="0"
          id="mobile-toggle"
          aria-label="Toggle mobile navigation"
          onClick={handleToggleClick}
          onKeyPress={(e) => handleKeyPress(e, handleToggleClick)}
        >
          <svg
            width="30"
            height="19"
            viewBox="0 0 30 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="hamburger">
              <rect
                id="Rectangle 128"
                y="0.238525"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
              <rect
                id="Rectangle 129"
                y="7.28284"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
              <rect
                id="Rectangle 130"
                y="14.3268"
                width="30"
                height="4"
                rx="2"
                fill="#656969"
              />
            </g>
          </svg>
        </button>

        <nav
          id="masthead-navigation-primary"
          aria-label="Primary Navigation"
          className={isNavOpen ? "active" : ""}
        >
          <button
            aria-label="Close mobile navigation"
            id="mobile-navigation-close"
            onClick={handleCloseClick}
          >
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                fill="#656969"
              />
            </svg>
          </button>
          <div id="primay-nav-container">
            <ul id="primary-nav">
              {navItems.map((item, index) => (
                <li
                  key={index}
                  tabIndex="0"
                  className={`mf-title`}
                  aria-expanded={expandedNavItems[index] || false}
                  onClick={() => handleSubnavToggle(index)}
                  onKeyPress={(e) =>
                    handleKeyPress(e, () => handleSubnavToggle(index))
                  }
                >
                  <span className="nolink">{item.label}</span>
                  <ul className="dropdown">
                    {item.links.map((link, linkIndex) => (
                      <li
                        key={linkIndex}
                        className={
                          link.to === location.pathname ? "child-active" : ""
                        }
                      >
                        <Link to={link.to}>{link.text}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>

            <span className="sep"></span>
            <ul id="utility-nav">
              <li
                className={`parent-link-container ${
                  isUtilityNavOpen ? "active" : ""
                }`}
                onClick={handleUtilityNavToggle}
                onKeyPress={(e) => handleKeyPress(e, handleUtilityNavToggle)}
                tabIndex="0"
              >
                <a className="parent-link wd_top_link">Investors</a>
                <ul className="dropdown">
                  <li>
                    <a href="/investor" target="_self">
                      Overview
                    </a>
                  </li>
                  <li>
                    <a href="/investor/press-releases" target="_self">
                      Press Releases
                    </a>
                  </li>
                  <li>
                    <a href="/investor/board-committees" target="_self">
                      Board Committees
                    </a>
                  </li>
                  <li>
                    <a href="/company/policies-and-statements/" target="_self">
                      Board Policies & Guidelines
                    </a>
                  </li>
                  <li>
                    <a href="/investor/ir-archive" target="_self">
                      Investor Resources Archive
                    </a>
                  </li>
                  <li>
                    <a href="/investor/financial-information" target="_self">
                      Financial Information
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="icon-external" href="/careers/" target="_self">
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
