import React from "react";
import "../css/investor.css";
import { Helmet } from "react-helmet";
import Header from "../investorComponents/Header";
import Footer from "../components/Footer";
import Main from "../investorComponents/Main";
import OurCompany from "../investorComponents/OurCompany";
import LatestestNews from "../investorComponents/LatestestNews";
import SignUpAlerts from "../investorComponents/SignUpAlerts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Investor = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Investors | Endo - Overview</title>

        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta name="twitter:card" content="summary" />
        <meta property="og:site_name" content="Investors | Endo" />
        <meta property="og:url" content="https://investor.endo.com/overview" />
        <link rel="stylesheet" href="css/omj1cgh.css" />
        <link rel="stylesheet" href="css/site.min.css" />
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="manifest" href="site.webmanifest" />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
        />

        <link rel="canonical" href="https://investor.endo.com/" />
      </Helmet>
      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container">
            <Main />
          </div>
          <OurCompany />
          <LatestestNews />
          <SignUpAlerts notify={notify} successNotify={successNotify} />
          <hr class="wd_form_placeholder" />
        </div>
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Investor;
