import React from "react";

const Sterile = ({ sterile }) => {
  return (
    <div
      ref={sterile}
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/7ab9389b83564f1fa8c039882aad563f"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="sterile-injectables"
        class="block-section p-b-large p-t-medium-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/8bc2e0b80a774812ba8f952dcb4a8472"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-e7b1c502-dee0-437b-a7fe-8cdcc6ac1e73"
                    class="block-column p-r-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/2e80c4b1d308405893d91e19028ff607"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/0konljqn/sterile-injectibles.webp"
                                alt="A father sits in a hospital bed while holding his daughter&#x27;s hand."
                                width="0"
                                height="0"
                              />
                              <figcaption></figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/98a02a1542ca47ff8e5172c04630dd58"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-13cdd0ab-a635-4833-b492-f91da01a0a5c"
                    class="block-column p-r-medium"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/6515680760dd4d29bc80d9d38440f777"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-65156807-60dd-4d29-bc80-d9d38440f777"
                              class="block-header font-size-large"
                            >
                              Sterile Injectables&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/481bd24e30364ea58bfbd4883012ebe3"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                We partner with hospitals and healthcare systems
                                to reduce complexity through life-enhancing
                                solutions, innovating together to add value
                                where it matters—from manufacturing to
                                treatment—so that healthcare providers can focus
                                on patient care.
                              </p>
                              <p>
                                Our aseptic product portfolio spans various
                                therapeutic categories and includes both branded
                                and generic injectables.
                              </p>
                              <p>
                                Our ready-to-administer (RTA) and ready-to-use
                                (RTU) products, available through our
                                <a
                                  rel="noopener"
                                  href="https://www.trudelivery.com/"
                                  target="_blank"
                                >
                                  TruDelivery®
                                </a>
                                platform, streamline hospital operations by
                                eliminating the need for preparation time or
                                transfer before patient administration. This can
                                reduce preparation time, enhance convenience and
                                workflow, and minimize potential preparation
                                errors. All these advantages support quality
                                care for patients in hospitals, clinics, and
                                home health and long-term care systems.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/72d2f595e82b43c6bafe5025fcd8c613"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Sterile;
