import React from "react";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notfound = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>404 page | Endo</title>
        <meta name="description" />
        <meta property="og:title" content="404 page" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.endo.com/404-page/" />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container">
            <div
              class="umb-block-grid__layout-item"
              data-content-element-type-alias="sectionBlock"
              data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
              data-element-udi="umb://element/79424da26cc243bd8e92fd78f91abb12"
              data-col-span="12"
              data-row-span="1"
              style={{
                "--umb-block-grid--item-column-span": "12",
                "--umb-block-grid--item-row-span": "1",
              }}
            >
              <section
                id="section-79424da2-6cc2-43bd-8e92-fd78f91abb12"
                class="block-section p-b-xxx-large p-t-xxx-large ignore-mobile-padding"
              >
                <picture class="section-image">
                  <img
                    class="bg-image"
                    loading="lazy"
                    src="https://www.endo.com/media/vkzeihxo/hero-404.webp"
                    alt=""
                    width="1540"
                    height="839"
                  />
                </picture>

                <div class="global-container">
                  <div
                    class="umb-block-grid__area-container"
                    style={{ "--umb-block-grid--area-grid-columns": "1" }}
                  >
                    <div
                      class="umb-block-grid__area"
                      data-area-col-span="12"
                      data-area-row-span="1"
                      data-area-alias="inner"
                      style={{
                        "--umb-block-grid--grid-columns": "12",
                        "--umb-block-grid--area-column-span": "12",
                        "--umb-block-grid--area-row-span": "1",
                      }}
                    >
                      <div class="umb-block-grid__layout-container">
                        <div
                          class="umb-block-grid__layout-item"
                          data-content-element-type-alias="columnBlock"
                          data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                          data-element-udi="umb://element/4daa031e243b4852891db7c014f53e7a"
                          data-col-span="12"
                          data-row-span="1"
                          style={{
                            "--umb-block-grid--item-column-span": "12",
                            "--umb-block-grid--item-row-span": "1",
                          }}
                        >
                          <div
                            id="column-eca21055-a06c-4fad-a797-e84ef9415689"
                            class="block-column p-b-x-large p-t-x-large"
                          >
                            <div
                              class="umb-block-grid__area-container"
                              style={{
                                "--umb-block-grid--area-grid-columns": "1",
                              }}
                            >
                              <div
                                class="umb-block-grid__area"
                                data-area-col-span="12"
                                data-area-row-span="1"
                                data-area-alias="inner"
                                style={{
                                  "--umb-block-grid--grid-columns": "12",
                                  "--umb-block-grid--area-column-span": "12",
                                  "--umb-block-grid--area-row-span": "1",
                                }}
                              >
                                <div class="umb-block-grid__layout-container">
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="labelBlock"
                                    data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                    data-element-udi="umb://element/70f9a5a40b4c4a1abcc7d735fd8e57d7"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <p class="block-label talign-center">
                                      Page not found
                                    </p>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/0cf76ab8b2c9454d927c6eb3bf78ae0c"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h1
                                      id="header-0cf76ab8-b2c9-454d-927c-6eb3bf78ae0c"
                                      class="block-header font-size-xxx-large talign-center"
                                    >
                                      404
                                    </h1>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/d6b264909cd94615b27ba12e1c5f79a8"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text font-size-medium-large font-weight-medium talign-center m-b-medium-large">
                                      <p>
                                        Sorry! We were unable to <br />
                                        find what you’re looking for
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="buttonGroupBlock"
                                    data-content-element-type-key="60df19bf-89a7-4bd8-81d4-7f0fea515acb"
                                    data-element-udi="umb://element/983ca0313d6d4df1af956d3ac6938711"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-btn-group align-h-center">
                                      <div class="block-btn">
                                        <a class="common-btn" href="/">
                                          Go to the Homepage
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Notfound;
