import React from "react";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Main from "../teamComponents/Main";
import TeamQuote from "../teamComponents/TeamQuote";
import Supporting from "../teamComponents/Supporting";
import FeelingGood from "../teamComponents/FeelingGood";
import Join from "../teamComponents/Join";
// import MeetTeam from "../teamComponents/MeetTeam";
import RelatedStories from "../focusComponents/RelatedStories";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Team = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Team and Culture | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Team and Culture" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/responsibility/team-and-culture/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/media/htppcylf/hero-team-and-culture.webp"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container">
            <Main />
            <TeamQuote />
            <Supporting />
            <FeelingGood />
            <Join />
            {/* <MeetTeam /> */}
            <RelatedStories />
          </div>
        </div>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Team;
