import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CherylStouch = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Cheryl Stouch | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Cheryl Stouch" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/cheryl-stouch/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Cheryl Stouch</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/dqwb1ahf/cheryl-stouch.webp"
                  alt="Cheryl Stouch"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Cheryl Stouch</h1>

              <p class="team-position">
                Senior Vice President, Information Technology and Chief
                Information Officer
              </p>

              <div class="team-bio">
                <p>
                  Cheryl Stouch has served as Senior Vice President, Information
                  Technology and Chief Information Officer of Endo International
                  plc since June 2022, and continues in that role today. In this
                  role, she has responsibility for the overall IT strategy and
                  roadmap to support Endo’s business strategy and vision,
                  ensuring digital capabilities for all global operations and
                  team members. She joined the company in May 2020 as Executive
                  Director of IT Corporate Functions &amp; End User Services.
                  Since joining Endo, Ms. Stouch has championed the development
                  and implementation of our enterprise collaboration strategy
                  and is a founding member of AWE (Alliance for Women at Endo).
                </p>
                <p>
                  Prior to joining Endo International plc, Ms. Stouch spent 13
                  years at Shire (now Takeda), where she held various positions
                  of increasing responsibility within IT, partnering with
                  leaders from across the business. Her most recent role at
                  Shire was Head of IT Cross Function &amp; Platforms, where she
                  was accountable for eight Global Enterprise Capabilities
                  supporting 32,000 team members.
                </p>
                <p>
                  Ms. Stouch began her career in the food industry and then in
                  management consulting, working for IBM focused on SAP
                  Enterprise Solutions. Ms. Stouch is a member of the Healthcare
                  Businesswomen’s Association (HBA). She holds a Bachelor of
                  Business and a Bachelor of Science for Information Systems
                  from Dakota State University.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default CherylStouch;
