import React from "react";

const Main = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="heroBlock"
      data-content-element-type-key="e8d63b4b-5bbe-4afd-8533-01727a730aeb"
      data-element-udi="umb://element/08a08cd2e70e467abb5930051e4a6598"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section class="block-section hero-block">
        <picture class="hero-image">
          <source
            media="(max-width: 781px)"
            srcset="https://www.endo.com/media/14apjjbp/hero-mobile-careers.webp"
            width="0"
            height="0"
            alt="Two colleagues in discussion at a table"
          />

          <img
            class="bg-image"
            src="https://www.endo.com/media/13hfic10/hero-careers.webp"
            alt="Three colleagues sitting at a table in a meeting."
            width="0"
            height="0"
          />
        </picture>

        <div class="section-block-inner">
          <div class="global-container">
            <div
              class="umb-block-grid__area-container"
              style={{ "--umb-block-grid--area-grid-columns": "12" }}
            >
              <div
                class="umb-block-grid__area"
                data-area-col-span="12"
                data-area-row-span="1"
                data-area-alias="Inner"
                style={{
                  "--umb-block-grid--grid-columns": "12",
                  "--umb-block-grid--area-column-span": "12",
                  "--umb-block-grid--area-row-span": "1",
                }}
              >
                <div class="umb-block-grid__layout-container">
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="columnBlock"
                    data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                    data-element-udi="umb://element/3f54800542a544edb63d1832c8bc37e7"
                    data-col-span="3"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": "3",
                      "--umb-block-grid--item-row-span": "1",
                    }}
                  >
                    <div
                      id="column-9dfb0cac-1508-469f-9a88-15016e0a2b67"
                      class="block-column"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": "1" }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": "12",
                            "--umb-block-grid--area-column-span": "12",
                            "--umb-block-grid--area-row-span": "1",
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="labelBlock"
                              data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                              data-element-udi="umb://element/2576b32655f7476ba598656175884b6d"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <p class="block-label">
                                Opportunities for Success
                              </p>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="headerBlock"
                              data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                              data-element-udi="umb://element/819837d77fa042978e1de40f2f214ab7"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <h1
                                id="header-819837d7-7fa0-4297-8e1d-e40f2f214ab7"
                                class="block-header"
                                style={{ color: "#3f4443" }}
                              >
                                Careers
                              </h1>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="richTextBlock"
                              data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                              data-element-udi="umb://element/99ea634bba0243d5b679f3415a705525"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-rich-text font-size-medium-large font-weight-medium mobile-br">
                                <p style={{ color: "#3f4443" }}>
                                  Follow your inspiration, <br />
                                  make a difference
                                </p>
                              </div>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="buttonBlock"
                              data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                              data-element-udi="umb://element/f04033fc0fa84a20aa590a8c03f7ea89"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-btn">
                                <a
                                  class="common-btn"
                                  href="/careers/open-positions/"
                                  target=""
                                >
                                  Explore Open Positions
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
