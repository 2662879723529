import React from "react";

const ClinicalTrialConduct = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/dfd5ecfc7f50487daaec38765f17d47d"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-dfd5ecfc-7f50-487d-aaec-38765f17d47d"
        class="block-section align-v-top align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/b151e5369fad46baa51fc7aa8d168fa0"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-d8212f25-a0a2-4723-bd8c-6d540c1c868a"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/69892b10ac0447cb985579ac912d4253"
                            data-col-span="10"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 10,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-e83f51c4-10ec-412e-b4a5-72d0931d7f59"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/3bb3b6d062314fca8959d2f469b8c9dc"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-3bb3b6d0-6231-4fca-8959-d2f469b8c9dc"
                                        class="block-header"
                                      >
                                        Clinical Trial Conduct&#xA0;
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/19aaa69d40dd40aa93cca40a9c379472"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text font-size-medium m-b-medium-large">
                                        <p>
                                          We run studies with a dedication to
                                          the highest quality and safety
                                          standards at the core. Our clinical
                                          studies are performed in compliance
                                          with current Good Clinical Practices,
                                          including the ICH E6 guidance. In
                                          addition, the Endo Generics business
                                          unit follows all applicable
                                          regulations for generic drug
                                          development issued by the Office of
                                          Generics (OGD).
                                        </p>
                                        <p>
                                          <strong>
                                            Key elements of our process include:
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/bca8dd0c345949e9a217cdb2e023ca8e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-293230cc-746e-4258-bd13-cff219ddade0"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/f36f226c1db34529bb8d0c1302e42773"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-2e61ba94-bb80-4bc4-9131-8ec30be4eb38"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/9c1e7388aaf349ccb308c381a096bc2c"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/3uilcqjz/icon-training.svg"
                                                              alt="Icon - group of figures sitting around a table"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/17ac96b41872415e8ea89e0d5b80168c"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-7097bafe-6369-4e6c-9da0-461702e85573"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/78df0ad5af824a98a42d6dc688642143"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-78df0ad5-af82-4a98-a42d-6dc688642143"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Training
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/7716b20b128a4c429caaa50127d24195"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              All R&amp;D team
                                                              members go through
                                                              robust annual
                                                              training aligned
                                                              with the ICH E6
                                                              Good Clinical
                                                              Practice training
                                                              and Good
                                                              Manufacturing
                                                              Practice
                                                              Compliance
                                                              training, as well
                                                              as Endo’s Standard
                                                              Operating
                                                              Procedure on the
                                                              Protection of
                                                              Human Subjects:
                                                              Ethics and
                                                              Advertising
                                                              Approvals.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/b1f72326f1354e76937c111a31d863d6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-75cdf666-d620-4556-8bea-ad2d7de919ff"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/ce3e4f5ae8ca4100bdfe791e59344a3e"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-7e677dde-8654-40f0-97a2-c0f0dcb35cc6"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/8bce38154537449f9bbb5e35d186bb85"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/jkzpywcs/icon-oversight.svg"
                                                              alt="Icon - clipboard surrounding by people figures"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/a5c02e558b344dcc8e82fb9131367c29"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-adf7e623-3874-4970-882d-41a688a48b5e"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/30cabd9770b54ff4b0f36b19800496cd"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-30cabd97-70b5-4ff4-b0f3-6b19800496cd"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Oversight&#xA0;
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/ec24138ec4324d6c8520b0d51278384a"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              All phase 1-4
                                                              clinical study
                                                              protocols are
                                                              reviewed and
                                                              approved by an
                                                              established
                                                              internal committee
                                                              called the
                                                              Protocol Approval
                                                              Committee. This
                                                              cross-functional
                                                              team evaluates
                                                              protocol
                                                              concepts/synopses
                                                              to determine
                                                              scientific merit
                                                              and approvability
                                                              and assesses the
                                                              ethical
                                                              implications of
                                                              the study.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/ca908dcf29614ddf8db4e5f7c9a20c4e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-de1666fa-1f20-4ff0-b42a-f571f1543f4f"
                                        class="block-column p-b-small"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/9582154f55cb4b2eace3e79aa96d2632"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-e0c1a98a-9b47-443a-b473-cf5c3a02ca39"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/971e0ce6a0eb4ac98b770731cbe56358"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/mdbnqvob/icon-risk-based-management.svg"
                                                              alt="Icon - figure standing in front of a presentation screen"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/8938478de4f94e12828ad18766092d91"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-0b92f76c-0d63-486a-947e-262aa6075ba7"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/e24b56a6b74e45cba2d608f1b05242c1"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-e24b56a6-b74e-45cb-a2d6-08f1b05242c1"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Risk-Based
                                                            Management&#xA0;
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/ceb06ca29635464590c8a3d7a6c3748f"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              Our Risk
                                                              Assessment and
                                                              Management process
                                                              begins at the
                                                              planning stage and
                                                              extends through
                                                              the execution of
                                                              clinical studies.
                                                              Through this
                                                              process, we
                                                              identify all
                                                              potential risks
                                                              and establish a
                                                              plan to manage
                                                              them.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/0b90ea1c71d34233a0f5a45a5d513055"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-b55c4e66-9863-48a2-99fd-9143122119c4"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/4d079f25aa934eee89155313f8729b8d"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-df87f696-8805-4306-a2e5-91ca8debe68b"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/2d14925037d34b6eb873727f8839b076"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/woqdqhmf/icon-patient-protections.svg"
                                                              alt="Icon - shield with checkmark"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/92f232b5f2ff4d819f86382900964b3f"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-8ce19261-8c65-4b1b-b086-82c76c882e2e"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/2f8686f94aea4622ba8d8767fc5ae7d7"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-2f8686f9-4aea-4622-ba8d-8767fc5ae7d7"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Patient
                                                            Protections&#xA0;
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/7bc59caf835e4de88d8fceed819421dd"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              Our standard
                                                              operating
                                                              procedures require
                                                              that we follow all
                                                              applicable
                                                              regulations around
                                                              vulnerable
                                                              participants,
                                                              including but not
                                                              limited to
                                                              children,
                                                              prisoners, and
                                                              pregnant women.
                                                              All trials follow
                                                              applicable privacy
                                                              laws and
                                                              regulations to
                                                              maintain patient
                                                              privacy.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/db676f6b2bee4abcb1adb39ac08302b8"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-8df9e996-5193-4993-a2a4-64391181490e"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/75fa7bfc65eb451f8c2bd5a1947f6f7b"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-9b4a80ed-c51b-49a9-a298-6d8fd0e81887"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/1ecfcc9a86db45ab8bd25f4cd5f90612"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/atfhwisx/icon-proactive-quality-management.svg"
                                                              alt="Icon - list and a award ribbon"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/516058326e4449cba0bf29d89456351f"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-1f0e5ad8-46fb-4b09-9e5b-291d57d0878b"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/e44d04025834442f9a5f812360adcefd"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-e44d0402-5834-442f-9a5f-812360adcefd"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Proactive Quality
                                                            Management
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/8c62c27819ce4c73838212f9086e3b4e"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              We employ Quality
                                                              by Design (QbD)
                                                              principles when
                                                              initiating and
                                                              monitoring our
                                                              clinical trials. A
                                                              dedicated team
                                                              plans and reviews
                                                              the execution of
                                                              all studies and
                                                              works with the
                                                              R&amp;D leadership
                                                              team to
                                                              investigate and
                                                              address any issues
                                                              that arise.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/467673c3cec8402e92d4aa5f63c0ed99"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-17fb928a-42c2-40b8-b12f-636c52f02b23"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/90abe7c023354e7ab794872835034d00"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-b86b47ee-8b10-44d7-8beb-6e728d6e1e86"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/e75f8f81efcc437997c1793896f60419"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/1cebzral/icon-electronic-systems.svg"
                                                              alt="Icon - electronic connections and a clipboard"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/45d57656fb8741c6a3e5ea0ea5902773"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-bd2d4919-e8fe-42ec-8fd4-8e9319ebdf49"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/23d0ebb76b6a46789e7a84b3d6a9f05b"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-23d0ebb7-6b6a-4678-9e7a-84b3d6a9f05b"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Electronic Systems
                                                            &amp;
                                                            Documentation&#xA0;
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/72004a09ef47431bbf80c29281280ba6"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              Electronic systems
                                                              support the
                                                              validation and
                                                              quality control of
                                                              all users and
                                                              track and document
                                                              all data entries
                                                              required over the
                                                              course of any
                                                              given trial, in
                                                              support of the
                                                              ALCOA+C principles
                                                              of quality
                                                              documentation.
                                                              ALCOA stands for
                                                              Attributable,
                                                              Legible,
                                                              Contemporaneous,
                                                              Original,
                                                              Accurate, and
                                                              Complete.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/8c802cd641284eac83e756483d98242a"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-d3130fef-1120-447d-a24f-d4a85edfe750"
                                        class="block-column p-b-medium"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/781164c4a6924ed1ad3ccb37824c97bc"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-34e28300-a4e9-414a-badf-55bf83685292"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/f02bf9cff453414ba4179810c419839e"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/x1gmser2/icon-auditing.svg"
                                                              alt="Icon - hand holding a magnifying glass"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/69d8fffb67704dd2a5d0395cd0cfd8c8"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-a3c60895-6df8-48c1-85e0-c846c6922310"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/26ee59df3b784cc6a1beae166c030835"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-26ee59df-3b78-4cc6-a1be-ae166c030835"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Auditing&#xA0;
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/826d7602e6114eefa0641c1d82bccdf0"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              We have clear
                                                              standard operating
                                                              procedures in
                                                              place to support
                                                              additional
                                                              auditing efforts,
                                                              including vendor,
                                                              system,
                                                              investigator, and
                                                              regulatory audits.
                                                              We audit our
                                                              clinical trial
                                                              sites throughout
                                                              the course of
                                                              studies using a
                                                              risk-based
                                                              approach and use
                                                              standard
                                                              monitoring
                                                              processes. All
                                                              applicable vendors
                                                              used in the
                                                              clinical trial
                                                              process are
                                                              audited prior to
                                                              the start of their
                                                              work to support
                                                              our clinical
                                                              trials.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/c31ece8fdabd4783ae0f7607caaef7e2"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div
                                        id="column-8a87d5ab-b1f5-4e0e-80de-72ee54a484f9"
                                        class="block-column"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns": 12,
                                              "--umb-block-grid--area-column-span": 12,
                                              "--umb-block-grid--area-row-span": 1,
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/aad4e2bab9564905b403adce43176d6b"
                                                data-col-span="1"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 1,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-07c0479a-4882-4c43-bfa4-69f02e05beea"
                                                  class="block-column"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="imageBlock"
                                                          data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                                          data-element-udi="umb://element/09205c11f5c649c8872d9c90f9e1aaf6"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <figure class="block-image is-icon">
                                                            <img
                                                              loading="lazy"
                                                              src="https://www.endo.com/media/phcmydqx/icon-building.svg"
                                                              alt="Building icon"
                                                            />
                                                          </figure>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="columnBlock"
                                                data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                                data-element-udi="umb://element/cadfdcacb10d4f4bb0031f4451a8447e"
                                                data-col-span="11"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span": 11,
                                                  "--umb-block-grid--item-row-span": 1,
                                                }}
                                              >
                                                <div
                                                  id="column-06dc7da1-2a74-47f0-a2f2-322926d76f9d"
                                                  class="block-column p-l-small"
                                                >
                                                  <div
                                                    class="umb-block-grid__area-container"
                                                    style={{
                                                      "--umb-block-grid--area-grid-columns":
                                                        "1",
                                                    }}
                                                  >
                                                    <div
                                                      class="umb-block-grid__area"
                                                      data-area-col-span="12"
                                                      data-area-row-span="1"
                                                      data-area-alias="inner"
                                                      style={{
                                                        "--umb-block-grid--grid-columns": 12,
                                                        "--umb-block-grid--area-column-span": 12,
                                                        "--umb-block-grid--area-row-span": 1,
                                                      }}
                                                    >
                                                      <div class="umb-block-grid__layout-container">
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="headerBlock"
                                                          data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                                          data-element-udi="umb://element/4536fdae5ec84845b0111a56a546cef5"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <h3
                                                            id="header-4536fdae-5ec8-4845-b011-1a56a546cef5"
                                                            class="block-header font-size-medium-large m-b-xx-small"
                                                          >
                                                            Pre-Clinical Work
                                                          </h3>
                                                        </div>
                                                        <div
                                                          class="umb-block-grid__layout-item"
                                                          data-content-element-type-alias="richTextBlock"
                                                          data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                          data-element-udi="umb://element/5c86ab06d8d74e9e842c13f5e8d372f9"
                                                          data-col-span="12"
                                                          data-row-span="1"
                                                          style={{
                                                            "--umb-block-grid--item-column-span": 12,
                                                            "--umb-block-grid--item-row-span": 1,
                                                          }}
                                                        >
                                                          <div class="block-rich-text">
                                                            <p>
                                                              We approach the
                                                              use of animals
                                                              with humane and
                                                              ethical concern.
                                                              Our R&amp;D
                                                              efforts are
                                                              primarily focused
                                                              on phases 1-3, as
                                                              well as
                                                              post-marketing
                                                              studies and label
                                                              extension studies.
                                                              Therefore, our use
                                                              of animals in
                                                              research is
                                                              limited. When
                                                              pre-clinical work
                                                              is required, Endo
                                                              aims to limit
                                                              animal testing.
                                                              Alternatives are
                                                              used whenever they
                                                              are scientifically
                                                              valid and
                                                              acceptable to
                                                              regulators. Endo
                                                              only contracts
                                                              with companies
                                                              with bioethical
                                                              standards in line
                                                              with our own
                                                              policies.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ClinicalTrialConduct;
