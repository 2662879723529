import React from "react";

const MainHero = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="heroBlock"
      data-content-element-type-key="e8d63b4b-5bbe-4afd-8533-01727a730aeb"
      data-element-udi="umb://element/b036fbcf87c14edd87c5b07d0d783b30"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section class="block-section hero-block align-v-top align-h-left">
        <picture class="hero-image">
          <source
            media="(max-width: 781px)"
            srcset="
                    https://www.endo.com/media/1ollutqk/hero-mobile-home.webp
                  "
            width="0"
            height="0"
            alt="A father picks up his young child and give them a kiss."
          />

          <img
            class="bg-image"
            src="https://www.endo.com/media/q2zlwrsp/hero-home-1.webp"
            alt="A father picks up his young child and give them a kiss."
            width="0"
            height="0"
          />
        </picture>

        <div class="section-block-inner">
          <div class="global-container">
            <div
              class="umb-block-grid__area-container"
              style={{ "--umb-block-grid--area-grid-columns": 12 }}
            >
              <div
                class="umb-block-grid__area"
                data-area-col-span="12"
                data-area-row-span="1"
                data-area-alias="Inner"
                style={{
                  "--umb-block-grid--grid-columns": 12,
                  "--umb-block-grid--area-column-span": 12,
                  "--umb-block-grid--area-row-span": 1,
                }}
              >
                <div class="umb-block-grid__layout-container">
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="columnBlock"
                    data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                    data-element-udi="umb://element/e9ef571bf15a4723bcb109bebb1a177a"
                    data-col-span="5"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": 5,
                      "--umb-block-grid--item-row-span": 1,
                    }}
                  >
                    <div
                      id="column-77d005c2-9ef8-465f-8f30-e7d454c0d245"
                      class="block-column"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": 1 }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": 12,
                            "--umb-block-grid--area-column-span": 12,
                            "--umb-block-grid--area-row-span": 1,
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="headerBlock"
                              data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                              data-element-udi="umb://element/cac6b894f7b04bae9392218b3c3e5ac6"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": 12,
                                "--umb-block-grid--item-row-span": 1,
                              }}
                            >
                              <h1
                                id="header-cac6b894-f7b0-4bae-9392-218b3c3e5ac6"
                                class="block-header"
                                style={{ color: "#3f4443" }}
                              >
                                Inspired for life
                              </h1>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="richTextBlock"
                              data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                              data-element-udi="umb://element/427b6819c18845ba93b1356ca8ffadb2"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-rich-text font-size-medium-large font-weight-medium">
                                <p style={{ color: "#3f4443" }}>
                                  We’re boldly transforming insights into
                                  <br />
                                  life-enhancing therapies.
                                </p>
                              </div>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="buttonBlock"
                              data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                              data-element-udi="umb://element/5b62de6522fb48e295de5cc3ba726d17"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-btn">
                                <a
                                  class="common-btn is-modal"
                                  href="https://www.endo.com/media/sw1chfu0/endo-anthem-video-1080p_10mbps.mp4"
                                >
                                  See the Video
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainHero;
