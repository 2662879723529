import React, { useEffect, useState } from "react";

const SupplierDiversity = () => {
  const [expandedId, setExpandedId] = useState("");

  const handleAccordionClick = (id) => {
    setExpandedId(expandedId === id ? "" : id);
  };

  // Set the height of the panel based on the state change
  useEffect(() => {
    const panels = document.querySelectorAll(".accordion-panel");
    panels.forEach((panel) => {
      const inner = panel.querySelector(".accordion-panel-inner");
      if (panel.parentElement.dataset.key === expandedId) {
        panel.style.height = `${inner.offsetHeight}px`;
      } else {
        panel.style.height = "0px";
      }
    });
  }, [expandedId]);

  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/049f9c1d683c44889bf4f9bc998dddae"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-049f9c1d-683c-4488-9bf4-f9bc998dddae"
        class="block-section align-h-center p-b-x-large p-t-x-large"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/ml2o1gfq/background-decorative.webp"
            alt=""
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/1b5122156bfa4c9197aa7135b0bd9323"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-ba4a514b-e186-47b3-a451-74931fdaaf94"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          {/* here */}

                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/6c7e6df7881641efbc1626f8b100492c"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 6,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-eae708ab-e516-43dd-bf9d-69aecf0a4629"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/2c84c2ce3fe440f8a4307a1708cde027"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-2c84c2ce-3fe4-40f8-a430-7a1708cde027"
                                        class="block-header font-size-large"
                                      >
                                        Supplier Registration
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/c427ef68b8674b6ca1d0cbb86f0736f6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We strive to build collaborative and
                                          lasting relationships with our
                                          suppliers, which play an integral role
                                          in our commitment to excellence.
                                          Suppliers interested in being
                                          considered for future business with
                                          Endo should complete the online
                                          Supplier Registration.
                                        </p>
                                        <p>
                                          Our Strategic Sourcing Team will
                                          contact registered companies if
                                          opportunities become available in that
                                          company’s area of expertise. Should
                                          this occur, additional requirements
                                          may need to be met prior to conducting
                                          business with Endo.
                                        </p>
                                        <p>
                                          We will keep your information on hand
                                          and may contact you as opportunities
                                          become available in your area of
                                          expertise. 
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/187e0c82aea84972a6d32ad33f4f1026"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-btn">
                                        <a
                                          class="common-btn"
                                          href="https://endo.corcentricplatform.com/"
                                          target="_blank"
                                        >
                                          Register Here
                                        </a>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d" style={{ width: "70vh" }}>
                            {" "}
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="accordionBlock"
                              data-content-element-type-key="0c32fb25-ca42-4ca6-985a-e8a7ee7031b4"
                              data-element-udi="umb://element/e118b757e58c4864b20eb781549f5f11"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div
                                className="block-accordion"
                                data-expand="False"
                                data-remove-indent="False"
                                aria-roledescription="accordion"
                              >
                                {accordionData.map((data) => (
                                  <div
                                    key={data.id}
                                    data-key={data.id}
                                    className={`accordion-row ${
                                      data.id === expandedId ? "active" : ""
                                    }`}
                                  >
                                    <h2
                                      aria-expanded={
                                        data.id === expandedId ? "" : "false"
                                      }
                                      tabIndex="0"
                                      className="accordion-header"
                                      onClick={() =>
                                        handleAccordionClick(data.id)
                                      }
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          handleAccordionClick(data.id);
                                        }
                                      }}
                                    >
                                      {data.title}
                                    </h2>
                                    <div className="accordion-panel">
                                      <div className="accordion-panel-inner">
                                        <div
                                          className="umb-block-grid"
                                          data-grid-columns="12"
                                          style={{
                                            "--umb-block-grid--grid-columns":
                                              "12",
                                          }}
                                        >
                                          <div className="umb-block-grid__layout-container">
                                            {data.contents.map(
                                              (content, index) => (
                                                <div
                                                  key={index}
                                                  className="umb-block-grid__layout-item"
                                                  data-content-element-type-alias={
                                                    content.typeAlias
                                                  }
                                                  data-content-element-type-key={
                                                    content.typeKey
                                                  }
                                                  data-element-udi={
                                                    content.elementUdi
                                                  }
                                                  data-col-span="12"
                                                  data-row-span="1"
                                                  style={{
                                                    "--umb-block-grid--item-column-span":
                                                      "12",
                                                    "--umb-block-grid--item-row-span":
                                                      "1",
                                                  }}
                                                >
                                                  <div className="block-btn type-text">
                                                    {content.p ? (
                                                      <p
                                                        className={`${
                                                          content.list &&
                                                          "disc-text"
                                                        } ${
                                                          content.bold &&
                                                          "bold-text"
                                                        } accordian-text-updated`}
                                                      >
                                                        {content.strong && (
                                                          <>
                                                            <strong>
                                                              {content.strong}
                                                            </strong>
                                                            <br />
                                                            <br />
                                                          </>
                                                        )}
                                                        {content.p}
                                                      </p>
                                                    ) : (
                                                      <a
                                                        className={`${
                                                          content.sublink &&
                                                          "sublink"
                                                        } common-btn`}
                                                        href={content.href}
                                                        target={`${
                                                          !content.sublink &&
                                                          !content.self
                                                            ? "_blank"
                                                            : "_self"
                                                        }`}
                                                        rel="noopener noreferrer"
                                                      >
                                                        {content.text}
                                                        {!content.sublink && (
                                                          <div className="arrow-wrap">
                                                            <svg
                                                              width="40"
                                                              height="12"
                                                              viewBox="0 0 40 12"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                                fill="#E87722"
                                                              />
                                                              <path
                                                                d="M33 11L38 6L33 1"
                                                                stroke="#E87722"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                              />
                                                            </svg>
                                                          </div>
                                                        )}
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const accordionData = [
  {
    id: "supplier-reg",
    title: "Supplier Registration Requirements",
    contents: [
      {
        p: "Federal Taxpayer Identification Number (TIN) if a U.S. company",
        list: true,
      },
      {
        p: "Primary company contact information",
        list: true,
      },
      {
        p: "Company information",
        list: true,
      },
      {
        p: "Dun & Bradstreet number",
        list: true,
      },
      {
        p: "NAICS (North American Industry Classification System) code",
        list: true,
      },
      {
        p: "Company diversity classification and any applicable supporting certification(s)",
        list: true,
      },
    ],
  },
  {
    id: "complete-supplier",
    title: "How to Complete Supplier Registration",
    contents: [
      {
        p: "Click on the Supplier Registration link to begin the Supplier Registration process.",
        list: true,
      },
      {
        p: "Complete the basic information on the New Supplier Registration page and press “Submit”.",
        list: true,
      },
      {
        p: "You will receive a confirmation email. Click the link to access our Supplier Registration System. Please note: In the event you exit the registration process before submitting your profile, you can re-use this link to return to the System.",
        list: true,
      },
      {
        p: "Complete each section of the Supplier Registration Profile.",
        list: true,
      },
    ],
  },

  // Add more accordion data here
];

export default SupplierDiversity;
