import React from "react";

const BoardCommittieRoute = () => {
  return (
    <div class="wd_content-wrapper-left top-content-container">
      <div class="breadcrumb">
        <a href="/investor">Investors</a>
        <span> &gt; </span>
        <a href="/investor/board-committees" target="_self" class="nav">
          Board Committees
        </a>
      </div>

      <div class="wd_content"></div>
    </div>
  );
};

export default BoardCommittieRoute;
