import React from "react";
import { Helmet } from "react-helmet";
import Header from "../investorComponents/Header";
import Footer from "../components/Footer";
import IrRoutes from "../investorComponents/IrRoutes";
import Ivr from "../investorComponents/Ivr";

const IrArchive = () => {
  return (
    <div>
      <Helmet>
        <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Investors | Endo - Investor Resources Archive</title>

        <meta name="keywords" content="" />
        <meta name="description" content="" />
        <meta name="twitter:card" content="summary" />
        <meta property="og:site_name" content="Investors | Endo" />
        <meta
          property="og:url"
          content="https://investor.endo.com/ir-archive"
        />
        <link rel="stylesheet" href="css/omj1cgh.css" />
        <link rel="stylesheet" href="css/site.min.css" />
        <link rel="manifest" href="site.webmanifest" />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />
      </Helmet>

      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container"></div>
          <IrRoutes />
          <Ivr />
          <hr class="wd_form_placeholder" />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default IrArchive;
