import React from "react";

const OurFoundation = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/2e120188e8e44a7d89214d455607dd3c"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-2e120188-e8e4-4a7d-8921-4d455607dd3c"
        class="block-section p-t-x-small ignore-mobile-padding"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/d4873f99d3c64ceea859c2912bdd92dd"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-b07e54f2-56f2-42b1-a512-18a8ed0fa74e"
                    class="block-column p-b-medium-large p-t-0"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/2651431bf76a475bb95da4ad5b1dfd59"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-xx-small">
                              <p>
                                Parke-Davis Farm. From the Archives of the
                                Rochester Hills Museum at Van Hoosen Farm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/41930c92fdcb4f8c98b6eeff09dd17cc"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-40c88fba-6e17-4a80-b9af-8278dd1951c4"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/7a735c48e2644b689a2b6cabb80e6876"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label">Our Foundational Start</p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/668d718eb0c04ec28ca587a1edcafb0c"
                            data-col-span="9"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "9",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-668d718e-b0c0-4ec2-8ca5-87a1edcafb0c"
                              class="block-header font-size-x-large"
                            >
                              Inspiring Healthcare Across Generations
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/d94344c32c904672ba5382902e9ed4f4"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Endo’s history can be traced back to the early
                                20th century and includes respected names in the
                                science and pharmaceutical space, namely DuPont,
                                Merck, and Parke-Davis.
                              </p>
                              <p>
                                In 1920, Intravenous Products of America, Inc.
                                was founded as a family-run pharmaceutical
                                company in New York City, and 15 years later,
                                the company changed its name to Endo Products.
                                Through an acquisition a few decades later, Endo
                                became part of E.I. du Pont de Nemours and
                                Company, better known as DuPont. The move
                                followed lagging sales of DuPont’s first
                                FDA-approved pharmaceutical product and aimed to
                                boost the company’s marketing and drug research
                                capabilities as it expanded into health
                                sciences.
                              </p>
                              <p>
                                Fast-forward to the nineties, when DuPont teamed
                                up with industry giant Merck Pharmaceuticals on
                                a joint venture that would see the first
                                FDA-approved treatment for AIDS, among other
                                successful new medicines, by the latter part of
                                the decade. During this time, DuPont-Merck
                                reestablished Endo as a separate entity within
                                the company, called Endo Laboratories LLC., to
                                focus solely on generic medications. In 1997,
                                three of the division’s executives purchased all
                                of Endo Laboratories’ generic products, along
                                with a dozen branded medicines, to form Endo
                                Pharmaceuticals Inc.
                              </p>
                              <p>
                                Today, Endo is a specialty pharmaceutical
                                company focusing on developing and delivering
                                life-enhancing products—many of which are
                                complex in nature and require unique expertise,
                                technology, and equipment. We focus on medical
                                therapeutics, specifically in the areas of
                                urology, orthopedics, and endocrinology, and on
                                sterile injectables for hospitals and health
                                systems, along with generics.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/8341310e6a84471eae161da47df8b60a"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-afe0a96b-45a6-4e35-8708-de83d328449d"
                    class="block-column p-l-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/6ead88475ee7469a978d23cdb25ec879"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <figure class="block-image has-rc m-b-medium-large">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/p5kgrokb/endo-black-and-white.webp"
                                alt="Home office and laboratories of Endo Products, Inc., Richmond Hill, New York"
                                width="0"
                                height="0"
                              />
                              <figcaption>
                                Home office and laboratories of Endo Products,
                                Inc., Richmond Hill, New York
                              </figcaption>
                            </figure>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="imageBlock"
                            data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                            data-element-udi="umb://element/813cbff9d78c4529b97df1d235e6aa3c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <figure class="block-image has-rc">
                              <img
                                loading="lazy"
                                src="https://www.endo.com/media/g1zhnbis/endo-team-97.webp"
                                alt="Endo team shortly after executive buyout established the company in 1997"
                                width="0"
                                height="0"
                              />
                              <figcaption>
                                Endo team shortly after executive buyout
                                established the company in 1997
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurFoundation;
