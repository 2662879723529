import React from "react";
import { Helmet } from "react-helmet";
import Main from "../suppliersComponent/Main";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import SupplierQuote from "../suppliersComponent/SupplierQuote";
import SupplierPhilosophy from "../suppliersComponent/SupplierPhilosophy";
import SupplierCode from "../suppliersComponent/SupplierCode";
import SupplierDiversity from "../suppliersComponent/SupplierDiversity";
import SmallBuisness from "../suppliersComponent/SmallBuisness";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Suppliers = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Suppliers | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Suppliers" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/products-and-capabilities/suppliers/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/media/bcjomr1v/hero-suppliers.webp"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/site.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container">
            <Main />
            <SupplierQuote />
            <SupplierPhilosophy />
            <SupplierCode />
            <SupplierDiversity />
            <SmallBuisness />
          </div>
        </div>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Suppliers;
