import React, { useState } from "react";

const ContactEndo = ({ contactendo, notify, successNotify }) => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    comment: "",
    department: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (
      !formState.firstName ||
      !formState.lastName ||
      !formState.email ||
      !formState.comment
    ) {
      notify("Please fill all the fields");
      return;
    } else {
      fetch("https://formspree.io/f/xkgwgeog", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          name: `${formState.firstName} ${formState.lastName}`,
          email: formState.email,
          message: formState.comment,
          department: formState.department,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormState({
              ...formState,
              firstName: "",
              lastName: "",
              email: "",
              comment: "",
              department: "",
            });
            successNotify("Message sent!!!");
          }
        })
        .catch(() => notify("Failed to send message."));
    }
  };

  return (
    <div
      ref={contactendo}
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/ff03188a32fc4795bcbfbdcb107ffb4f"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="contact-endo"
        class="block-section align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/1f04d137d74147c48cb80c6e46b028df"
                  data-col-span="8"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "8",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-186f66c7-ccc0-4159-9583-02c012137e83"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/7d32d73f851140e8aa9db1bf45e0db24"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-7d32d73f-8511-40e8-aa9d-b1bf45e0db24"
                              class="block-header font-size-large"
                            >
                              Contact Endo
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/1d96acfcef264fcea6985f7647891976"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>Use the form below to reach the right team.</p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="formBlock"
                            data-content-element-type-key="19759500-8c53-451e-bb9d-8089e83f11fc"
                            data-element-udi="umb://element/d613046fcedf4502944cacf2c3e34f20"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-form">
                              <div
                                id="umbraco_form_f21b3f6d438d46d2861c44c139de8815"
                                class="umbraco-forms-form contactgeneral umbraco-forms-default"
                              >
                                <form onSubmit={submitHandler}>
                                  <input
                                    data-val="true"
                                    data-val-required="The FormId field is required."
                                    name="FormId"
                                    type="hidden"
                                    value="f21b3f6d-438d-46d2-861c-44c139de8815"
                                  />
                                  <input
                                    data-val="true"
                                    data-val-required="The FormName field is required."
                                    name="FormName"
                                    type="hidden"
                                    value="Contact General"
                                  />
                                  <input
                                    data-val="true"
                                    data-val-required="The RecordId field is required."
                                    name="RecordId"
                                    type="hidden"
                                    value="00000000-0000-0000-0000-000000000000"
                                  />
                                  <input
                                    name="PreviousClicked"
                                    type="hidden"
                                    value=""
                                  />
                                  <input
                                    name="Theme"
                                    type="hidden"
                                    value="default"
                                  />
                                  <input
                                    name="RedirectToPageId"
                                    type="hidden"
                                    value=""
                                  />
                                  <input
                                    type="hidden"
                                    name="FormStep"
                                    value="0"
                                  />
                                  <input
                                    type="hidden"
                                    name="RecordState"
                                    value="Q2ZESjhOaUY5bmJPM3FaQWlXbURPalQ0bk1Pa25pQ2todzRKaUgya0FYaDBFX0hOTFppaG5XTGlCcy1Fdk53dGZiQzdScnZUSTNtZnc4SzdsOVQ3SEhIUWFuRDVZTTkxc2x3bDNHSnc0cHlPQlE2dmZoeXVyOThjV2RNUW9GbzVWcVNEQmc="
                                  />

                                  <div
                                    class="umbraco-forms-page"
                                    id="1ae8221a-f128-42e7-9991-1474b2bbcd92"
                                  >
                                    <fieldset
                                      class="umbraco-forms-fieldset"
                                      id="c505fe98-d198-482e-96af-a6e4bd85e2de"
                                    >
                                      <div class="row-fluid">
                                        <div class="umbraco-forms-container col-md-12">
                                          <div class="umbraco-forms-field firstname shortanswer mandatory">
                                            <label
                                              for="b9b4f51b-6f15-4606-f31e-919668a2973e"
                                              class="umbraco-forms-label"
                                            >
                                              First Name
                                            </label>

                                            <div class="umbraco-forms-field-wrapper">
                                              <input
                                                type="text"
                                                name="firstName"
                                                onChange={changeHandler}
                                                id="b9b4f51b-6f15-4606-f31e-919668a2973e"
                                                data-umb="b9b4f51b-6f15-4606-f31e-919668a2973e"
                                                class="text"
                                                value={formState.firstName}
                                                maxlength="255"
                                                placeholder="Enter First Name"
                                                data-val="true"
                                                data-val-required="Please provide a value for First Name"
                                                aria-required="true"
                                              />

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="b9b4f51b-6f15-4606-f31e-919668a2973e"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field lastname shortanswer mandatory alternating">
                                            <label
                                              for="8c75bdf3-a866-4934-856f-789896869992"
                                              class="umbraco-forms-label"
                                            >
                                              Last Name
                                            </label>

                                            <div class="umbraco-forms-field-wrapper">
                                              <input
                                                type="text"
                                                name="lastName"
                                                id="8c75bdf3-a866-4934-856f-789896869992"
                                                data-umb="8c75bdf3-a866-4934-856f-789896869992"
                                                class="text"
                                                onChange={changeHandler}
                                                value={formState.lastName}
                                                maxlength="255"
                                                placeholder="Enter Last Name"
                                                data-val="true"
                                                data-val-required="Please provide a value for Last Name"
                                                aria-required="true"
                                              />

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="8c75bdf3-a866-4934-856f-789896869992"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field emailaddress shortanswer mandatory">
                                            <label
                                              for="f30b9d70-7745-43fd-b090-a27d5efbc4e6"
                                              class="umbraco-forms-label"
                                            >
                                              Email Address
                                            </label>

                                            <div class="umbraco-forms-field-wrapper">
                                              <input
                                                type="email"
                                                name="email"
                                                id="f30b9d70-7745-43fd-b090-a27d5efbc4e6"
                                                data-umb="f30b9d70-7745-43fd-b090-a27d5efbc4e6"
                                                class="text"
                                                onChange={changeHandler}
                                                value={formState.email}
                                                maxlength="255"
                                                placeholder="Enter Email Address"
                                                data-val="true"
                                                data-val-required="Please provide a value for Email Address"
                                                aria-required="true"
                                              />

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="f30b9d70-7745-43fd-b090-a27d5efbc4e6"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field contact dropdown mandatory alternating">
                                            <label
                                              for="1c6013ee-6724-4c69-8e8a-230cf87fd425"
                                              class="umbraco-forms-label"
                                            >
                                              Contact
                                            </label>

                                            <div class="umbraco-forms-field-wrapper">
                                              <select
                                                class=""
                                                name="department"
                                                id="1c6013ee-6724-4c69-8e8a-230cf87fd425"
                                                data-umb="1c6013ee-6724-4c69-8e8a-230cf87fd425"
                                                data-val="true"
                                                data-val-required="Please provide a value for Contact"
                                                aria-required="true"
                                                value={formState.department}
                                                onChange={changeHandler}
                                              >
                                                <option value="">
                                                  Select Department
                                                </option>
                                                <option value="customer-service">
                                                  Customer Service
                                                </option>
                                                <option value="medical-information">
                                                  Medical Information
                                                </option>
                                                <option value="business-development">
                                                  Business Development
                                                </option>
                                                <option value="investor-relations">
                                                  Investor Relations
                                                </option>
                                              </select>

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="1c6013ee-6724-4c69-8e8a-230cf87fd425"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field comment longanswer mandatory">
                                            <label
                                              for="6f61fbcd-2ad3-4d0f-9853-3c52db845e5d"
                                              class="umbraco-forms-label"
                                            >
                                              Comment
                                            </label>

                                            <div class="umbraco-forms-field-wrapper">
                                              <textarea
                                                class=""
                                                name="comment"
                                                id="6f61fbcd-2ad3-4d0f-9853-3c52db845e5d"
                                                data-umb="6f61fbcd-2ad3-4d0f-9853-3c52db845e5d"
                                                rows="2"
                                                cols="20"
                                                onChange={changeHandler}
                                                value={formState.comment}
                                                placeholder="Enter your comment"
                                                data-val="true"
                                                data-val-required="Please provide a value for Comment"
                                                aria-required="true"
                                              ></textarea>

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="6f61fbcd-2ad3-4d0f-9853-3c52db845e5d"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field smallprint richtext alternating">
                                            <div class="umbraco-forms-field-wrapper">
                                              <div
                                                id="94f7932e-c341-4a16-b584-dde2e3a0d3bd"
                                                data-umb="94f7932e-c341-4a16-b584-dde2e3a0d3bd"
                                                class=""
                                              >
                                                <p>
                                                  This contact form is not
                                                  intended for reporting adverse
                                                  drug experiences. If you need
                                                  to report an adverse drug
                                                  experience, please call our
                                                  toll-free number at (800)
                                                  462-ENDO (3636).
                                                </p>
                                              </div>

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="94f7932e-c341-4a16-b584-dde2e3a0d3bd"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                          <div class="umbraco-forms-field recaptcha recaptchav3withscore mandatory">
                                            <div class="umbraco-forms-field-wrapper">
                                              <input
                                                type="hidden"
                                                id="be67fc52-ce22-4df2-c02d-690b2b69fa6c"
                                                name="g-recaptcha-response"
                                              />
                                              <div
                                                class="umbraco-forms-recaptcha-v3-config umbraco-forms-hidden"
                                                data-id="be67fc52-ce22-4df2-c02d-690b2b69fa6c"
                                                data-site-key="6Le-Xr0pAAAAAI2Kf6BzmFDPKKeNkTDVeNGHnGaY"
                                              ></div>

                                              <span
                                                class="field-validation-valid"
                                                data-valmsg-for="be67fc52-ce22-4df2-c02d-690b2b69fa6c"
                                                data-valmsg-replace="true"
                                                role="alert"
                                              ></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>

                                    <div
                                      class="umbraco-forms-hidden"
                                      aria-hidden="true"
                                    >
                                      <input
                                        type="text"
                                        name="f21b3f6d438d46d2861c44c139de8815"
                                      />
                                    </div>

                                    <div class="umbraco-forms-navigation row-fluid">
                                      <div class="col-md-12">
                                        <input
                                          type="submit"
                                          class="btn primary"
                                          value="Submit"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <input
                                    name="__RequestVerificationToken"
                                    type="hidden"
                                    value="CfDJ8NiF9nbO3qZAiWmDOjT4nMMyCTHhGl99ydEh-4iKzlpEhAnHhlAzFLAyMzDci3O4pkrbXCojzXXnnL_jsJCr7mkPHzeiITxwhMzfM4rVAzMWhiJjwXEYk_kKecORmesgFuV9klM7H9rxD02DmuaYu-s"
                                  />
                                  <input
                                    name="ufprt"
                                    type="hidden"
                                    value="CfDJ8NiF9nbO3qZAiWmDOjT4nMP9D1pIj8h2HUrZFaPjzv-QA1EQS2rFhpz_5IbQ3IxG07IKnzdIev3NPTsTScNFJUVLMhyIOQlh6gO7x_swaHfINmZo75-LQk12RJsxA5gj2LlVlmSIa2tny2cM_WBRzss"
                                  />
                                </form>
                              </div>

                              <div
                                class="umbraco-forms-form-config umbraco-forms-hidden"
                                data-id="f21b3f6d438d46d2861c44c139de8815"
                                data-serialized-page-button-conditions="{}"
                                data-serialized-fieldset-conditions="{}"
                                data-serialized-field-conditions="{}"
                                data-serialized-fields-not-displayed="{}"
                                data-trigger-conditions-check-on="change"
                                data-form-element-html-id-prefix=""
                                data-disable-validation-dependency-check="true"
                              ></div>

                              <script
                                defer="defer"
                                src="https://www.google.com/recaptcha/api.js?render=6Le-Xr0pAAAAAI2Kf6BzmFDPKKeNkTDVeNGHnGaY"
                                type="application/javascript"
                              ></script>
                              <script
                                defer="defer"
                                src="/App_Plugins/UmbracoForms/Assets/recaptcha.v3.init.min.js"
                                type="application/javascript"
                              ></script>
                              <script
                                defer="defer"
                                src="/App_Plugins/UmbracoForms/Assets/themes/default/umbracoforms.min.js"
                                type="application/javascript"
                              ></script>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactEndo;
