import React from "react";

const IrRoutes = () => {
  return (
    <div class="wd_content-wrapper-left top-content-container">
      <div class="breadcrumb">
        <a href="/investor">Investors</a>
        <span> &gt; </span>
        <a href="/investor/ir-archive" target="_self" class="nav">
          Investor Resources Archive
        </a>
      </div>

      <div class="wd_content"></div>
    </div>
  );
};

export default IrRoutes;
