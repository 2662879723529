import React from "react";

const PipelineQuote = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/5752ea5c86f84644a4309dab76a5926b"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-5752ea5c-86f8-4644-a430-9dab76a5926b"
        class="block-section align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/de8808eae0b64f158a6f0db7c50f049a"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-0ba47ebb-17ce-4cfc-a01c-20ffa0d92bbd"
                    class="block-column p-l-large p-r-large p-b-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/174792c232294bba965f240486c2e0cb"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-x-large font-weight-medium talign-center m-b-medium-large">
                              <p>
                                Our commitment to innovation fuels our ongoing
                                efforts to pioneer medical solutions. Discover
                                the advancements we’re bringing to life.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/2ae7339960a24e53978e83de01115aa5"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator align-h-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/f942b14aa9b3446cad072f56f2d995e1"
                  data-col-span="8"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 8,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-a20b6331-a80f-4db8-8a40-f315a024b384"
                    class="block-column p-b-medium-large p-t-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/de3c35633fd34c40b3f0bb8c2d47705b"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-de3c3563-3fd3-4c40-b3f0-bb8c2d47705b"
                              class="block-header talign-center"
                            >
                              Products in development&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/14bb197ec1364aa7894bd1c011306077"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-medium talign-center">
                              <p>
                                We are dedicated to advancing medical knowledge
                                and developing life-enhancing products. The
                                snapshot below provides an overview of our
                                clinical development programs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/505ce5f987924a9db13f269384b4b572"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-d412c918-238f-4eb9-959b-cd535638ed51"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/50b29ff1ddc84843b7520e711c40b442"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-50b29ff1-ddc8-4843-b752-0e711c40b442"
                              class="block-header font-size-medium-large"
                            >
                              Specialty Medicines
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="tableBlockSpecialtyMedicines"
                            data-content-element-type-key="aeb2fded-7c54-4383-9cb6-c2baecf869f0"
                            data-element-udi="umb://element/69afc40be675429dbf4dfc36e09b1eaa"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="product-chart block-products-detailed global-container">
                              <div class="table-chart-wrap">
                                <div class="row-therapeutic row-1">
                                  <div class="col">
                                    <div class="th">Therapeutic Areas</div>
                                    <h3>Orthopedics</h3>
                                  </div>

                                  <div class="container-compound">
                                    <div class="row-compound row-1">
                                      <div class="col">
                                        <div class="th">Compound*</div>
                                        <h4>
                                          XIAFLEX&#xAE;&#x2028;(collagenase
                                          clostridium histolyticum)
                                        </h4>
                                      </div>

                                      <div class="container-indication">
                                        <div class="row-indication first">
                                          <div class="col indication-name-col">
                                            <div class="th">Indication*</div>
                                          </div>
                                          <div class="col">
                                            <div class="th text-color-dark-orange">
                                              Phase I
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="th text-color-dark-orange">
                                              Phase II
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="th text-color-dark-orange">
                                              Phase III
                                            </div>
                                          </div>
                                          <div class="col">
                                            <div class="th">
                                              Regulatory Submission
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="row-indication"
                                          data-phase="phase 3 half"
                                        >
                                          <div class="col indication-name-col">
                                            <p class="indication-name">
                                              Plantar Fibromatosis
                                            </p>
                                          </div>
                                          <div class="col phase phase1">
                                            <div class="bar show cap-end-square"></div>
                                          </div>
                                          <div class="col phase phase2">
                                            <div class="bar show cap-end-square cap-start-square"></div>
                                          </div>
                                          <div class="col phase phase3">
                                            <div class="bar show cap-end-round cap-start-square half"></div>
                                          </div>
                                          <div class="col phase regulatory"></div>
                                        </div>
                                        <div
                                          class="row-indication"
                                          data-phase="phase 2 half"
                                        >
                                          <div class="col indication-name-col">
                                            <p class="indication-name">
                                              Plantar Fasciitis
                                            </p>
                                          </div>
                                          <div class="col phase phase1">
                                            <div class="bar show cap-end-square"></div>
                                          </div>
                                          <div class="col phase phase2">
                                            <div class="bar show cap-end-round cap-start-square half"></div>
                                          </div>
                                          <div class="col phase phase3"></div>
                                          <div class="col phase regulatory"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/de651466069c44e78f6660ce33a60aff"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-x-small">
                              <p>
                                *Products and indications listed here are not
                                currently approved by FDA. For full prescribing
                                information for approved products and
                                indications.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/63e658762ed745079b7cf7ec3d609cd0"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-x-small">
                              <p>
                                <span class="talign-center">
                                  Updated April 23, 2024
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/addf51e5d0f04636b87b46bf043981be"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width m-b-large m-t-large"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/3964c5a488344029b661b0014fbb4df0"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-3964c5a4-8834-4029-b661-b0014fbb4df0"
                              class="block-header font-size-medium-large"
                            >
                              Sterile Injectables
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="tableBlockProductsGeneral"
                            data-content-element-type-key="bc85a233-2e25-48fb-a32d-5ab736f5026f"
                            data-element-udi="umb://element/6f482c2df93443448a91ec5df247ca32"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="product-chart block-products-general global-container">
                              <h3 class="table-header">
                                50 Products in Development
                              </h3>

                              <div class="table-chart-wrap">
                                <div class="table-chart">
                                  <div
                                    class="table-chart-bar"
                                    style={{
                                      backgroundImage:
                                        "linear-gradient(120deg,var(--endo-preset-color-orange)75%,var(--endo-preset-color-yellow)75%)",
                                    }}
                                  ></div>
                                </div>
                                <div class="table-chart-key">
                                  <span class="r-d">
                                    Research & Development
                                  </span>
                                  <span class="r-s">Regulatory Submission</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/e749fcb891724b2ba43c987cd2e6d621"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-x-small">
                              <p>
                                <span class="talign-center">
                                  Updated April 23, 2024
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/02e1950542fc463d88eaf77e8e183f94"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator type-full-width m-b-large m-t-large"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/c189fbf187d34c8e86d12a0176ad5f5c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h3
                              id="header-c189fbf1-87d3-4c8e-86d1-2a0176ad5f5c"
                              class="block-header font-size-medium-large"
                            >
                              Generics
                            </h3>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="tableBlockProductsGeneral"
                            data-content-element-type-key="bc85a233-2e25-48fb-a32d-5ab736f5026f"
                            data-element-udi="umb://element/065d73f3fd954a93a62dfec33004003f"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="product-chart block-products-general global-container">
                              <h3 class="table-header">
                                12 Products in Development
                              </h3>

                              <div class="table-chart-wrap">
                                <div class="table-chart">
                                  <div
                                    class="table-chart-bar"
                                    style={{
                                      backgroundImage: `linear-gradient(
                                              120deg,
                                              var(--endo-preset-color-orange)
                                                30%,
                                              var(--endo-preset-color-yellow)
                                                30%
                                            )`,
                                    }}
                                  ></div>
                                </div>
                                <div class="table-chart-key">
                                  <span class="r-d">
                                    Research & Development
                                  </span>
                                  <span class="r-s">Regulatory Submission</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/be0e116999b142a992382150fd3534ad"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-x-small">
                              <p>
                                <span class="talign-center">
                                  Updated April 23, 2024
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PipelineQuote;
