import React from "react";

const LatestestNews = () => {
  return (
    <div class="wd_content-wrapper-left body-content-container">
      <div class="wd_content">
        <div class="wd_featureboxes_middle wd_featureboxes-horizontal">
          <div
            id="wd_featurebox-teaser_2"
            class="wd_featurebox_container wd_featurebox-teaser"
          >
            <div class="wd_featurebox_title">Latest News</div>
            <div class="wd_featurebox">
              <div class="wd_newsfeed_releases-teaser">
                <div class="item">
                  <div class="wd_date">Jun 28, 2024</div>
                  <div class="wd_title">
                    <a href="/investor/2024-06-28-Endo-Announces-Commencement-of-OTCQX-Trading">
                      Endo Announces Commencement of OTCQX Trading
                    </a>
                  </div>
                  <div class="wd_summary">
                    <p>
                      Endo, Inc. (OTCQX: NDOI) ("Endo" or the "Company") today
                      announced that the Company has commenced trading on the
                      OTCQX® Best Market ("OTCQX") under the ticker symbol NDOI.
                      "Trading on OTCQX is a...
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="wd_date">Jun 10, 2024</div>
                  <div class="wd_title">
                    <a href="/investor/2024-06-10-Endo-Launches-New-Dupuytrens-Contracture-Campaign-Reminders">
                      Endo Launches New Dupuytren's Contracture Campaign:
                      Reminders
                    </a>
                  </div>
                  <div class="wd_summary">
                    <p>
                      The campaign aims to embolden patients to become advocates
                      in their treatment journey and find a hand specialist who
                      considers their preferences and lifestyle. Commercial
                      features real Dupuytren's...
                    </p>
                  </div>
                </div>

                <div class="item">
                  <div class="wd_date">May 30, 2024</div>
                  <div class="wd_title">
                    <a href="/investor/2024-05-30-Endo-Provides-Select-First-Quarter-2024-Financial-Results-for-Endo-International-plc">
                      Endo Provides Select First-Quarter 2024 Financial Results
                      for Endo International plc
                    </a>
                  </div>
                  <div class="wd_summary">
                    <p>
                      Endo, Inc. ("Endo" or the "Company"), a newly formed
                      entity that recently acquired (the "Acquisition")
                      substantially all of the assets of Endo International plc
                      ("EIP") as contemplated by EIP's...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestestNews;
