import React from "react";

const Ceo = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/faff432bcf8c444c8b0dd27aa67671c0"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-faff432b-cf8c-444c-8b0d-d27aa67671c0"
        class="block-section align-h-center background-color-light-gray"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/je5l3eqo/background.webp"
            alt="decorative background gradient"
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/dd92471f1f13496f9950824bff16604b"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-28f99a54-125e-4fd2-9317-b4bc050903c5"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9da34256b94046c4bd9ceb5b58ac0bb3"
                            data-col-span="5"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "5",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-03476a1e-fbf2-4703-a4e8-24659aee0ff9"
                              class="block-column p-r-xx-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/ba928c19e7b94ec583165d07307566e7"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/ah3en41t/president-quote.webp"
                                          alt="Blaise Coleman, President and Chief Executive Officer"
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/73a60bf053164387919bea9887dc3c0a"
                            data-col-span="7"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "7",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-ac133485-305a-4ce8-b644-c1ec3de9f9ed"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/35511008da16498e90102254f8f77a27"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label">From Our CEO</p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/ae4c7f0a226d4f65b906c090126c36da"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text font-size-medium-large font-weight-medium">
                                        <p>
                                          “We have and will continue to
                                          challenge ourselves to operate in even
                                          more sustainable ways, knowing that by
                                          doing so, we will be one step closer
                                          to fulfilling our vision of helping
                                          everyone live their best life.”
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/eca2de95e94941768203909824e4db06"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text font-size-medium">
                                        <p>
                                          Blaise Coleman, President and Chief
                                          Executive Officer
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ceo;
