import React from "react";

const Leadership = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/bbbbb87f59c641ed99acbb8d459ab2a7"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-bbbbb87f-59c6-41ed-99ac-bb8d459ab2a7"
        class="block-section background-color-light-gray p-b-x-large p-t-x-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/1c3f3975ee1f48e39c07ca521fb078bc"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-7b43967e-0793-4180-b501-51550439bcc5"
                    class="block-column p-r-xxx-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/7e26d6f346a34f948c7eeefa179537d1"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-7e26d6f3-46a3-4f94-8c7e-eefa179537d1"
                              class="block-header font-size-large"
                            >
                              Leadership Responsibilities&#xA0;
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/e0243fc1a8b34d1f8018c673ae5f1b63"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Our leadership fosters a culture of compliance
                                by setting the appropriate “tone at the top” and
                                by demonstrating a commitment to ethical and
                                compliant business practices every day.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="buttonBlock"
                            data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                            data-element-udi="umb://element/a9518543d26d4f55ac5c923076e8f935"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-btn type-text">
                              <a class="common-btn" href="/company/leadership/">
                                Meet Our Leadership
                                <div class="arrow-wrap">
                                  <svg
                                    width="40"
                                    height="12"
                                    viewBox="0 0 40 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                      fill="#E87722"
                                    />
                                    <path
                                      d="M33 11L38 6L33 1"
                                      stroke="#E87722"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/facb72dae56e4f619ca5d26e202167da"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-34e12079-a133-407b-a071-96a59e2b785c"
                    class="block-column p-r-xx-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/15b25a95e0c64fc4b74941ad3081e03e"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-medium font-weight-medium">
                              <p>Leadership responsibilities include:</p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/dec7c499160243c3b4dfe7aea6db7a25"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-small">
                              <ul>
                                <li>
                                  Our Senior Vice President and Chief Compliance
                                  Officer reports directly to our CEO and to the
                                  Compliance Committee Board of Directors.
                                </li>
                                <li>
                                  Our Global Compliance Committee oversees,
                                  assesses, and enhances our compliance program
                                  and supports our strategic approach to
                                  compliance.
                                </li>
                                <li>
                                  Our Board of Directors provides oversight
                                  through a Compliance Committee that receives
                                  quarterly updates on Endo’s Compliance
                                  program.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Leadership;
