import React from "react";

const Access = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/451b399c4bc94d44bd789ddb3ee94a9f"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-451b399c-4bc9-4d44-bd78-9ddb3ee94a9f"
        class="block-section align-v-top align-h-center p-b-large p-t-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/e84132a7a4414c71aa3967bc7892e657"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-050c805f-0d8f-4823-a693-2d48736193f7"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/8557dbe820674c7aa3e564b8ff251f78"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-5a3f330a-f7a2-4c10-8ec6-9773a96e2dcd"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="imageBlock"
                                      data-content-element-type-key="8097f05b-90df-4922-85d8-271e5fc96abd"
                                      data-element-udi="umb://element/0293a68a860b4c4fa6bd0265572a318d"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <figure class="block-image">
                                        <img
                                          loading="lazy"
                                          src="https://www.endo.com/media/i5mniyom/access-and-affortability.webp"
                                          alt="A scientist studies a vial that he&#x27;s holding between his fingers."
                                          width="0"
                                          height="0"
                                        />
                                        <figcaption></figcaption>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/7721cbd1397942c7a24f5613623c91ef"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-9bfa19fc-b9bd-447c-a715-dceed94e9dff"
                              class="block-column p-l-x-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/478f935a556e4f57893065904c71634f"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-478f935a-556e-4f57-8930-65904c71634f"
                                        class="block-header"
                                      >
                                        Access & Affordability
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/cef5ab6331b64f7f9c5dcd9dab77c7c6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          Helping patients get access to the
                                          medicines and products they need is
                                          important to Endo. When pricing our
                                          products, we consider the value they
                                          bring to patients as well as the
                                          investment costs required to research,
                                          develop, and produce the products.
                                        </p>

                                        <p>
                                          In addition to providing low-cost
                                          generic medications, we donate
                                          significant quantities of products,
                                          support patient assistance programs,
                                          and fund broader community healthcare
                                          projects.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/7cfcd60d077e43418e27563e8626fc5a"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/k44hgnih/endo_2022_cr_report_05-01-23.pdf"
                                        >
                                          Read our Corporate Responsibility
                                          Report
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Access;
