import React from "react";

const Main = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="heroBlock"
      data-content-element-type-key="e8d63b4b-5bbe-4afd-8533-01727a730aeb"
      data-element-udi="umb://element/3857f16c446845748d699faac3a7fa40"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section class="block-section hero-block">
        <picture class="hero-image">
          <source
            media="(max-width: 781px)"
            srcset="https://www.endo.com/media/fp1ow243/hero-mobile-team-culture.webp"
            width="0"
            height="0"
            alt=""
          />

          <img
            class="bg-image"
            src="https://www.endo.com/media/htppcylf/hero-team-and-culture.webp"
            alt="Looking down onto a sit down meeting with 4 colleagues."
            width="0"
            height="0"
          />
        </picture>

        <div class="section-block-inner">
          <div class="global-container">
            <div
              class="umb-block-grid__area-container"
              style={{ "--umb-block-grid--area-grid-columns": "12" }}
            >
              <div
                class="umb-block-grid__area"
                data-area-col-span="12"
                data-area-row-span="1"
                data-area-alias="Inner"
                style={{
                  "--umb-block-grid--grid-columns": "12",
                  "--umb-block-grid--area-column-span": "12",
                  "--umb-block-grid--area-row-span": "1",
                }}
              >
                <div class="umb-block-grid__layout-container">
                  <div
                    class="umb-block-grid__layout-item"
                    data-content-element-type-alias="columnBlock"
                    data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                    data-element-udi="umb://element/43503bfb783c4377952239d05dbd8019"
                    data-col-span="4"
                    data-row-span="1"
                    style={{
                      "--umb-block-grid--item-column-span": "4",
                      "--umb-block-grid--item-row-span": "1",
                    }}
                  >
                    <div
                      id="column-9f372180-110b-4f1d-95a5-20aa1d791f3a"
                      class="block-column"
                    >
                      <div
                        class="umb-block-grid__area-container"
                        style={{ "--umb-block-grid--area-grid-columns": "1" }}
                      >
                        <div
                          class="umb-block-grid__area"
                          data-area-col-span="12"
                          data-area-row-span="1"
                          data-area-alias="inner"
                          style={{
                            "--umb-block-grid--grid-columns": "12",
                            "--umb-block-grid--area-column-span": "12",
                            "--umb-block-grid--area-row-span": "1",
                          }}
                        >
                          <div class="umb-block-grid__layout-container">
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="headerBlock"
                              data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                              data-element-udi="umb://element/be5db6883c1f4bfe9af97e9c57a96be5"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <h1
                                id="header-be5db688-3c1f-4bfe-9af9-7e9c57a96be5"
                                class="block-header"
                                style={{ color: "black" }}
                              >
                                Team &amp; Culture
                              </h1>
                            </div>
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="richTextBlock"
                              data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                              data-element-udi="umb://element/c4c97ac50c5448b586f4ded2fa733e69"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div class="block-rich-text font-size-medium-large font-weight-medium">
                                <p style={{ color: "black" }}>
                                  United by purpose
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Main;
