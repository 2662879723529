import React from "react";

const ContactLocations = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/cd1a190a6c1942c58b57098c53efebf2"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-cd1a190a-6c19-42c5-8b57-098c53efebf2"
        class="block-section align-h-left p-b-large p-t-xx-large ignore-mobile-padding"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/9e52b000e877444c86dc168dd39a2aec"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-3f22f262-7e2c-4a40-9671-1909765dd070"
                    class="block-column p-b-medium-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/44d8df24c848468ba3825f94ce59fb6d"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h1
                              id="header-44d8df24-c848-468b-a382-5f94ce59fb6d"
                              class="block-header font-size-x-large"
                            >
                              Contact &amp; Locations
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/d3a2c31dfd1043869111c8b4b724554b"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-d41ee88f-7e75-428e-a79a-64c9e9e16b29"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9e346568544f44c4971fb0f650d999a4"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-2182d7fd-a1f4-498f-a797-667c12ae2d0c"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/88a9c18eff04498daca2a72d9585b157"
                                      data-col-span="10"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "10",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-88a9c18e-ff04-498d-aca2-a72d9585b157"
                                        class="block-header font-size-medium-large text-color-dark-gray"
                                      >
                                        Endo Global Headquarters
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/dd85f0a9d32b4f0ba5ecaa1158db3cfb"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-9c428ce3-6613-4f64-9def-db312d1a6810"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/9f5343b7aca747ffbbe0477698ee5531"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-9f5343b7-aca7-47ff-bbe0-477698ee5531"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Malvern
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/887edbd60aab4cf2bb245c6a89692949"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          1400 Atwater Drive
                                          <br />
                                          Malvern, PA 19355
                                          <br />
                                          Phone: (484) 216-0000
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/fd5d5c5e35354b368bc986421a6b85f0"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-separator type-full-width background-color-gray-20 m-b-large m-t-small"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/025df533aea64e88bbe2f36373ceec87"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-30fe4510-cee0-4211-8882-3099b0f8212b"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/c8ead53d5c5646369e3ef1981748848f"
                                      data-col-span="8"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "8",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-c8ead53d-5c56-4636-9e3e-f1981748848f"
                                        class="block-header font-size-medium-large text-color-dark-gray"
                                      >
                                        Corporate Offices
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/931e3d551f0e433880383b319ec6b31a"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-32496a3f-e2a7-40b2-bc10-4889340eb194"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/99826113c57d42da9f9ca74fd97d1827"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-99826113-c57d-42da-9f9c-a74fd97d1827"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Dublin
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/1cecfd38bd524c81b1945bc497cb2eb4"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <span>
                                            First Floor, Minerva House
                                            <br />
                                            Simmonscourt Road
                                            <br />
                                            Ballsbridge, Ireland Dublin 4<br />
                                            Phone: +353 1 268 2000
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/12f6c2f030ce4759bb8de34185d5c0be"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-60ee0079-f761-4ac9-b19a-d9e884e6cdc4"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/20551e469526481f83ebd5fedbb1258e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-20551e46-9526-481f-83eb-d5fedbb1258e"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Woodcliff Lake
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/14129234550e4fdcb5b107139df35760"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          300 Tice Blvd, Suite 230
                                          <br />
                                          Woodcliff Lake, NJ 07677
                                          <br />
                                          Phone: (845) 403-6034
                                          <br />
                                          Fax: (845) 425-7907
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/952b0854238b417d92de57d16e61dbad"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-c9700ae9-0cfd-475e-ac5b-20d82c387594"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/870dbc15c43e48dfb7c581312743483d"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-870dbc15-c43e-48df-b7c5-81312743483d"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Montreal
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/56a35415db8242f090cacd11f47b4d66"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <strong>Paladin Pharma</strong>
                                          <br />
                                          100 Blvd Alexis-Nihon, Suite 600
                                          <br />
                                          Montreal, Quebec, H4M 2P2, Canada
                                          <br />
                                          Phone: (514) 340-1112
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/80fb7a94df17456caf7c6b5775dc4219"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-separator type-full-width m-b-large m-t-small"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/3b2080daad4c4351b8a4c4696973ac76"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-45a8da77-3510-4e62-8145-df1db8272150"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/d6535abe060a4eeca6f7eacad6d4910c"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-d6535abe-060a-4eec-a6f7-eacad6d4910c"
                                        class="block-header font-size-medium-large text-color-dark-gray"
                                      >
                                        U.S. Manufacturing
                                        Facilities&#xA0;&#xA0;
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/372124dc2d32476d8de7eeec7da6b7cf"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-8e31c50d-5023-4b4f-af23-dc8e05890182"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/b3243c2605b04d659af6b88b97475d1a"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-b3243c26-05b0-4d65-9af6-b88b97475d1a"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Cranbury
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/49914a2b73f44c9ab1966409f86c37e3"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          8 Clarke Drive
                                          <br />
                                          Cranbury, NJ 08512
                                          <br />
                                          Phone: (845) 403-6034
                                          <br />
                                          Fax: (609) 409-1654
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/c5bb51bfc5894bd7b38d3126cba7f53e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-c5bb51bf-c589-4bd7-b38d-3126cba7f53e"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Rye
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/de7947b92a144dc888bd818c64ef59e0"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <span>
                                            70 Maple Avenue
                                            <br />
                                            Rye, NY 10580
                                            <br />
                                            Phone: (914) 481-4241
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/68a2e8269cda4bba91024431ff5b8c70"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-a96258c8-929c-4543-99b6-fbad5a7f1f99"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/be58f08db722470f976beaf2780e8d8d"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-be58f08d-b722-470f-976b-eaf2780e8d8d"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Horsham
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/4486388c40e64501a4c3588dd9d9d25d"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <span>
                                            102 Witmer Road
                                            <br />
                                            Horsham, PA 19044
                                            <br />
                                            Phone: (267) 960-1800
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/12bb1d771da24913a5f1095405d04dde"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-b7139982-abb4-4338-a3df-6465f9e4ee9c"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/19e139bbdb884a37aa703d0f717c6dc1"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-19e139bb-db88-4a37-aa70-3d0f717c6dc1"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Rochester
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/f75eef61268d489bae153442c42745e9"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          870 Parkdale Road
                                          <br />
                                          Rochester, MI 48307
                                          <br />
                                          Phone: (845) 403-6034
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/a5309476aec94ed1bb00951e1f0b0d92"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-separator type-full-width m-b-large m-t-small"></div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/25ff1230cc284bb3a17bd2dcaca9fcae"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-08b785e1-2cd1-43c3-9fc5-974f0204e872"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/4e28f0f8ba1d4cd582c8705b9475f29e"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-4e28f0f8-ba1d-4cd5-82c8-705b9475f29e"
                                        class="block-header font-size-medium-large text-color-dark-gray"
                                      >
                                        India Manufacturing Facilities
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/4ccf5f1ac08f4ca7b9e62d100865c21e"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-5f957f87-d4b2-4f6f-ae6c-9802dab1ea79"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/117202fd2d524ffda41b3dbc79e2f950"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-117202fd-2d52-4ffd-a41b-3dbc79e2f950"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Chennai
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/3eef0141ba374e408e3a8a26bfc33c87"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <strong>
                                            Par Formulations Private Limited
                                          </strong>
                                          <br />
                                          <strong>
                                            Par Active Technologies Private
                                            Limited
                                          </strong>
                                          <br />
                                          <strong>
                                            Par Biosciences Private Limited
                                          </strong>
                                          <br />
                                          9/215, Pudupakkam-Vandalur Main Road,
                                          Pudupakkam, Kelambakkam, Chennai,
                                          Tamil Nadu - 603103, India.
                                          <br />
                                          Phone: +91 44 67480000 (Board)
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/faf3e7d8962b4d9382d656fcce2cef0f"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-32d34daa-22e9-4df6-abe6-609ea52461f8"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/f1001483b4fb43989e6f8b2cfde666a0"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-f1001483-b4fb-4398-9e6f-8b2cfde666a0"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Mumbai
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/48dbc723131c43458bec97b6d7043504"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <span>
                                            <strong>
                                              Par Formulations Private Limite
                                            </strong>
                                            d<br />
                                            Plot No. 8-A1/2, Kalwa Block, TTC
                                            Industrial Area, Thane-Belapur Road,
                                            MIDC Digha- Airoli, Navi Mumbai -
                                            400708, Maharashtra, India.
                                            <br />
                                            Phone: +91 22 62791200 (Board)
                                            <br />
                                            CIN: U33111TN1982PTC009602
                                            <br />
                                            GST NO. 27AAACC2463N1ZI
                                            <br />
                                            <a href="mailto:cs.formulations@parpharm.com">
                                              cs.formulations@parpharm.com
                                            </a>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/f0d84b3a34d441abbcbe3cdacf0e3021"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-61794bd4-bd4c-4784-b90e-fe35faa99450"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/3efa57bd96f4443c895bde5325e48155"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-3efa57bd-96f4-443c-895b-de5325e48155"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Dhar
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/dde2d0c273164d5882b1b0dead90bbea"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <strong>
                                            Par Formulations Private Limited
                                          </strong>
                                          <br />
                                          Office/Plant: Plot No. M-82-83,
                                          Industrial Area SEZ PHASE-II,
                                          Pithampur, Dhar, Madhya Pradesh,
                                          454775
                                          <br />
                                          Phone: +91 7292 679100 (Board)
                                          <br />
                                          GST No. 23AAACC2463N1ZQ
                                          <br />
                                          <a href="mailto:cs.formulations@parpharm.com">
                                            cs.formulations@parpharm.com
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/90478960851d4b96ac26c729cf81bbef"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-61f24035-6dbc-4b1f-8b1e-952b441e6047"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/625bdbb024ce492db973d6922bfe4032"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-552a3a1d-6ddb-48be-87ad-80a8715850c1"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/90471e1292e547e5b8cd7170b8206824"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-90471e12-92e5-47e5-b8cd-7170b8206824"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Chennai
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/861b7dc193b543ca849f63c6cb5cd961"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <strong>
                                            Par Formulations Private Limited
                                          </strong>
                                          <br />
                                          Office/Plant: 9/215,
                                          Pudupakkam-Vandalur Main Road,
                                          Pudupakkam, Kelambakkam, Chennai,
                                          Tamil Nadu - 603103, India.
                                          <br />
                                          Phone: +91 44 67480000 (Board)
                                          <br />
                                          CIN: U33111TN1982PTC009602
                                          <br />
                                          GST NO. 33AAACC2463N1ZP <br />
                                          <a href="mailto:cs.formulations@parpharm.com">
                                            cs.formulations@parpharm.com
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/1f8b0b8ecdc14afc9b7861dcded839e6"
                            data-col-span="3"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "3",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-66655b1b-f6e8-42b2-88f1-966582bcc503"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/77ac146af59b4a39ba2c1941677afd75"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h3
                                        id="header-77ac146a-f59b-4a39-ba2c-1941677afd75"
                                        class="block-header font-size-medium text-color-dark-orange m-b-0"
                                      >
                                        Alathur
                                      </h3>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/1c78d985a18e432db346bce14bc3f922"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br">
                                        <p>
                                          <strong>
                                            Par Active Technologies Private
                                            Limited
                                          </strong>
                                          <br />
                                          Plant: Plot No. 16, 17, 31 and 32
                                          Sidco Pharmaceutical Complex, Alathur,
                                          Kanchipuram District - 603110, <br />
                                          Tamil Nadu, India. <br />
                                          Phone: +91 44 67300300
                                          <br />
                                          CIN: U24232TN2013PTC093471
                                          <br />
                                          GST NO. 33AAMCA2552N1ZH
                                          <br />
                                          <a href="mailto:cs.activetechnologies@parpharm.com">
                                            cs.activetechnologies@parpharm.com
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/7438a4ac8aa6461195389d94c24d4cb2"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-1387092c-31f6-4971-bce5-9778a5f148c4"
                              class="block-column p-t-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/e4fff6005ca74e91928bc12450b8f57c"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text mobile-br talign-center">
                                        <p>
                                          If you need to report an adverse
                                          event, please call:
                                        </p>
                                        <p>
                                          <strong>
                                            Branded products: +1-800-462-3636
                                          </strong>
                                          <br />
                                          <strong>
                                            Generic or Sterile products:
                                            +1-800-828-9393
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactLocations;
