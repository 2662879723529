import React, { useState } from "react";

const Newsletter = ({ notify, successNotify }) => {
  const [email, setEmail] = useState("");

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!email) {
      notify("Please fill email address");
      return;
    } else {
      fetch("https://formspree.io/f/xkgwgeog", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email,
          message: "Sign up for newsletter",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setEmail("");
            successNotify("Message sent!!!");
          }
        })
        .catch(() => notify("Failed to send message."));
    }
  };

  return (
    <section id="section-newsletter">
      <div class="global-container">
        <form
          id="form-newsletter"
          aria-label="Sign up for news alerts form"
          data-recap_sitekey="6Le-Xr0pAAAAAI2Kf6BzmFDPKKeNkTDVeNGHnGaY"
          onSubmit={submitHandler}
        >
          <label for="input-newsletter-email">
            Sign up for news alerts for everything Endo.
          </label>

          <div class="fields">
            <input
              type="email"
              id="input-newsletter-email"
              name="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={changeHandler}
            />
            <button type="submit" aria-label="Sign up for newsletter">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.28125 5.74219C0.728965 5.74219 0.28125 6.1899 0.28125 6.74219C0.28125 7.29447 0.728965 7.74219 1.28125 7.74219V5.74219ZM19.2812 5.74219H1.28125V7.74219H19.2812V5.74219Z"
                  fill="#3F4443"
                />
                <path
                  d="M14.2812 11.7422L19.2813 6.74219L14.2812 1.74219"
                  stroke="#3F4443"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Newsletter;
