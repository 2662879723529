import React from "react";

const ByTheNumbers = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/9d6d6eb30c394b8ba2c140dd9b609937"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-9d6d6eb3-0c39-4b8b-a2c1-40dd9b609937"
        class="block-section align-h-center"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/4p3lw1gs/bg-by-the-numbers.webp"
            alt=""
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": 1 }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/2e5fbac677604d74bdcdf0011675cb06"
                  data-col-span="8"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 8,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-af2e4c9f-83bc-40fb-919f-0017e62f69dd"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{
                        "--umb-block-grid--area-grid-columns": 1,
                      }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/741562390e2343c8a5facc0e31142c28"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <p class="block-label talign-center">
                              By the Numbers
                            </p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/4e78ce05d8ed466387da564c337ffc60"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-4e78ce05-d8ed-4663-87da-564c337ffc60"
                              class="block-header font-size-large talign-center m-b-medium-large"
                            >
                              Measuring Our Global Impact
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/7664471c3a4c4bb2beee1a36fe42d806"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="3,000"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">3,000</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Global <br />
                                  Team Members
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/da77948ea9304f16b64f1d65b8b9e9c2"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="2.6"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">2.6</span>
                                <span class="stat-suffix">M</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Prescriptions Dispensed
                                  <br />
                                  Each Month
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/6f6be4d6c2374f8f8e631c32e5179f73"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="180"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">180</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Products Available to
                                  <br />
                                  Patients and Providers
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/c709c865022841379b0060d092b81c28"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="60"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">60</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Products in
                                  <br />
                                  Pipeline
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/3222521892fd4796b6cbb9a397640420"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="1"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">1</span>
                                <span class="stat-suffix">M</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Square Feet of Global
                                  <br />
                                  Manufacturing
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="statBlock"
                            data-content-element-type-key="dcb172ce-fe95-4c85-a99a-feb95e890b43"
                            data-element-udi="umb://element/45261f2a21a74845a37a9d3bc2a25c95"
                            data-col-span="4"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 4,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              class="block-stat has-ds has-rc"
                              data-stat="220"
                              data-auto="auto-false"
                              data-interval="interval-"
                            >
                              <div class="stat">
                                <span class="stat-count">220</span>
                              </div>

                              <div class="stat-label">
                                <p>
                                  Conditions Treated <br />
                                  Through Our Medicines
                                </p>
                              </div>

                              <span class="sep"></span>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/17576c97a5aa4bcea9f5a78aba23d627"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text font-size-xx-small talign-center">
                              <p>Updated April 23, 2024</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ByTheNumbers;
