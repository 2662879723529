import React, { useEffect, useState } from "react";

const SupplierCode = () => {
  // State to keep track of the expanded accordion panel
  const [expandedId, setExpandedId] = useState("");

  const handleAccordionClick = (id) => {
    setExpandedId(expandedId === id ? "" : id);
  };

  // Set the height of the panel based on the state change
  useEffect(() => {
    const panels = document.querySelectorAll(".accordion-panel");
    panels.forEach((panel) => {
      const inner = panel.querySelector(".accordion-panel-inner");
      if (panel.parentElement.dataset.key === expandedId) {
        panel.style.height = `${inner.offsetHeight}px`;
      } else {
        panel.style.height = "0px";
      }
    });
  }, [expandedId]);

  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/76936db6201945b6a397c6fc51fe115e"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-76936db6-2019-45b6-a397-c6fc51fe115e"
        class="block-section align-h-center p-b-x-large p-t-x-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/3fcdd0bb93604fbfa6cba85dc2e38254"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 10,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-d2461532-518f-4107-a9e5-8edd8593e84a"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/ffc54da5814a456db01fb7f188a2e054"
                            data-col-span="5"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 5,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div
                              id="column-fb476c59-b090-479c-8cb6-5823c9a482ad"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": 12,
                                    "--umb-block-grid--area-column-span": 12,
                                    "--umb-block-grid--area-row-span": 1,
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/3983c9130fdd4128ae052f72c2111879"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <h2
                                        id="header-3983c913-0fdd-4128-ae05-2f72c2111879"
                                        class="block-header"
                                      >
                                        Supplier Code of Conduct&#xA0;&#xA0;
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/945584a8bd534b17ba322745f7f6ffcf"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          We recognize the important role
                                          suppliers play in helping us meet our
                                          business objectives. Just as we expect
                                          our team members to adhere to the
                                          employee Code of Conduct, we ask our
                                          suppliers to make a similar commitment
                                          to operate with integrity.
                                        </p>
                                        <p>
                                          All suppliers working with or on
                                          behalf of Endo are expected to share
                                          our commitment to ethically, socially,
                                          and environmentally responsible
                                          operations and abide by the
                                          Pharmaceutical Supply Chain Initiative
                                          (PSCI) guidelines, which serve as the
                                          foundation for our own Supplier Code
                                          of Conduct (“Supplier Code”). We hold
                                          our suppliers accountable and evaluate
                                          suppliers based on the principles
                                          contained in the Supplier Code.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/ebc5b1b8f17b495ebec24ddbbeb3fdb6"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span": 12,
                                        "--umb-block-grid--item-row-span": 1,
                                      }}
                                    >
                                      <div class="block-btn type-text">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/33spptff/suppliercode_02012023v2-june-2024.pdf"
                                          target="_blank"
                                        >
                                          Read the full Supplier Code of Conduct
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d" style={{ width: "70vh" }}>
                            {" "}
                            <div
                              class="umb-block-grid__layout-item"
                              data-content-element-type-alias="accordionBlock"
                              data-content-element-type-key="0c32fb25-ca42-4ca6-985a-e8a7ee7031b4"
                              data-element-udi="umb://element/e118b757e58c4864b20eb781549f5f11"
                              data-col-span="12"
                              data-row-span="1"
                              style={{
                                "--umb-block-grid--item-column-span": "12",
                                "--umb-block-grid--item-row-span": "1",
                              }}
                            >
                              <div
                                className="block-accordion"
                                data-expand="False"
                                data-remove-indent="False"
                                aria-roledescription="accordion"
                              >
                                {accordionData.map((data) => (
                                  <div
                                    key={data.id}
                                    data-key={data.id}
                                    className={`accordion-row ${
                                      data.id === expandedId ? "active" : ""
                                    }`}
                                  >
                                    <h2
                                      aria-expanded={
                                        data.id === expandedId ? "" : "false"
                                      }
                                      tabIndex="0"
                                      className="accordion-header"
                                      onClick={() =>
                                        handleAccordionClick(data.id)
                                      }
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          handleAccordionClick(data.id);
                                        }
                                      }}
                                    >
                                      {data.title}
                                    </h2>
                                    <div className="accordion-panel">
                                      <div className="accordion-panel-inner">
                                        <div
                                          className="umb-block-grid"
                                          data-grid-columns="12"
                                          style={{
                                            "--umb-block-grid--grid-columns":
                                              "12",
                                          }}
                                        >
                                          <div className="umb-block-grid__layout-container">
                                            {data.contents.map(
                                              (content, index) => (
                                                <div
                                                  key={index}
                                                  className="umb-block-grid__layout-item"
                                                  data-content-element-type-alias={
                                                    content.typeAlias
                                                  }
                                                  data-content-element-type-key={
                                                    content.typeKey
                                                  }
                                                  data-element-udi={
                                                    content.elementUdi
                                                  }
                                                  data-col-span="12"
                                                  data-row-span="1"
                                                  style={{
                                                    "--umb-block-grid--item-column-span":
                                                      "12",
                                                    "--umb-block-grid--item-row-span":
                                                      "1",
                                                  }}
                                                >
                                                  <div className="block-btn type-text">
                                                    {content.p ? (
                                                      <p
                                                        className={`${
                                                          content.list &&
                                                          "disc-text"
                                                        } ${
                                                          content.bold &&
                                                          "bold-text"
                                                        } accordian-text-updated`}
                                                      >
                                                        {content.strong && (
                                                          <>
                                                            <strong>
                                                              {content.strong}
                                                            </strong>
                                                            <br />
                                                            <br />
                                                          </>
                                                        )}
                                                        {content.p}
                                                      </p>
                                                    ) : (
                                                      <a
                                                        className={`${
                                                          content.sublink &&
                                                          "sublink"
                                                        } common-btn`}
                                                        href={content.href}
                                                        target={`${
                                                          !content.sublink &&
                                                          !content.self
                                                            ? "_blank"
                                                            : "_self"
                                                        }`}
                                                        rel="noopener noreferrer"
                                                      >
                                                        {content.text}
                                                        {!content.sublink && (
                                                          <div className="arrow-wrap">
                                                            <svg
                                                              width="40"
                                                              height="12"
                                                              viewBox="0 0 40 12"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <path
                                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                                fill="#E87722"
                                                              />
                                                              <path
                                                                d="M33 11L38 6L33 1"
                                                                stroke="#E87722"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                              />
                                                            </svg>
                                                          </div>
                                                        )}
                                                      </a>
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* start from here */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const accordionData = [
  {
    id: "ethics",
    title: "Ethics",
    contents: [
      {
        p: "Suppliers shall conduct their business ethically and act with integrity.",
      },
    ],
  },
  {
    id: "human",
    title: "Human Rights and Labor ",
    contents: [
      {
        p: "Suppliers shall be committed to upholding the human and employment rights of workers and to treat them with dignity and respect. ",
      },
    ],
  },
  {
    id: "health",
    title: "Health and Safety ",
    contents: [
      {
        p: "Suppliers shall provide a safe and healthy working environment for their employees, contractors, and subcontractors. ",
      },
    ],
  },
  {
    id: "environment",
    title: "Environment ",
    contents: [
      {
        p: "Suppliers shall operate in an environmentally responsible and efficient manner to minimize adverse impacts on the environment. ",
      },
    ],
  },
  {
    id: "management",
    title: "Management Systems ",
    contents: [
      {
        p: "Suppliers shall use management systems to maintain business continuity, facilitate continual improvement and compliance with the expectations of these principles. ",
      },
    ],
  },
  {
    id: "responsible",
    title: "Responsible Sourcing ",
    contents: [
      {
        p: "While Endo works to make its own operations more sustainable, we are also proactively engaging our suppliers to inquire how they can make improvements to support our environmental efforts. ",
      },
    ],
  },

  // Add more accordion data here
];

export default SupplierCode;
