import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import "../css/accordion.css";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PoliciesAndStatement = () => {
  // State to keep track of the expanded accordion panel
  const [expandedId, setExpandedId] = useState("");

  const handleAccordionClick = (id) => {
    setExpandedId(expandedId === id ? "" : id);
  };

  // Set the height of the panel based on the state change
  useEffect(() => {
    const panels = document.querySelectorAll(".accordion-panel");
    panels.forEach((panel) => {
      const inner = panel.querySelector(".accordion-panel-inner");
      if (panel.parentElement.dataset.key === expandedId) {
        panel.style.height = `${inner.offsetHeight}px`;
      } else {
        panel.style.height = "0px";
      }
    });
  }, [expandedId]);

  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Policies and Statements | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Policies and Statements" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/policies-and-statements/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <div class="umb-block-grid" data-grid-columns="12;">
          <div class="umb-block-grid__layout-container">
            <div
              class="umb-block-grid__layout-item"
              data-content-element-type-alias="sectionBlock"
              data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
              data-element-udi="umb://element/637bf1460e4f406fabc4510eb272334b"
              data-col-span="12"
              data-row-span="1"
              style={{
                "--umb-block-grid--item-column-span": "12",
                "--umb-block-grid--item-row-span": "1",
              }}
            >
              <section
                id="section-637bf146-0e4f-406f-abc4-510eb272334b"
                class="block-section p-b-large p-t-xx-large ignore-mobile-padding"
              >
                <div class="global-container">
                  <div
                    class="umb-block-grid__area-container"
                    style={{ "--umb-block-grid--area-grid-columns": "1" }}
                  >
                    <div
                      class="umb-block-grid__area"
                      data-area-col-span="12"
                      data-area-row-span="1"
                      data-area-alias="inner"
                      style={{
                        "--umb-block-grid--grid-columns": "12",
                        "--umb-block-grid--area-column-span": "12",
                        "--umb-block-grid--area-row-span": "1",
                      }}
                    >
                      <div class="umb-block-grid__layout-container">
                        <div
                          class="umb-block-grid__layout-item"
                          data-content-element-type-alias="columnBlock"
                          data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                          data-element-udi="umb://element/f04d198393a1403d97b6680b1a8c74a4"
                          data-col-span="12"
                          data-row-span="1"
                          style={{
                            "--umb-block-grid--item-column-span": "12",
                            "--umb-block-grid--item-row-span": "1",
                          }}
                        >
                          <div
                            id="column-3084fad2-2741-4b9a-b90b-9f7a00601704"
                            class="block-column"
                          >
                            <div
                              class="umb-block-grid__area-container"
                              style={{
                                "--umb-block-grid--area-grid-columns": "1",
                              }}
                            >
                              <div
                                class="umb-block-grid__area"
                                data-area-col-span="12"
                                data-area-row-span="1"
                                data-area-alias="inner"
                                style={{
                                  "--umb-block-grid--grid-columns": "12",
                                  "--umb-block-grid--area-column-span": "12",
                                  "--umb-block-grid--area-row-span": "1",
                                }}
                              >
                                <div class="umb-block-grid__layout-container">
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="headerBlock"
                                    data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                    data-element-udi="umb://element/9888366101274f08bd13ba2a5b5b64c6"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <h1
                                      id="header-98883661-0127-4f08-bd13-ba2a5b5b64c6"
                                      class="block-header font-size-x-large"
                                    >
                                      Policies &amp; Statements
                                    </h1>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="richTextBlock"
                                    data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                    data-element-udi="umb://element/535afb7392cc47f8ae1ce2de08985924"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div class="block-rich-text">
                                      <p>
                                        We uphold a set of rigorous policies and
                                        procedures that guide our actions and
                                        decisions. These principles reflect our
                                        commitment to integrity, transparency,
                                        and ethical conduct in all aspects of
                                        our business as we work to improve lives
                                        and advance healthcare.
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    class="umb-block-grid__layout-item"
                                    data-content-element-type-alias="accordionBlock"
                                    data-content-element-type-key="0c32fb25-ca42-4ca6-985a-e8a7ee7031b4"
                                    data-element-udi="umb://element/e118b757e58c4864b20eb781549f5f11"
                                    data-col-span="12"
                                    data-row-span="1"
                                    style={{
                                      "--umb-block-grid--item-column-span":
                                        "12",
                                      "--umb-block-grid--item-row-span": "1",
                                    }}
                                  >
                                    <div
                                      className="block-accordion"
                                      data-expand="False"
                                      data-remove-indent="False"
                                      aria-roledescription="accordion"
                                    >
                                      {accordionData.map((data) => (
                                        <div
                                          key={data.id}
                                          data-key={data.id}
                                          className={`accordion-row ${
                                            data.id === expandedId
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <h2
                                            aria-expanded={
                                              data.id === expandedId
                                                ? ""
                                                : "false"
                                            }
                                            tabIndex="0"
                                            className="accordion-header"
                                            onClick={() =>
                                              handleAccordionClick(data.id)
                                            }
                                            onKeyPress={(e) => {
                                              if (e.key === "Enter") {
                                                handleAccordionClick(data.id);
                                              }
                                            }}
                                          >
                                            {data.title}
                                          </h2>
                                          <div className="accordion-panel">
                                            <div className="accordion-panel-inner">
                                              <div
                                                className="umb-block-grid"
                                                data-grid-columns="12"
                                                style={{
                                                  "--umb-block-grid--grid-columns":
                                                    "12",
                                                }}
                                              >
                                                <div className="umb-block-grid__layout-container">
                                                  {data.contents.map(
                                                    (content, index) => (
                                                      <div
                                                        key={index}
                                                        className="umb-block-grid__layout-item"
                                                        data-content-element-type-alias={
                                                          content.typeAlias
                                                        }
                                                        data-content-element-type-key={
                                                          content.typeKey
                                                        }
                                                        data-element-udi={
                                                          content.elementUdi
                                                        }
                                                        data-col-span="12"
                                                        data-row-span="1"
                                                        style={{
                                                          "--umb-block-grid--item-column-span":
                                                            "12",
                                                          "--umb-block-grid--item-row-span":
                                                            "1",
                                                        }}
                                                      >
                                                        <div className="block-btn type-text">
                                                          {content.p ? (
                                                            <p
                                                              className={`${
                                                                content.list &&
                                                                "disc-text"
                                                              } ${
                                                                content.bold &&
                                                                "bold-text"
                                                              } accordian-text-updated`}
                                                            >
                                                              {content.strong && (
                                                                <>
                                                                  <strong>
                                                                    {
                                                                      content.strong
                                                                    }
                                                                  </strong>
                                                                  <br />
                                                                  <br />
                                                                </>
                                                              )}
                                                              {content.p}
                                                            </p>
                                                          ) : (
                                                            <a
                                                              className={`${
                                                                content.sublink &&
                                                                "sublink"
                                                              } common-btn`}
                                                              href={
                                                                content.href
                                                              }
                                                              target={`${
                                                                !content.sublink &&
                                                                !content.self
                                                                  ? "_blank"
                                                                  : "_self"
                                                              }`}
                                                              rel="noopener noreferrer"
                                                            >
                                                              {content.text}
                                                              {!content.sublink && (
                                                                <div className="arrow-wrap">
                                                                  <svg
                                                                    width="40"
                                                                    height="12"
                                                                    viewBox="0 0 40 12"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                                      fill="#E87722"
                                                                    />
                                                                    <path
                                                                      d="M33 11L38 6L33 1"
                                                                      stroke="#E87722"
                                                                      strokeWidth="2"
                                                                      strokeLinecap="round"
                                                                    />
                                                                  </svg>
                                                                </div>
                                                              )}
                                                            </a>
                                                          )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

const accordionData = [
  {
    id: "code-of-conduct",
    title: "Code of Conduct",
    contents: [
      {
        p: "Endo’s Code of Conduct defines how we interact with patients, healthcare providers, payors, suppliers, government officials, the healthcare community, shareholders and our colleagues. It is designed to enable team members to apply the principles of respect, trust, and integrity to their day-to-day activities. All team members, full-time contractors, and select part-time contractors and vendors are required to complete annual training on the tenets of the Code of Conduct and certify that they will adhere to it. ",
      },
      {
        p: "Endo's full Code of Conduct is located below, available in multiple languages:",
      },
      {
        text: "English",
        href: "https://www.endo.com/media/fynaynye/endo_code_of_conduct_english_050124.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/ed3be6e36e4c4735b7a342354c1c9d23",
      },
      {
        text: "French Canadian",
        href: "https://www.endo.com/media/hidlslxi/endo_code_of_conduct_fr_can_050124.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/7d3a834196f74f45bef869db90e95175",
      },
      {
        text: "Hindi",
        href: "https://www.endo.com/media/inkdwndu/endo_code_of_conduct_hindi_050124.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/44ed15162f064f09a5bffed001bd403c",
      },
      {
        text: "Tamil",
        href: "https://www.endo.com/media/cltleyzc/endo_code_of_conduct_tamil_050124.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/97e5fd1c896e432789c656d09f20b184",
      },
    ],
  },
  {
    id: "advy-lobbying",
    title: "Advocacy & Lobbying ",
    contents: [
      {
        strong: "Endo's Public Policy Engagement",
        p: "Endo’s public policy engagement focuses on building a constructive discourse in the political and regulatory environment to further the Company’s interests, mission and values, and to enhance its ability to meet patient needs. For this reason, the Company actively engages in dialog with policymakers to explain and advocate its perspectives. Endo’s political and lobbying activities (including contributions made by the Endo Political Action Committee (the “Endo PAC”) take place without regard to the personal preferences or affiliations of team members, officers, directors, customers or vendors. Endo’s public policy initiatives are subject to robust oversight, designed to comply with applicable law(s) and exclusively consider the Company’s interests, including trade, healthcare, patient access, regulatory, litigation, intellectual property, competition and other issues directly impacting Endo. Endo’s Code of Conduct outlines the process for team members who engage with government officials on behalf of Endo to influence official action.",
      },
      {
        p: "Endo supports the right of all team members to participate in individual political activity. Team members must engage in such activity on their own time, at their own expense and in accordance with applicable law(s). Team members must also communicate that they are acting independently and not as an Endo representative.",
      },
      {
        p: "Endo’s Board of Directors is apprised of political and lobbying policies, procedures and key initiatives.",
      },
      {
        p: "In accordance with applicable law, Endo exclusively makes political contributions from the Endo PAC, a nonpartisan organization that provides opportunities for team members to participate in the American political process. The Endo PAC makes contributions to candidates and office holders at both the federal and state level and discloses its contributions to the Federal Election Commission (on Form LD-2) and appropriate state authorities. Contributions by team members to the Endo PAC are entirely voluntary. Endo will neither favor nor disfavor team members based on such contributions. ",
      },
    ],
  },
  {
    id: "anti-corruption",
    title: "Anti-Corruption Compliance Policy",
    contents: [
      {
        p: "Endo is committed to conduct business ethically, lawfully and with integrity in the global marketplace. It includes compliance with the U.S. Foreign Corrupt Practices Act 1977 (“FCPA”), the UK Bribery Act (“UKBA”), Prevention of Corruption Act in India and other anti-bribery and anti-corruption laws. While determining whether an activity is covered by these laws can be complicated, the underlying principle at Endo is simple: do not bribe or offer to bribe anyone, or solicit or accept a bribe from anyone, at any time, for any reason whether it’s done directly or through third parties intermediaries. Endo holds its business partners to the same ethical standards.",
      },
      {
        p: "Read Endo’s full Anti-Corruption Compliance Policy below, available in multiple languages:",
      },
      {
        text: "English",
        href: "https://www.endo.com/media/ed3no0py/global-anti-corruption-compliance-policy-v1-final-eng.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/ed3be6e36e4c4735b7a342354c1c9d23",
      },
      {
        text: "French Canadian",
        href: "https://www.endo.com/media/mwmj3mjl/global-anti-corruption-compliance-policy-v1-fr-ca.pdf#",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/7d3a834196f74f45bef869db90e95175",
      },
      {
        text: "Hindi",
        href: "https://www.endo.com/media/l5ajw4lk/global-anti-corruption-compliance-policy-v1-final-hi-in.pdf",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/44ed15162f064f09a5bffed001bd403c",
      },
      {
        text: "Tamil",
        href: "https://www.endo.com/media/0p0jarjz/global-anti-corruption-compliance-policy-v1-final-ta-in.pdf#",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/97e5fd1c896e432789c656d09f20b184",
      },
    ],
  },
  {
    id: "california",
    title: "California Comprehensive Compliance Program Disclosure",
    contents: [
      {
        p: "Endo hereby declares that to the best of our knowledge and based on our good faith understanding of the statutory requirements, we have established a Comprehensive Compliance Program (CCP) for our U.S. based operating companies that is compliant with the requirements set forth by the California Health and Safety Code §§ 119400-119402. By making this declaration, Endo is not asserting that in all circumstances we can prevent individual employees from engaging in conduct that deviates from the standards set forth in the CCP, but we have established procedures to identify potential violations and to address inappropriate conduct as necessary. In addition, Endo assesses its CCP on an ongoing basis and makes enhancements as necessary. A summary of Endo’s Comprehensive Compliance Program and a copy of this declaration can be obtained by calling (800) 462-ENDO (3636).",
      },
    ],
  },
  {
    id: "california-transparency",
    title: "California Transparency in Supply Chains Act Disclosure ",
    contents: [
      {
        p: "The California Transparency in Supply Chains Act of 2010 (SB 657) requires certain companies doing business in the state to provide consumers with information regarding its efforts to address the issues of slavery and human trafficking within their direct supply chain(s).",
      },
      {
        p: "Endo and its affiliates are committed to conducting business in a lawful and ethical manner. We do not tolerate human rights abuses within our own business operations and we expect all of our business partners, including suppliers, to engage in sound human rights practices, to treat workers fairly and with dignity and respect. Endo fully supports the intent of California's law and opposes slavery and human trafficking in all forms.",
      },
      {
        p: "Endo engages in an internal assessment process for evaluating and selecting suppliers. Our contractual agreements include provisions requiring suppliers to comply with all applicable laws and regulations as relates to the products or materials supplied, including labor, employment, slavery and human trafficking laws. Endo conducts audits for compliance with applicable laws and regulations and failure to comply with these requirements are grounds for business relationship termination.",
      },
      {
        p: "Endo has adopted and implemented a Code of Conduct which applies to the company and all of its worldwide subsidiaries and affiliated companies. In connection with the Code of Conduct, Endo seeks to: (i) require the highest standard of business ethics and integrity, (ii) comply with all applicable laws and regulations and (iii) maintain training and other related processes to ensure compliance with the Code. All Endo employees receive annual training and must certify that they will abide by the Code of Conduct.",
      },
    ],
  },
  {
    id: "capital",
    title: "Capital Punishment ",
    contents: [
      {
        p: "At Endo, our vision is to help everyone we serve live their best life. To achieve that vision, every day our global team members are focused on the development and delivery of life-enhancing products. For over 50 years, physicians and hospital pharmacies have relied upon our products to improve the lives of patients. Consequently, we are opposed to the inappropriate use of any of our medicines for capital punishment. We work with our wholesale distribution partners and support controls intended to preclude purchases of our medicines for this inappropriate use. ",
      },
    ],
  },
  {
    id: "clinical-trial",
    title: "Clinical Trial Communication ",
    contents: [
      {
        p: "Endo’s Code of Conduct defines how we interact with patients, healthcare providers, payors, suppliers, government officials, the healthcare community, shareholders and our colleagues. It is designed to enable team members to apply the principles of respect, trust and integrity to their day-to-day activities. All team members, full-time contractors and select part-time contractors and vendors are required to complete annual training on the tenets of the Code of Conduct and certify that they will adhere to it. ",
      },
    ],
  },
  {
    id: "compassionate-use",
    title: "Compassionate Use & Expanded Access of Products ",
    contents: [
      {
        p: "In cases where a clinical trial isn’t an option and the patient has exhausted all available treatment options, regulators may grant permission for us to provide a treating physician with an unapproved drug. Such individual use of an unlicensed investigational drug is often called “compassionate use” or “expanded access” but may go by other names.",
      },
      {
        p: "It's important to remember that investigational drugs have not received regulatory approval; therefore, their potential risks and benefits are not yet established. Doctors and patients should consider all possible risks and benefits when seeking compassionate or expanded access to a product that is unapproved for the condition in which the patient and physician are seeking treatment.",
      },
      {
        p: "While an expanded access program (EAP) is not opened for every investigational medicine or use, our company does not offer EAPs when investigational medicines are in early testing because there are still too many unknowns.",
      },
      {
        p: "To be eligible for access to an investigational or unlicensed product, patients must meet the following criteria:",
      },
      {
        p: "Suffer from a serious or immediately life-threatening disease or condition",
        list: true,
      },
      {
        p: "Have undergone appropriate standard treatments without success, and comparable or satisfactory alternative treatments to diagnose, monitor or treat the disease or condition are not available",
        list: true,
      },
      {
        p: "Are ineligible for participation in any ongoing clinical study of the investigational drug and meet any other pertinent medical criteria for access to the investigational or unlicensed drug, as established by Endo",
        list: true,
      },
      {
        p: "Please feel free to contact us with any questions:",
      },
      {
        p: "(800) 462-ENDO (3636)",
        bold: true,
      },
      {
        text: "Contact Us",
        href: "/company/contact-locations#contact-endo",
        sublink: true,
      },
    ],
  },
  {
    id: "equal-opportunity",
    title: "Equal Opportunity Employment ",
    contents: [
      {
        p: "Our goal is to establish and maintain a work environment free from discrimination and harassment. Endo complies with all applicable federal, state, and local laws prohibiting discrimination on the basis of race, color, religion, sex, sexual orientation, national origin, ancestry, age, disability, genetic information, veteran status, or any other classification protected by federal, state, or local law. The laws prohibiting discrimination apply to all personnel practices, including but not limited to recruitment, hiring, training, promotion, termination, leave of absence, compensation, discipline, evaluation, benefits, transfers, educational assistance, and social and recreational activities.",
      },
    ],
  },
  {
    id: "interactions-with",
    title: "Interactions with Healthcare Professionals",
    contents: [
      {
        p: "Endo team members play an important role in the healthcare system. Our relationships with healthcare professionals are intended to inform them about the benefits and risks of our products to help advance appropriate patient use, provide scientific and educational information, support medical research and education, and obtain feedback and advice about our products through consultation with medical experts. Endo is committed to achieving its business and scientific objectives in an ethical and compliant manner.",
      },
      {
        text: "Read more in our Code of Conduct",
        href: "/company/policies-and-statements/#code-of-conduct",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/97e5fd1c896e432789c656d09f20b184",
        self: true,
      },
    ],
  },
  {
    id: "medical-machine",
    title: "Medical Machine Readable File",
    contents: [
      {
        p: "Following the requirements of the federal “Transparency in Coverage” rule, Endo displays pricing data for covered items and services based on in-network negotiated payment rates and historical out-of-network allowed amounts. The Machine Readable File is intended to be analyzed by third parties to conduct research and develop products to help consumers better understand the costs associated with their healthcare and evaluate their healthcare coverage options.",
      },
      {
        text: "Medical Machine File",
        href: "https://health1.aetna.com/app/public/#/one/insurerCode=AETNACVS_I&brandCode=ALICSI/machine-readable-transparency-in-coverage?reportingEntityType=Third%20Party%20Administrator_96144483&lock=true",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/97e5fd1c896e432789c656d09f20b184",
      },
    ],
  },
  {
    id: "prescription-data",
    title: "Prescription Data Restriction",
    contents: [
      {
        p: "Endo participates in the American Medical Association (AMA) Prescription Data Restriction Program (PDRP) in order to comply with state laws regarding the use of their prescription data as well as the requests of healthcare professionals who choose for their prescription data to be restricted. The PDRP allows healthcare providers the opportunity to restrict their prescribing data from pharmaceutical sales representatives and/or not be used for marketing purposes. Healthcare providers may request to have their prescription data restricted by registering at American Medical Association's Physician Data Restriction Program Website. ",
      },
    ],
  },
  {
    id: "supplier-diversity",
    title: "Supplier Diversity Policy",
    contents: [
      {
        p: "It is Endo's policy to offer small and diverse suppliers an opportunity to compete for our business. Endo thoroughly reviews all opportunities to include small and diverse business entities consistent with good procurement practices. We encourage all small and diverse businesses to make their status known by completing our Supplier Registration Profile found on the Supplier Registration page. This is an equal opportunity policy and does not discriminate towards race, ethnicity, sexual orientation, gender identity or expression, religion, national origin, color, personal affinity, disability, age, or any other protected characteristic.",
      },
      {
        text: "Supplier Registration",
        href: "/",
        typeAlias: "buttonBlock",
        typeKey: "fab5bcbc-1b00-4e39-a3e9-e652c768f6f7",
        elementUdi: "umb://element/97e5fd1c896e432789c656d09f20b184",
      },
    ],
  },
  {
    id: "voluntary-operating",
    title: "Voluntary Operating Injunction Related Opioid Medications",
    contents: [
      {
        p: "Since 2016, we have taken a number of voluntary steps to help address the opioid abuse crisis including: ceasing promotion of opioid medications, eliminating the Company’s U.S. pain salesforce, withdrawing Opana® ER from the market, discontinuing the sale of numerous other opioid medications, discontinuing research and development on new opioid products, and continuing to enhance our program to monitor sales of opioid medications to help reduce the risk of diversion. We also continue to maintain a robust corporate compliance program.",
      },
      {
        p: "Additionally, in 2022, Endo agreed to a Voluntary Operating Injunction (the VOI) governing Endo’s remaining U.S. opioid business. We voluntarily agreed to these terms, including the appointment of a corporate monitor who oversaw, reported on and made recommendations regarding Endo’s compliance with the VOI through April 23, 2024. The monitor’s reports are available on this page.",
      },
      {
        text: "Initial Independent Monitor’s Report - March 16, 2023",
        href: "https://www.endo.com/media/l40dlo4o/initial_monitor_report-3-16-23.pdf",
        sublink: true,
      },
      {
        text: "Final Independent Monitor’s Report - April 19, 2024",
        href: "https://www.endo.com/media/ll4bhtxf/endo-dkt-4199-final-monitor-report.pdf",
        sublink: true,
      },
    ],
  },
  // Add more accordion data here
];

export default PoliciesAndStatement;
