import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MathewJMaletta = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Matthew J. Maletta | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Matthew J. Maletta" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/matthew-j-maletta/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />

        <link rel="stylesheet" href="/css/team.min.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Matthew J. Maletta</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/vu2h4p4x/matthew-maletta.webp"
                  alt="Matthew J. Maletta"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Matthew J. Maletta</h1>

              <p class="team-position">
                Executive Vice President, Chief Legal Officer and Secretary
              </p>

              <div class="team-bio">
                <p>
                  Matthew J. Maletta has served as Executive Vice President and
                  Chief Legal Officer of Endo International plc since May 2015
                  and as Company Secretary of Endo International plc since June
                  2020, and continues to serve in those roles for Endo. Mr.
                  Maletta has global responsibility for all legal matters
                  affecting the company.
                </p>
                <p>
                  Prior to joining Endo International plc in 2015, Mr. Maletta
                  served as Vice President, Associate General Counsel and
                  Corporate Secretary of Allergan. In this position, he served
                  as an advisor to the Chief Executive Officer and Board of
                  Directors and supervised several large transactions, including
                  the $70 billion acquisition of Allergan by Actavis in 2015.
                  Mr. Maletta also played a key role defending Allergan from an
                  unsolicited takeover bid by Valeant Pharmaceuticals and
                  Pershing Square Capital Management in 2014. Mr. Maletta joined
                  Allergan in 2002 and, during his tenure, held roles of
                  increased responsibility, including serving as the lead
                  commercial attorney for Allergan’s aesthetics businesses for
                  several years and as Head of Human Resources in 2010. Prior to
                  joining Allergan, Mr. Maletta was in private practice,
                  focusing on general corporate matters, finance, governance,
                  securities, and transactions.
                </p>
                <p>
                  He holds a Bachelor of Arts degree in political science from
                  the University of Minnesota, summa cum laude and Phi Beta
                  Kappa, and a Juris Doctor degree, cum laude, from the
                  University of Minnesota Law School.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default MathewJMaletta;
