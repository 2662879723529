import React from "react";

const Resources = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/a701072f13094b478f02d74128bbc68b"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": 12,
        "--umb-block-grid--item-row-span": 1,
      }}
    >
      <section
        id="section-a701072f-1309-4b47-8f02-d74128bbc68b"
        class="block-section p-t-xx-large ignore-mobile-padding"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": 12,
                "--umb-block-grid--area-column-span": 12,
                "--umb-block-grid--area-row-span": 1,
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/b2dd320a770e43e9a38044cff9d588eb"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-e01ae2fd-c5cc-49b3-ad63-d3a594f9d734"
                    class="block-column p-b-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/7b6606e344c548a0a552b75593315bbb"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h1
                              id="header-7b6606e3-44c5-48a0-a552-b75593315bbb"
                              class="block-header font-size-x-large"
                            >
                              Media Resources
                            </h1>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/255f2d9d41324903b5e032e0599fb1d8"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                Explore our recent news releases to stay updated
                                on Endo's innovations, advancements, and
                                contributions in the healthcare sector.
                              </p>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="separatorBlock"
                            data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                            data-element-udi="umb://element/9c32791e82054e9e9891f3fad336eb4c"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-separator m-t-large"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/29c2803caf564f7f8c99d5f8a92d75d9"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-fee8a18d-ebe6-4f04-8207-816741aa5645"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/b6d8bb8bf39e46a09e60cee735a4b694"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-b6d8bb8b-f39e-46a0-9e60-cee735a4b694"
                              class="block-header font-size-large m-b-medium-large"
                            >
                              Press Releases
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="pressReleaseBlock"
                            data-content-element-type-key="d49f7035-f9c5-4d2e-93a9-3a1ecad8abdd"
                            data-element-udi="umb://element/dcfec777cf0a4c7da59520730b94a016"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-press-release type-tiles m-b-large">
                              <div id="press-output" class="vs-block-grid">
                                <a
                                  href="/investor/2024-06-28-Endo-Announces-Commencement-of-OTCQX-Trading"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Fri, 28 Jun 2024 07:05:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      June 28, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo Announces Commencement of OTCQX Trading
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-06-10-Endo-Launches-New-Dupuytrens-Contracture-Campaign-Reminders"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Mon, 10 Jun 2024 07:30:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      June 10, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo Launches New Dupuytren&#x27;s
                                    Contracture Campaign: Reminders
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-05-30-Endo-Provides-Select-First-Quarter-2024-Financial-Results-for-Endo-International-plc"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Thu, 30 May 2024 16:30:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      May 30, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo Provides Select First-Quarter 2024
                                    Financial Results for Endo International plc
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-05-15-Endo-to-Announce-First-Quarter-2024-Financial-Results-for-Endo-International-plc"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Wed, 15 May 2024 16:45:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      May 15, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo to Announce First-Quarter 2024
                                    Financial Results for Endo International plc
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-05-07-Endo-Announces-New-Peyronies-Disease-Data-Presented-at-the-American-Urological-Association-Annual-Meeting"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Tue, 07 May 2024 07:30:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      May 7, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo Announces New Peyronie&#x27;s Disease
                                    Data Presented at the American Urological
                                    Association Annual Meeting
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-05-06-Endo-Launches-Enhanced-TruDelivery-R-Ready-to-Use-Product-Line-and-Platform"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Mon, 06 May 2024 07:45:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      May 6, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo Launches Enhanced TruDelivery&#xAE;
                                    Ready-to-Use Product Line and Platform
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-04-23-Endo,-Inc-Completes-Acquisition-of-Endo-International-plcs-Assets"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Tue, 23 Apr 2024 16:48:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      April 23, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Endo, Inc. Completes Acquisition of Endo
                                    International plc&#x27;s Assets
                                  </p>
                                </a>
                                <a
                                  href="/investor/2024-04-22-Par-Pharmaceutical-Issues-Voluntary-Nationwide-Recall-of-Seven-Lots-of-Treprostinil-Injection-Due-to-Potential-for-Silicone-Particulates-in-the-Product-Solution"
                                  target="_blank"
                                  class="grid-tile"
                                >
                                  <p class="press-date" itemprop="dateline">
                                    <time
                                      datetime="Mon, 22 Apr 2024 07:56:00 -0400"
                                      itemprop="datePublished"
                                    >
                                      April 22, 2024
                                    </time>
                                  </p>
                                  <p class="press-headline">
                                    Par Pharmaceutical Issues Voluntary
                                    Nationwide Recall of Seven Lots of
                                    Treprostinil Injection Due to Potential for
                                    Silicone Particulates in the Product
                                    Solution
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="separatorBlock"
                  data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                  data-element-udi="umb://element/a34cc827b7b74fd4bd4cc2c801996ea1"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div class="block-separator type-full-width m-b-large m-t-large"></div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/fb798cbbe0354f4796f88102a585fa92"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 6,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-bc757878-99ab-493e-b571-42de9fe89b5f"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/ddd9e34b4a1c46b6be05d666548839e5"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <h2
                              id="header-ddd9e34b-4a1c-46b6-be05-d666548839e5"
                              class="block-header"
                            >
                              Visual Assets
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/1d92e1054c344c2b9c44adab4f7f0ad7"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-rich-text">
                              <p>
                                This collection of media assets is intended for
                                use by media professionals. The images may not
                                be modified.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/fb3b21e545b343dd9c0fc5e3e2f2cc15"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": 12,
                    "--umb-block-grid--item-row-span": 1,
                  }}
                >
                  <div
                    id="column-f9a6d0dc-7894-4ed4-b41f-fc2b803fc089"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": 12,
                          "--umb-block-grid--area-column-span": 12,
                          "--umb-block-grid--area-row-span": 1,
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="visualAssetsBlock"
                            data-content-element-type-key="b6052ee1-872f-490c-942b-46b98be7afe9"
                            data-element-udi="umb://element/ecbe4c2b1c42444cb4f81961a31c198d"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": 12,
                              "--umb-block-grid--item-row-span": 1,
                            }}
                          >
                            <div class="block-assets">
                              <div id="asset-grid">
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/bibjt1tf/endo-logo-4x.png"
                                  >
                                    <figure class="asset-thumbnail no-crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/bibjt1tf/endo-logo-4x.png"
                                        alt="Endo logo"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Endo Logo</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/2s5dgykd/endo_malvern_two.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/2s5dgykd/endo_malvern_two.jpg?width=620&amp;height=620"
                                        alt="Exterior of the Endo Malvern location"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Endo Malvern</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/vnohxpig/endo_malvern_three.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/vnohxpig/endo_malvern_three.jpg?width=620&amp;height=620"
                                        alt="Endo logo inside the Malvern building"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Endo Malvern</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/esqfb0ia/endo_malvern_1.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/esqfb0ia/endo_malvern_1.jpg?cc=0,0,0.3333333333333334,0&amp;width=620&amp;height=620"
                                        alt="Exterior of the Endo Malvern location"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Endo Malvern</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/abpl4rs4/horsham-building-1.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/abpl4rs4/horsham-building-1.jpg?cc=0.07073675630691738,0,0.3665343059641449,0&amp;width=620&amp;height=620"
                                        alt="Exterior of the Endo Horsham location"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Horsham Building</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://endocorporate.blob.core.windows.net/video/Endo_b-roll.mp4"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/1b0jbyae/endo_malvern_two-5.jpg?cc=0.054080154999015494,0.20383131528964865,0.5965331720032004,0.27208867521367525&amp;width=620&amp;height=620"
                                        alt="Alt text"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">B-Roll</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/w1kpimeo/blaise-coleman-4x.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/w1kpimeo/blaise-coleman-4x.jpg?width=620&amp;height=620"
                                        alt="Blaise Coleman"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Blaise Coleman</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/raghlgvu/patrick-barry-4x.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/raghlgvu/patrick-barry-4x.jpg?width=620&amp;height=620"
                                        alt="Patrick Barry"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Patrick Barry</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                                <div class="asset">
                                  <a
                                    class="asset-link"
                                    download
                                    href="https://www.endo.com/media/jm3jjf1e/tracy-basso-4x.jpg"
                                  >
                                    <figure class="asset-thumbnail crop">
                                      <img
                                        loading="lazy"
                                        src="https://www.endo.com/media/jm3jjf1e/tracy-basso-4x.jpg?width=620&amp;height=620"
                                        alt="Tracy Basso"
                                        width="620"
                                        height="620"
                                      />
                                    </figure>
                                    <h3 class="asset-name">Tracy Basso</h3>
                                    <div class="block-btn type-hollow">
                                      <span class="common-btn">Download</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Resources;
