import React from "react";

const Efforts = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/5ddf83e3b6784ecd896a3ac91095f655"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-5ddf83e3-b678-4ecd-896a-3ac91095f655"
        class="block-section align-h-center background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/b6817582f49448139f68acb122358e36"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-43b2560a-e569-44db-a197-a7274c36b26b"
                    class="block-column align-v-center"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/f9ac6bf1566a4ab6ae0446513f8c0fcb"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-525e4768-993b-4948-81ed-8ddb553668d0"
                              class="block-column p-r-x-large"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/c0c4b006c89441f6a7abe70410ec16a0"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label">
                                        Efforts and Progress
                                      </p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/10ddb862d9e34f7fa5978eeac2c9141b"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-10ddb862-d9e3-4f7f-a597-8eeac2c9141b"
                                        class="block-header font-size-large"
                                      >
                                        Corporate Responsibility Reports
                                      </h2>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/1fcdccf18fa949558d2546d88249f79b"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          Our Corporate Responsibility Report
                                          details our efforts and progress
                                          across four pillars—our business, our
                                          team, our customers, and our world.
                                          Learn more by reading the latest
                                          report.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9c6df07a0c0a4c3c921a701d1dcf7824"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-7e87d0f9-d447-41e0-bb4d-1de10b322427"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/de7a1a3afa4a404c8e5d1ff2042154b5"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text font-size-medium">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/k44hgnih/endo_2022_cr_report_05-01-23.pdf"
                                          target="_blank"
                                        >
                                          2022 Corporate Responsibility Report
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/15b7e816c4f5402cb6fc320a99e76c63"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator type-full-width"></div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/bb55ae2b2ed64e748f50c3142534a1e8"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text font-size-small">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/vlvpkzyi/endo_2021_cr_report_4-26-22.pdf"
                                          target="_blank"
                                        >
                                          2021 Corporate Responsibility Report
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/cec8b109874b45cf86c17c2074fe9d31"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text font-size-small">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/dlvjyla4/endo_2020_csr_0503.pdf"
                                          target="_blank"
                                        >
                                          2020 Corporate Responsibility Report
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/c62b5fdf7d274052a78c5f2d72dfcad5"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn type-text font-size-small">
                                        <a
                                          class="common-btn"
                                          href="https://www.endo.com/media/zdokn1pw/endo_corpresp_report_final2-03-24-21.pdf"
                                          target="_blank"
                                        >
                                          2019 Corporate Responsibility Report
                                          <div class="arrow-wrap">
                                            <svg
                                              width="40"
                                              height="12"
                                              viewBox="0 0 40 12"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM38 5H1V7H38V5Z"
                                                fill="#E87722"
                                              />
                                              <path
                                                d="M33 11L38 6L33 1"
                                                stroke="#E87722"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                              />
                                            </svg>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Efforts;
