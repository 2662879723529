import React, { useState } from "react";

const SignUpAlerts = ({ notify, successNotify }) => {
  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!formState.firstName || !formState.lastName || !formState.email) {
      notify("Please fill all the fields");
      return;
    } else {
      fetch("https://formspree.io/f/xkgwgeog", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          name: `${formState.firstName} ${formState.lastName}`,
          email: formState.email,
          purpose: "Sign up for Investor Alerts",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            setFormState({
              ...formState,
              firstName: "",
              lastName: "",
              email: "",
              comment: "",
              department: "",
            });
            successNotify("Message sent!!!");
          }
        })
        .catch(() => notify("Failed to send message."));
    }
  };
  return (
    <div class="subscribe-wrapper">
      <div class="wd_featureboxes_subscribe wd_featureboxes-vertical">
        <div
          id="wd_featurebox-subscribe_2"
          class="wd_featurebox_container wd_featurebox-subscribe"
        >
          <div class="wd_featurebox_title">Sign up for Investor Alerts</div>
          <div class="wd_featurebox">
            <div class="wd_subscribe_featurebox">
              <div class="wd_intro">
                <p>
                  Receive company financial news and updates in your email.
                  Click on the confirmation email to select your preferences.
                  For questions, contact{" "}
                  <a href="mailto:info@endotherapeutics.us">
                    info@endotherapeutics.us
                  </a>
                  .
                </p>
              </div>
              <form
                method="POST"
                class="wd_formbuilder"
                id="subscribe_form_2"
                name="formbuilderform"
                action="https://investor.endo.com/overview?submitted=1"
                enctype="multipart/form-data"
                onSubmit={submitHandler}
              >
                <div class="wd_form_header">
                  <div class="wd_form_legend">* Required Fields</div>
                </div>
                <div class="wd_form_field wd_form_field_pos_right">
                  <div class="wd_form_field_label">
                    <label for="formbuilderform_subscribe_2[first_name]">
                      *&nbsp;First Name
                    </label>
                  </div>
                  <div class="wd_form_field_input">
                    <input
                      type="text"
                      onChange={changeHandler}
                      value={formState.firstName}
                      name="firstName"
                      size="16"
                      id="formbuilderform_subscribe_2[first_name]"
                    />
                  </div>
                </div>
                <div class="wd_form_field wd_form_field_pos_right">
                  <div class="wd_form_field_label">
                    <label for="formbuilderform_subscribe_2[last_name]">
                      *&nbsp;Last Name
                    </label>
                  </div>
                  <div class="wd_form_field_input">
                    <input
                      type="text"
                      name="lastName"
                      value={formState.lastName}
                      onChange={changeHandler}
                      size="16"
                      id="formbuilderform_subscribe_2[last_name]"
                    />
                  </div>
                </div>
                <div class="wd_form_field wd_form_field_pos_right">
                  <div class="wd_form_field_label">
                    <label for="formbuilderform_subscribe_2[email]">
                      *&nbsp;Email
                    </label>
                  </div>
                  <div class="wd_form_field_input">
                    <input
                      type="text"
                      name="email"
                      value={formState.email}
                      onChange={changeHandler}
                      size="16"
                      id="formbuilderform_subscribe_2[email]"
                    />
                  </div>
                </div>
                <div class="wd_form_footer">
                  <div class="wd_form_buttons">
                    <input
                      type="submit"
                      class="wd_formbuilder_submit"
                      name="Submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpAlerts;
