import React from "react";

const EmployeeQuote = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/9fdf551b11be40f7a47934b524e10955"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-9fdf551b-11be-40f7-a479-34b524e10955"
        class="block-section align-h-center p-b-0 p-t-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/b8be68187b3d4c6384afd6c5cb97b093"
                  data-col-span="12"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "12",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-25f4e4e9-bca3-497e-bb01-71a1fd20a64f"
                    class="block-column has-rc align-h-center has-bg-color background-color-light-gray p-b-x-large p-t-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/9570c3e7c38e46ba96a7d06e79b8696a"
                            data-col-span="8"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "8",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-d33db76d-3263-4551-8eed-df260942a090"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/08fec5cf306e4728a79a05b9d001d961"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text font-size-x-large font-weight-medium">
                                        <p style={{ "text-align": "center" }}>
                                          Endo team members are part of a <br />
                                          dedicated community that values
                                          <br />
                                          well-being and growth.
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/b471241b7e61457f9f4a7e7f455f3b25"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator type-default align-h-center m-t-small"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmployeeQuote;
