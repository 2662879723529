import React from "react";

const FraudAlert = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/181210e5cbbb462896a82583f2c4a459"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-181210e5-cbbb-4628-96a8-2583f2c4a459"
        class="block-section align-h-center p-b-large p-t-medium"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/5657fca49d2643d992965392a6862f04"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-4461babb-e933-4d57-9566-edbe95160d58"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/98093c828e9944d68857ee9621ea2183"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label talign-center">
                              Recruitment Fraud Alert&#xA0;
                            </p>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/9ab61644c99a4018b08ed668f945e4fc"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-9ab61644-c99a-4018-b08e-d668f945e4fc"
                              class="block-header font-size-large talign-center"
                            >
                              Beware of fraudulent recruiters falsely claiming
                              to represent Endo.
                            </h2>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/5e96622dc391497c9894e4949e3d3c4a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text">
                              <p style={{ "text-align": "center" }}>
                                We never send payments for equipment or request
                                personal financial information during
                                recruitment.
                              </p>
                              <p style={{ "text-align": "center" }}>
                                If you believe you have been contacted by an
                                unauthorized person falsely posing as an Endo
                                representative, please report the activity to
                                law enforcement and/or the Federal Trade
                                Commission by visiting{" "}
                                <a
                                  rel="noopener"
                                  href="https://reportfraud.ftc.gov"
                                  target="_blank"
                                >
                                  reportfraud.ftc.gov
                                </a>
                                .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FraudAlert;
