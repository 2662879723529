import React from "react";

const Creating = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/395b854149c44f79a689386c90ebe3ac"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-395b8541-49c4-4f79-a689-386c90ebe3ac"
        class="block-section align-h-center p-b-x-large p-t-x-large"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/5f2a30b9bbdf4b489d5bf209382c31fa"
                  data-col-span="10"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "10",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-79338c75-8966-4524-81d5-51585cf0381d"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/8dddd0977cd345098fc8e5f75f8607d0"
                            data-col-span="6"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "6",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-445ac3ee-623b-4327-bfeb-5765da4911cc"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="labelBlock"
                                      data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                                      data-element-udi="umb://element/6a0fa78da32943b7a831357dc5ea22d1"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <p class="block-label">
                                        Creating a Brighter Tomorrow
                                      </p>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="headerBlock"
                                      data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                                      data-element-udi="umb://element/537c5c85d6054f76936e8829189a55bf"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <h2
                                        id="header-537c5c85-d605-4f76-936e-8829189a55bf"
                                        class="block-header font-size-x-large"
                                      >
                                        Leading with Purpose, Making Progress
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/33d080f7e5de48709c43836226478859"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-2970f78c-ccfd-44da-a1c0-1bfa043d6177"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/b5ba1f7c123d4d809967ba9401175504"
                                      data-col-span="6"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "6",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div
                                        id="column-2f6feafe-706d-4e10-bd91-3a71c2ea2fc9"
                                        class="block-column"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns":
                                                "12",
                                              "--umb-block-grid--area-column-span":
                                                "12",
                                              "--umb-block-grid--area-row-span":
                                                "1",
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="richTextBlock"
                                                data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                data-element-udi="umb://element/a37d75dba8134edcba044608a376e87d"
                                                data-col-span="12"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span":
                                                    "12",
                                                  "--umb-block-grid--item-row-span":
                                                    "1",
                                                }}
                                              >
                                                <div class="block-rich-text">
                                                  <p>
                                                    We strive to help everyone
                                                    we serve live their best
                                                    life, and that vision calls
                                                    on us to be a force for good
                                                    in our business practices,
                                                    for our team, for our
                                                    customers, and for our
                                                    world.
                                                  </p>
                                                  <p>
                                                    This strategy fortifies
                                                    Endo’s long-term
                                                    sustainability by addressing
                                                    all our stakeholders’ needs.
                                                    From our efforts to
                                                    reinforce diversity, equity,
                                                    and inclusion in the
                                                    workplace; to our Culture of
                                                    Excellence quality
                                                    improvement programming; to
                                                    our work to reduce our
                                                    environmental footprint—in
                                                    every case, we know that
                                                    continuing to move these
                                                    initiatives forward
                                                    strengthens our company.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="columnBlock"
                                      data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                                      data-element-udi="umb://element/3f068793a8a54f8aaa9950baa391e7f6"
                                      data-col-span="6"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "6",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div
                                        id="column-fabaa541-305e-4848-9d98-2777c1a9eef2"
                                        class="block-column"
                                      >
                                        <div
                                          class="umb-block-grid__area-container"
                                          style={{
                                            "--umb-block-grid--area-grid-columns":
                                              "1",
                                          }}
                                        >
                                          <div
                                            class="umb-block-grid__area"
                                            data-area-col-span="12"
                                            data-area-row-span="1"
                                            data-area-alias="inner"
                                            style={{
                                              "--umb-block-grid--grid-columns":
                                                "12",
                                              "--umb-block-grid--area-column-span":
                                                "12",
                                              "--umb-block-grid--area-row-span":
                                                "1",
                                            }}
                                          >
                                            <div class="umb-block-grid__layout-container">
                                              <div
                                                class="umb-block-grid__layout-item"
                                                data-content-element-type-alias="richTextBlock"
                                                data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                                data-element-udi="umb://element/bcf3a124b61a4734a8f1bf4abca0a92b"
                                                data-col-span="12"
                                                data-row-span="1"
                                                style={{
                                                  "--umb-block-grid--item-column-span":
                                                    "12",
                                                  "--umb-block-grid--item-row-span":
                                                    "1",
                                                }}
                                              >
                                                <div class="block-rich-text">
                                                  <p>
                                                    While we remain agile so we
                                                    can evolve in a changing
                                                    world, there are certain
                                                    elements that remain
                                                    foundational to our success.
                                                    We cherish our culture,
                                                    which empowers and engages
                                                    team members so that they
                                                    feel ownership and
                                                    accountability. Our
                                                    unwavering commitment to
                                                    customers and patients
                                                    remains core to who we are.
                                                  </p>
                                                  <p>
                                                    <span
                                                      style={{
                                                        color: "#c15401",
                                                      }}
                                                    >
                                                      <strong>
                                                        And we will always pride
                                                        ourselves on being a
                                                        force for good.
                                                      </strong>
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/d39375e52cde48e1966da11e1a934c78"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-c417b959-3f4f-4371-8c49-0561031367b2"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  "--umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="separatorBlock"
                                      data-content-element-type-key="32384c3f-6dcc-44e6-8f84-23469f00d78f"
                                      data-element-udi="umb://element/2c62a6be47c84808be67704657ae0284"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-separator"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Creating;
