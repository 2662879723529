import React from "react";

const Exclusive = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/2146c7793b3640db979408c2628f5da5"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-2146c779-3b36-40db-9794-08c2628f5da5"
        class="block-section p-b-xxx-large p-t-xxx-large"
      >
        <picture class="section-image">
          <img
            class="bg-image"
            loading="lazy"
            src="https://www.endo.com/media/kufjvq5q/bg-dei.webp"
            alt=""
            width="0"
            height="0"
          />
        </picture>

        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/4163424dd8984abcb8df40f64403e7ea"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-a156e51d-7bb0-42b7-98ca-b4e7fc346f45"
                    class="block-column has-rc has-bg-color background-color-white p-l-x-large p-r-x-large p-b-large p-t-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          {/* here */}
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="labelBlock"
                            data-content-element-type-key="16beea64-2302-48a2-8328-41c3a58fd7ed"
                            data-element-udi="umb://element/c9e923d2fee7488884930689212074f1"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <p class="block-label">
                              Diversity, Equity, &amp; Inclusion
                            </p>
                          </div>
                          {/* here */}
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="headerBlock"
                            data-content-element-type-key="861b8e09-093a-41f7-84f8-6e8f0f8a5713"
                            data-element-udi="umb://element/3b3cdcbc5d4c4b21ba538e9e41ba3c80"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <h2
                              id="header-3b3cdcbc-5d4c-4b21-ba53-8e9e41ba3c80"
                              class="block-header"
                            >
                              Supporting an Inclusive Workplace
                            </h2>
                          </div>
                          {/* her */}
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="columnBlock"
                            data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                            data-element-udi="umb://element/ec4215dc8dfc40098a16d9eaf586023a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div
                              id="column-a576fbd2-98f5-4c03-b769-2a5705d0fa39"
                              class="block-column"
                            >
                              <div
                                class="umb-block-grid__area-container"
                                style={{
                                  " --umb-block-grid--area-grid-columns": "1",
                                }}
                              >
                                <div
                                  class="umb-block-grid__area"
                                  data-area-col-span="12"
                                  data-area-row-span="1"
                                  data-area-alias="inner"
                                  style={{
                                    "--umb-block-grid--grid-columns": "12",
                                    "--umb-block-grid--area-column-span": "12",
                                    "--umb-block-grid--area-row-span": "1",
                                  }}
                                >
                                  <div class="umb-block-grid__layout-container">
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="richTextBlock"
                                      data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                                      data-element-udi="umb://element/59722e67454e464394f8f3f0f9b03a1f"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-rich-text">
                                        <p>
                                          <span>
                                            Our culture draws power from our
                                            team’s diverse backgrounds,
                                            experiences, and perspectives. We
                                            embrace and celebrate the unique
                                            voices of the Endo team and foster
                                            an environment where everyone feels
                                            welcome and valued. That inclusion
                                            creates a sense of belonging and
                                            empowers each person to be their
                                            best self.
                                          </span>
                                        </p>
                                        <p>
                                          <span>
                                            Learn more about our employee
                                            resource groups (ERGs), cultural
                                            awareness initiatives, equity
                                            measures, and unwavering commitment
                                            to DEI in our Corporate
                                            Responsibility Reports. 
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      class="umb-block-grid__layout-item"
                                      data-content-element-type-alias="buttonBlock"
                                      data-content-element-type-key="fab5bcbc-1b00-4e39-a3e9-e652c768f6f7"
                                      data-element-udi="umb://element/dcfb57c473674bc2b45379ffe7e4cbbb"
                                      data-col-span="12"
                                      data-row-span="1"
                                      style={{
                                        "--umb-block-grid--item-column-span":
                                          "12",
                                        "--umb-block-grid--item-row-span": "1",
                                      }}
                                    >
                                      <div class="block-btn">
                                        <a
                                          class="common-btn"
                                          href="/responsibility/progress-and-reports/"
                                        >
                                          Progress &amp; Reports
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Exclusive;
