import React from "react";

const EnvironmentQuote = () => {
  return (
    <div
      class="umb-block-grid__layout-item"
      data-content-element-type-alias="sectionBlock"
      data-content-element-type-key="39d0eaa5-bc52-4f47-aecc-e5f43ed76574"
      data-element-udi="umb://element/d22b22639fca461491cab5f31ab9deee"
      data-col-span="12"
      data-row-span="1"
      style={{
        "--umb-block-grid--item-column-span": "12",
        "--umb-block-grid--item-row-span": "1",
      }}
    >
      <section
        id="section-d22b2263-9fca-4614-91ca-b5f31ab9deee"
        class="block-section text-color-light-gray background-color-light-gray"
      >
        <div class="global-container">
          <div
            class="umb-block-grid__area-container"
            style={{ "--umb-block-grid--area-grid-columns": "1" }}
          >
            <div
              class="umb-block-grid__area"
              data-area-col-span="12"
              data-area-row-span="1"
              data-area-alias="inner"
              style={{
                "--umb-block-grid--grid-columns": "12",
                "--umb-block-grid--area-column-span": "12",
                "--umb-block-grid--area-row-span": "1",
              }}
            >
              <div class="umb-block-grid__layout-container">
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/9557367550754e9a9f0748d7be6c3181"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-a246484b-6475-48b7-ada8-42d880c2a6c5"
                    class="block-column has-border-color b-l-gray-20 p-l-x-large p-r-x-large"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/981272c056c24caca5266f116df59c00"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-x-large font-weight-medium">
                              <p>
                                We are dedicated to operating our business
                                sustainably.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="umb-block-grid__layout-item"
                  data-content-element-type-alias="columnBlock"
                  data-content-element-type-key="6c419fa5-5f49-4c24-b676-6ec8e10fd6c4"
                  data-element-udi="umb://element/1f237c4a71284077bc4486bba17e4275"
                  data-col-span="6"
                  data-row-span="1"
                  style={{
                    "--umb-block-grid--item-column-span": "6",
                    "--umb-block-grid--item-row-span": "1",
                  }}
                >
                  <div
                    id="column-6e6d49f2-c929-4066-93e0-37e152904bf7"
                    class="block-column"
                  >
                    <div
                      class="umb-block-grid__area-container"
                      style={{ "--umb-block-grid--area-grid-columns": "1" }}
                    >
                      <div
                        class="umb-block-grid__area"
                        data-area-col-span="12"
                        data-area-row-span="1"
                        data-area-alias="inner"
                        style={{
                          "--umb-block-grid--grid-columns": "12",
                          "--umb-block-grid--area-column-span": "12",
                          "--umb-block-grid--area-row-span": "1",
                        }}
                      >
                        <div class="umb-block-grid__layout-container">
                          <div
                            class="umb-block-grid__layout-item"
                            data-content-element-type-alias="richTextBlock"
                            data-content-element-type-key="b9247405-030b-4025-b647-c5eadffe381c"
                            data-element-udi="umb://element/c3e7066223df4188b5407dcf7d8b368a"
                            data-col-span="12"
                            data-row-span="1"
                            style={{
                              "--umb-block-grid--item-column-span": "12",
                              "--umb-block-grid--item-row-span": "1",
                            }}
                          >
                            <div class="block-rich-text font-size-medium">
                              <p>
                                We strive to minimize our environmental impact
                                and promote the safe, efficient, and responsible
                                use of global resources.
                              </p>
                              <p>
                                Throughout our global locations, we remain
                                committed to conserving water, natural resources
                                and energy; recycling, reducing and reusing
                                materials; and reducing waste. With our focus on
                                continuous improvement, we continue to manage
                                shorter-term goals on an annual basis while
                                developing longer-term goals around greenhouse
                                gas emissions and our overall carbon
                                footprint.  
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EnvironmentQuote;
