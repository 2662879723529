import React from "react";
import { Helmet } from "react-helmet";
import "../../css/team.css";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PatrickBarry = () => {
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        backgroundColor: "#000000",
        color: "#fff",
        border: "1px solid #444",
        fontFamily: "Poppins",
      },
    });
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Patrick Barry | Endo</title>
        <meta name="description" content="" />
        <meta property="og:title" content="Patrick Barry" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.endo.com/company/leadership/patrick-barry/"
        />
        <meta
          property="og:image"
          content="https://www.endo.com/images/Logo-share.png"
        />
        <meta property="og:description" content="" />
        <meta name="theme-color" content="#faf9f8" />

        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://www.endo.com/Favicon_2x.png"
        />

        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="stylesheet" href="https://use.typekit.net/omj1cgh.css" />
      </Helmet>
      <Header />
      <main id="main">
        <section id="single-team" class="global-container layout-">
          <ul class="breadcrumbs">
            <li>
              <a href="/company/leadership/">Leadership</a>
            </li>
            <li class="active">Patrick Barry</li>
          </ul>

          <div id="team-content">
            <div id="team-image">
              <figure>
                <img
                  loading="lazy"
                  src="https://www.endo.com/media/4dvfoen3/patrick-barry.webp"
                  alt="Patrick Barry"
                  width="0"
                  height="0"
                />
                <figcaption></figcaption>
              </figure>
            </div>
            <div id="team-copy">
              <h1 class="team-name">Patrick Barry</h1>

              <p class="team-position">
                Executive Vice President and
                <br />
                President, Global Commercial Operations
              </p>

              <div class="team-bio">
                <p>
                  Patrick Barry has served as Executive Vice President and
                  President, Global Commercial Operations of Endo International
                  plc since April 2020, and continues to serve in that role for
                  Endo. In this role, he has responsibility for the company’s
                  global commercial organization across each of Endo’s four
                  reportable business segments, including Branded
                  Pharmaceuticals, Sterile Injectables, Generic Pharmaceuticals,
                  and International Pharmaceuticals. He formerly served as
                  Executive Vice President and Chief Commercial Officer, U.S.
                  Branded Business since February 2018, after joining Endo
                  International plc in December 2016 as Senior Vice President,
                  U.S. Branded Pharmaceuticals.
                </p>
                <p>
                  Prior to joining Endo, Mr. Barry worked at Sanofi S.A. from
                  1992 until December 2016, holding roles of increasing
                  responsibility in areas such as Sales Leadership, Commercial
                  Operations, Marketing, Launch Planning and Training, and
                  Leadership Development. Most recently, he served at Sanofi
                  S.A. as its General Manager and Head of North America General
                  Medicines starting in September 2015 and as Vice President and
                  Head of U.S. Specialty from April 2014 until August 2015.
                  During this time, Mr. Barry oversaw three complex and diverse
                  businesses with responsibility for leading sales and marketing
                  activities for branded and generic products across the U.S.
                  and Canada.
                </p>
                <p>
                  He has a diverse therapeutic experience, including aesthetics
                  and dermatology, oncology, urology, orthopedics, and medical
                  device and surgical experience. He has an M.B.A. from Cornell
                  University, Johnson School of Management and a Bachelor of
                  Arts in Public Relations and Marketing from McKendree
                  University.
                </p>
              </div>

              <div class="block-separator type-default align-h-left"></div>
            </div>
          </div>
        </section>
      </main>
      <Newsletter notify={notify} successNotify={successNotify} />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default PatrickBarry;
