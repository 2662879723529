import React from "react";

const Footer = () => {
  return (
    <footer id="colophon">
      <div class="global-container">
        <div id="footer-logo">
          <img
            loading="lazy"
            src="https://www.endo.com/images/Logo-black.svg"
            alt="Endo Logo"
            width="116"
            height="34"
          />
        </div>

        <div id="colophon-legal">
          <p id="copyright">© 2024 Endo, Inc. or one of its affiliates</p>

          <nav aria-label="Legal Navigation">
            <ul id="legal-nav">
              <li>
                <a href="/company/policies-and-statements/#code-of-conduct">
                  Code of Conduct
                </a>
              </li>
              <li>
                <a href="/responsibility/ethics-and-compliance/">
                  Corporate Compliance
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div id="colophon-columns">
          <div class="colophon-group top">
            <nav aria-label="Footer Navigation Area 1">
              <ul>
                <li>
                  <span class="nolink">Company</span>
                  <ul class="dropdown">
                    <li>
                      <a href="/company/our-purpose/">Purpose</a>
                    </li>
                    <li>
                      <a href="/company/leadership/">Leadership</a>
                    </li>
                    <li>
                      <a href="/company/history/">History</a>
                    </li>
                    <li>
                      <a href="/company/policies-and-statements/">
                        Policies &amp; Statements
                      </a>
                    </li>
                    <li>
                      <a href="/company/contact-locations/">
                        Contact &amp; Locations
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <nav aria-label="Footer Navigation Area 2">
              <ul>
                <li>
                  <span class="nolink">Products &amp; Capabilities</span>
                  <ul class="dropdown">
                    <li>
                      <a href="/products-and-capabilities/focus-areas/">
                        Focus Areas
                      </a>
                    </li>
                    <li>
                      <a href="/products-and-capabilities/products/">
                        Products
                      </a>
                    </li>
                    <li>
                      <a href="/products-and-capabilities/manufacturing/">
                        Manufacturing
                      </a>
                    </li>
                    <li>
                      <a href="/products-and-capabilities/suppliers/">
                        Suppliers
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <nav aria-label="Footer Navigation Area 3">
              <ul>
                <li>
                  <span class="nolink">Research &amp; Development</span>
                  <ul class="dropdown">
                    <li>
                      <a href="/research-and-development/rd-approach/">
                        R&amp;D Approach
                      </a>
                    </li>
                    <li>
                      <a href="/research-and-development/pipeline/">Pipeline</a>
                    </li>
                    <li>
                      <a href="/research-and-development/clinical-research/">
                        Clinical Research
                      </a>
                    </li>
                    <li>
                      <a href="/research-and-development/research-grants/">
                        Grants
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <nav aria-label="Contact Us Navigation">
              <ul>
                <li>
                  <span class="nolink">Contact Us</span>
                  <ul class="dropdown font-size-small font-weight-normal">
                    <li>
                      <a
                        class="icon icon-contact"
                        href="/company/contact-locations/"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <div class="colophon-group">
            <nav aria-label="Footer Navigation Area 4">
              <ul>
                <li>
                  <span class="nolink">Newsroom</span>
                  <ul class="dropdown">
                    <li>
                      <a href="/newsroom/media/">Media</a>
                    </li>
                    <li>
                      <a href="/newsroom/stories/">Stories</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <nav aria-label="Footer Navigation Area 5">
              <ul>
                <li>
                  <span class="nolink">Responsibility</span>
                  <ul class="dropdown">
                    <li>
                      <a href="/responsibility/ethics-and-compliance/">
                        Ethics &amp; Compliance
                      </a>
                    </li>
                    <li>
                      <a href="/responsibility/team-and-culture/">
                        Team &amp; Culture
                      </a>
                    </li>
                    <li>
                      <a href="/responsibility/customers-and-patients/">
                        Customers &amp; Patients
                      </a>
                    </li>
                    <li>
                      <a href="/responsibility/environment/">Environment</a>
                    </li>
                    <li>
                      <a href="/responsibility/partnerships/">Partnerships</a>
                    </li>
                    <li>
                      <a href="/responsibility/progress-and-reports/">
                        Progress &amp; Reports
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <nav aria-label="Footer Navigation Area 6">
              <ul>
                <li>
                  <span class="nolink">Information</span>
                  <ul class="dropdown">
                    <li>
                      <a href="https://investor.endo.com/">Investors</a>
                    </li>
                    <li>
                      <a href="/careers/">Careers</a>
                    </li>
                    <li>
                      <a href="/careers/employee-benefits/">
                        Employee Benefits
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
